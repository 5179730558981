import { FETCH_SEO_META_DATA_FAILURE, FETCH_SEO_META_DATA_REQUEST, FETCH_SEO_META_DATA_SUCCESS } from '../../actionTypes';
import { FAILURE, FETCHING, SUCCESS } from '../../fetchStatus';

const initialState = {};

export default function seoMetaData(state = initialState, action) {
    switch (action.type) {
        case FETCH_SEO_META_DATA_REQUEST:
            return {
                ...state,
                [action.payload.page]: {
                    status: FETCHING,
                },
            };
        case FETCH_SEO_META_DATA_SUCCESS:
            return {
                ...state,
                [action.payload.page]: {
                    status: SUCCESS,
                    data: action.payload.seoMetaData,
                },
            };
        case FETCH_SEO_META_DATA_FAILURE:
            return {
                ...state,
                [action.payload.page]: {
                    status: FAILURE,
                    error: action.payload.error,
                },
            };
        default:
            return state;
    }
}
