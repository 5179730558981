import { SET_VHOST_REQUEST_LOCATION } from '../actionTypes';

const initialState = null;

export default function vHostRequestLocation(state = initialState, action) {
    switch (action.type) {
        case SET_VHOST_REQUEST_LOCATION:
            const { protocol, hostname, port } = action.payload;
            return {
                ...state,
                protocol,
                hostname,
                port,
            };
        default:
            return state;
    }
}
