import { FETCH_PRODUCTS_FAILURE, FETCH_PRODUCTS_REQUEST, FETCH_PRODUCTS_SUCCESS, SET_PRODUCTS_FILTER_VALUES } from '../../../actionTypes';
import { FAILURE, FETCHING, SUCCESS } from '../../../fetchStatus';

export default function products(state = {}, action) {
    switch (action.type) {
        case FETCH_PRODUCTS_REQUEST:
            return {
                ...state,
                [action.payload.categoryUrl]: {
                    status: FETCHING,
                },
            };
        case FETCH_PRODUCTS_SUCCESS:
            const data = action.payload.data;
            return {
                ...state,
                [action.payload.categoryUrl]: {
                    status: SUCCESS,
                    data: {
                        products: data.products,
                        filterValues: data.filterValues,
                        filterOptions: data.filterOptions,
                    },
                },
            };
        case FETCH_PRODUCTS_FAILURE:
            return {
                ...state,
                [action.payload.categoryUrl]: {
                    status: FAILURE,
                    error: action.payload.error,
                },
            };
        case SET_PRODUCTS_FILTER_VALUES:
            const { categoryUrl, filterValues } = action.payload;
            return {
                ...state,
                [categoryUrl]: {
                    ...state[categoryUrl],
                    data: {
                        ...state[categoryUrl].data,
                        filterValues,
                    },
                },
            };
        default:
            return state;
    }
}
