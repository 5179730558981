import classnames from 'classnames';

export function getUkButtonClassName({ modifier, fullWidth, hideArrow, additionalClassName }) {
    return classnames(
        { [`uk-button uk-button-${modifier}`]: !(modifier === undefined || modifier === 'reset') },
        { 'uk-width-1-1': fullWidth },
        { 'uk-button-hide-arrow': hideArrow },
        { 'uk-link-reset': modifier === 'reset' },
        additionalClassName
    );
}
