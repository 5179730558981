import React from 'react';
import { ExternalEventLink, ExternalLink, InternalEventLink, InternalLink } from '../form-elements/Link';

function LinkSelector({ type, to, label, action, category, className }) {
    switch (type) {
        case 'internalLink':
            return (
                <InternalLink to={to} className={className}>
                    {label}
                </InternalLink>
            );
        case 'internalEventLink':
            return (
                <InternalEventLink to={to} action={action} className={className}>
                    {label}
                </InternalEventLink>
            );
        case 'externalLink':
            return (
                <ExternalLink to={to} className={className}>
                    {label}
                </ExternalLink>
            );
        case 'externalEventLink':
            return (
                <ExternalEventLink to={to} action={action} className={className}>
                    {label}
                </ExternalEventLink>
            );
        default:
            throw new Error(`Unexpected link type: ${type}`);
    }
}

export default LinkSelector;
