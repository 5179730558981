import * as Yup from 'yup';
import _ from 'lodash';
import { validatePage } from './customPropTypesValidators';

const paramsSchema = Yup.object().shape(
    {
        currentHostIsShopSystem: Yup.boolean().when('currentStandortUrl', {
            is: currentStandortUrl => !_.isEmpty(currentStandortUrl),
            then: Yup.boolean().required(),
        }),
        currentHostname: Yup.string().when('currentStandortUrl', {
            is: currentStandortUrl => !_.isEmpty(currentStandortUrl),
            then: Yup.string().required(),
        }),
        currentStandortUrl: Yup.string().when('newStandortUrl', {
            is: newStandortUrl => _.isEmpty(newStandortUrl),
            then: Yup.string().required(),
        }),
        forceServerSideRouting: Yup.boolean().required(),
        newHostIsShopSystem: Yup.boolean().when('newStandortUrl', {
            is: newStandortUrl => !_.isEmpty(newStandortUrl),
            then: Yup.boolean().required(),
        }),
        newHostname: Yup.string().when('newStandortUrl', {
            is: newStandortUrl => !_.isEmpty(newStandortUrl),
            then: Yup.string().required(),
        }),
        newStandortUrl: Yup.string().when('currentStandortUrl', {
            is: currentStandortUrl => _.isEmpty(currentStandortUrl),
            then: Yup.string().required(),
        }),
        page: Yup.string()
            .test(
                'is-valid-page-format',
                ({ path }) => `${path} is invalid`,
                (page, { parent: { currentStandortUrl, newStandortUrl } }) => {
                    validatePage({ page, currentStandortUrl, newStandortUrl }, 'page', 'getStandortAwareHref');
                    return true;
                }
            )
            .required(),
        port: Yup.string().required(),
        protocol: Yup.string().required(),
    },
    ['currentStandortUrl', 'newStandortUrl']
);

export function validateGetStandortAwareHrefParams({
    currentHostIsShopSystem,
    currentHostname,
    currentStandortUrl,
    forceServerSideRouting,
    newHostIsShopSystem,
    newHostname,
    newStandortUrl,
    page,
    port,
    protocol,
}) {
    try {
        paramsSchema.validateSync({
            currentHostIsShopSystem,
            currentHostname,
            currentStandortUrl,
            forceServerSideRouting,
            newHostIsShopSystem,
            newHostname,
            newStandortUrl,
            page,
            port,
            protocol,
        });
        return true;
    } catch (err) {
        console.error(err);
        return false;
    }
}
