// Polyfills require global side effects.
/* eslint-disable import/no-unassigned-import */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
/* eslint-enable import/no-unassigned-import  */
import React from 'react';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { Frontload } from 'react-frontload';
import { CookiesProvider } from 'react-cookie';
import { ConnectedRouter } from 'connected-react-router';
import App from './app/App';
import { loadableReady } from '@loadable/component';
import { history, store } from './modules/clientStore';

// Running locally, we should run on a <ConnectedRouter /> rather than on a <StaticRouter /> like on the server
// Let's also let React Frontload explicitly know we're not rendering on the server here

const root = document.getElementById('root');

render(App);

if (module.hot) {
    module.hot.accept();
}

function render(App) {
    // Load all components needed before rendering
    loadableReady()
        .then(() => {
            hydrate(
                <Provider store={store}>
                    <ConnectedRouter history={history}>
                        <Frontload>
                            <CookiesProvider>
                                <App />
                            </CookiesProvider>
                        </Frontload>
                    </ConnectedRouter>
                </Provider>,
                root
            );
        })
        .catch(err => {
            console.error(err);
        });
}
