import { SHOW_TECAR_TILE, HIDE_TECAR_TILE } from '../actionTypes';

const initialState = {
    showTecarTile: false,
};

export default function floatingSidebar(state = initialState, action) {
    switch (action.type) {
        case SHOW_TECAR_TILE:
            return {
                showTecarTile: true,
            };
        case HIDE_TECAR_TILE:
            return {
                mountCount: false,
            };
        default:
            return state;
    }
}
