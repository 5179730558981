import { FETCH_DIENSTLEISTUNG_FAILURE, FETCH_DIENSTLEISTUNG_REQUEST, FETCH_DIENSTLEISTUNG_SUCCESS } from '../../../actionTypes';
import { FAILURE, FETCHING, SUCCESS } from '../../../fetchStatus';

export default function dienstleistungByID(state = {}, action) {
    switch (action.type) {
        case FETCH_DIENSTLEISTUNG_REQUEST:
            return {
                ...state,
                [action.payload.serviceId]: {
                    status: FETCHING,
                },
            };
        case FETCH_DIENSTLEISTUNG_SUCCESS: {
            return {
                ...state,
                [action.payload.serviceId]: {
                    status: SUCCESS,
                    data: action.payload.data,
                },
            };
        }
        case FETCH_DIENSTLEISTUNG_FAILURE:
            return {
                ...state,
                [action.payload.serviceId]: {
                    status: FAILURE,
                    error: action.payload.error,
                },
            };
        default:
            return state;
    }
}
