import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import Navbar from '../../legacy/header/Navbar';
import { useSelector } from 'react-redux';
import { markenStandortSelector } from '../../../../../modules/selectors/standort/markenStandort';
import { categoriesSelector } from '../../../../../modules/selectors/standort/categories';
import { Helmet } from 'react-helmet';
import TopBar from './components/TopBar';
import Logo from './components/Logo';
import DesktopNav from './components/DesktopNav';
import { LoginButtonsDesktop, LoginButtonsMobile } from './components/LoginButtons';
import useEFAData from '../useEFAData';
import classnames from 'classnames';
import EFALink from './components/EFALink';
import ShopMenuList from '../../../Header/ShopMenuList';
import LoginForm from './LoginForm';
import LoginDropdown from './LoginDropdown';
import HolidayBanner from '../../../Header/HolidayBanner.js';
import loadable from '@loadable/component';

const CouponBanner = loadable(() => import('../../../CouponBanner/Couponbanner'));

function EFAHeader() {
    const markenStandort = useSelector(markenStandortSelector);
    const categories = useSelector(categoriesSelector);
    const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
    const [isLoginFormVisible, setIsLoginFormVisible] = useState(false);
    const [isLoginDropdownVisible, setIsLoginDropdownVisible] = useState(false);

    const { content, error } = useEFAData();

    const navigationContent = useMemo(() => content?.structure.navigation.content, [content]);

    const { categoriesWithoutReparaturUndWartung } = useMemo(() => {
        const [reparaturUndWartungCategory, categoriesWithoutReparaturUndWartung] = _.partition(categories, { url: 'reparatur-wartung' });
        return { reparaturUndWartungCategory, categoriesWithoutReparaturUndWartung };
    }, [categories]);

    const { header, navigationMain, navigationSub } = useMemo(() => navigationContent || {}, [navigationContent]);

    if (error) return null;
    if (!navigationContent) return null;

    return (
        <>
            <Helmet>
                <link
                    rel="stylesheet"
                    href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css"
                    integrity="sha384-B0vP5xmATw1+K9KRQjQERJvTumQW0nPEzvF6L/Z6nronJ3oUOFUFpCjEUQouq2+l"
                    crossOrigin="anonymous"
                />
                <script
                    src="https://code.jquery.com/jquery-3.5.1.slim.min.js"
                    integrity="sha384-DfXdz2htPH0lsSSs5nCTpuj/zy4C+OGpamoFVy38MVBnE+IbbVYUew+OrCXaRkfj"
                    crossOrigin="anonymous"
                />
                <script
                    src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/js/bootstrap.bundle.min.js"
                    integrity="sha384-Piv4xVNRyMGpqkS2by6br4gNJ7DXjqk09RmUpJ8jgGtD7zP9yug3goQfGII0yAns"
                    crossOrigin="anonymous"
                />
            </Helmet>
            <div id="efa-header" className="uk-sticky-fixed" style={{ position: 'sticky', top: 0, zIndex: 981 }}>
                <div className="c-o-01-01-main_navigation main-wrapper" style={{ position: 'static' }}>
                    <div className="desktop">{navigationSub && <TopBar navigationSub={navigationSub} hiddenToggle={true} />}</div>
                    <div className="header-main-wrap" style={{ zIndex: 1000, position: 'relative' }}>
                        {header && <Logo header={header} />}
                        {navigationMain && <DesktopNav navigationMain={navigationMain} />}
                        <LoginButtonsDesktop toggleLoginDropdown={() => setIsLoginDropdownVisible(!isLoginDropdownVisible)} />
                        {isMobileMenuVisible && (
                            <div id="mobile_nav">
                                <div className="logo-container-mobile">{header && <Logo header={header} />}</div>
                                <i className="hamburger_menu_close icon-close" onClick={() => setIsMobileMenuVisible(false)} />
                                <LoginButtonsMobile
                                    closeSidebar={() => setIsMobileMenuVisible(false)}
                                    toggleLoginDropdown={() => setIsLoginDropdownVisible(!isLoginDropdownVisible)}
                                />
                                {isLoginDropdownVisible && (
                                    <LoginDropdown
                                        dropdownClassName="login-mobile"
                                        setIsLoginDropdownVisible={setIsLoginDropdownVisible}
                                        toggleLoginFormVisible={() => setIsLoginFormVisible(!isLoginFormVisible)}
                                    >
                                        {isLoginFormVisible && <LoginForm formClassName="login-mobile-val" />}
                                    </LoginDropdown>
                                )}
                                <div id="mobile-link_Container">
                                    <ul className="uk-nav-default uk-nav-parent-icon" data-uk-nav="">
                                        {navigationMain.map((navigationMainItem, index) => {
                                            const hasChildren = !!navigationMainItem.children.length;
                                            return (
                                                <li key={index} className={classnames({ 'uk-parent': hasChildren })}>
                                                    <EFALink className="mobile-link dropdown" to={navigationMainItem.link}>
                                                        {navigationMainItem.title}
                                                    </EFALink>
                                                    {hasChildren && (
                                                        <ul className="uk-nav-sub sublinks">
                                                            <li>
                                                                <EFALink className="sublink mobile-link" to={navigationMainItem.link}>
                                                                    {navigationMainItem.title}
                                                                </EFALink>
                                                            </li>
                                                            {navigationMainItem.children.map((child, index) => (
                                                                <li key={index}>
                                                                    <EFALink className="sublink mobile-link" to={child.link}>
                                                                        {child.title}
                                                                    </EFALink>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                    
                                    <hr />
                                    <ShopMenuList
                                        categories={categoriesWithoutReparaturUndWartung}
                                        closeSidebar={() => setIsMobileMenuVisible(false)}
                                        data-uk-nav=""
                                    />
                                </div>
                                <div className="mobile-sub">
                                    {navigationSub && <TopBar navigationSub={navigationSub} hiddenToggle={false} />}
                                </div>
                            </div>
                        )}
                        <div className="hamburger_menu">
                            <button className="uk-button-link" onClick={() => setIsMobileMenuVisible(true)}>
                                <i className="icon-menu" />
                            </button>
                        </div>
                    </div>
                    {!isMobileMenuVisible && isLoginDropdownVisible && (
                        <LoginDropdown
                            dropdownClassName="login-popup"
                            setIsLoginDropdownVisible={setIsLoginDropdownVisible}
                            toggleLoginFormVisible={() => setIsLoginFormVisible(!isLoginFormVisible)}
                        >
                            {isLoginFormVisible && <LoginForm formClassName="login-desktop" />}
                        </LoginDropdown>
                    )}
                    <HolidayBanner />
                    <CouponBanner />
                    <Navbar markenStandort={markenStandort} categories={categoriesWithoutReparaturUndWartung} />
                </div>
            </div>
        </>
    );
}

export default EFAHeader;
