import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Routes from './routes/Routes';
import ReduxSuspense from './components/ReduxSuspense/ReduxSuspense';
import SeoContainer from './components/SeoContainer/SeoContainer';
import Footer from './components/Footer/Footer';
import OneTrust from './components/OneTrust/OneTrust';

import { Matomo } from './components/Tracking/Matomo';
import ScrollToTop from './components/ScrollToTop';
import Theme from './components/themes';
import FloatingSidebar from './components/FloatingSidebar/FloatingSidebar';
import ToTop from './components/FloatingSidebar/ToTop';
import { sessionIdSelector } from '../modules/selectors/auth';
import { setNewSessionId } from '../modules/actions/auth/sessionId';
import HeadMeta from './components/Head/HeadMeta';
import HeaderSelector from './components/Header/HeaderSelector';
import { NoSSR } from '../helpers/SSRHelper';
import { useLocation } from 'react-router';

function Layout({ markenStandortResponse, seoMetaDataResponse }) {
    const dispatch = useDispatch();
    const sessionId = useSelector(sessionIdSelector);
    const location = useLocation();
    const isServiceNavExtern = location.pathname.includes("servicenavigatorExtern")
   


    useEffect(() => {
        if (!sessionId) dispatch(setNewSessionId());
    }, [dispatch, sessionId]);

    return (
        <>
            { !isServiceNavExtern && 
                <>
                    <HeadMeta seoMetaDataResponse={seoMetaDataResponse} markenStandortResponse={markenStandortResponse} />
                    <Matomo /> 
                    <ReduxSuspense response={markenStandortResponse} fallback={null} error={null}>
                        <HeaderSelector />
                    </ReduxSuspense> 
                    <FloatingSidebar />
                    <ToTop />
                </> 
            }
            <ReduxSuspense response={markenStandortResponse}>
                <Routes />
            </ReduxSuspense>
            { !isServiceNavExtern && 
                <>
                    <SeoContainer />
                    <Footer />
                </>  
            }
            {/* Global Side Effect Components  */}
            <Theme />
            <NoSSR>
                { !isServiceNavExtern && <ScrollToTop />}
                <OneTrust />
            </NoSSR> 

        </>
    );
}

export default Layout;
