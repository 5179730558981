import React, { useMemo } from 'react';
import { InternalEventLink } from '../form-elements/Link';
import { apiClient } from '../../../helpers/apiHelperV2';
import useSWR from 'swr';
import { useSelector } from 'react-redux';
import { markenStandortSelector } from '../../../modules/selectors/standort/markenStandort';
import Anchor from '../form-elements/Anchor';
import _ from 'lodash';

function ServiceLocationLandingpageList() {

    const markenStandort = useSelector(markenStandortSelector);

    const { data: serviceLocationList } = useSWR(`/api/v1/getAllServiceLocationLandingPage?standortUrl=${markenStandort.url}`, async url => {
        const { data } = await apiClient.get(url);
        return data;
    });

    const serviceLocationListLandingPage = useMemo(() => serviceLocationList ?? [], [serviceLocationList]);
    const allLocationsObject = _.groupBy(serviceLocationListLandingPage, o => o.mainLocation);
    const allLocationsLists = _.entries(allLocationsObject);
    //console.log(allLocationsLists);

    return (
        <>
        {/* <div className="uk-container">
            <h2>Services an unseren Standorten in Düsseldorf</h2>

            <div className="uk-grid uk-grid-small uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l"
                data-uk-grid="">
                {serviceLocationListLandingPage.map((list, index) => (
                    <div key={index}>
                        <InternalEventLink to={`/${list.mainUrl}/${list.url}`}>
                            {list.location}
                        </InternalEventLink>
                    </div>

                ))}
            </div>
        </div > */}
        <div className="uk-container">
            <ul data-uk-accordion="">
                {allLocationsLists.map((el,index)=>(
                    <li key={index} className={index === 0? "uk-open" : ""}>
                        <Anchor className="uk-accordion-title aps-accordion-title uk-background-secondary uk-padding-small uk-light">
                        <span uk-icon="icon:triangle-right; ratio:1.5" /> Services an unseren Standorten in {el[0]}
                        </Anchor>                       
                        <div className="uk-accordion-content uk-grid uk-grid-small uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l uk-padding-small uk-margin-left">
                            {el[1].map((list, index) => (
                                <div key={index} className='uk-margin-small-bottom'>
                                    <InternalEventLink to={`/${list.mainUrl}/${list.url}`}>
                                        {list.location}
                                    </InternalEventLink>
                                </div>

                            ))}
                        </div>                     
                    </li>
                ))}
            </ul>
        </div>
        </>
    );
}

export default ServiceLocationLandingpageList;
