import { FAILURE, FETCHING, PENDING, SUCCESS } from '../../../modules/fetchStatus';
import Spinner from '../Spinner/Spinner';
import React from 'react';
import Status from '../Status/Status';
import _ from 'lodash';
import loadable from '@loadable/component';

const NotFound = loadable(() => import('../../routes/ErrorPages/NotFound/NotFound'));

const defaultError = ({ responseStatus }) => {
    if (responseStatus === 404) {
        return <NotFound />;
    } else {
        // TODO: better default content for unexpected fetch failures
        return (
            <Status code={500}>
                <div>Es ist ein Fehler aufgetreten, bitte versuchen Sie es später erneut.</div>
            </Status>
        );
    }
};

const ReduxSuspense = ({ response, children, fallback = <Spinner />, error = defaultError }) => {
    if (!response?.status) {
        throw new Error(`Expected valid redux response, got: ${response}`);
    }

    switch (response.status) {
        case PENDING:
        case FETCHING:
            return fallback;
        case FAILURE:
            const responseStatus = response?.error?.code;

            if (_.isFunction(error)) {
                return error({ responseStatus });
            } else {
                return error;
            }
        case SUCCESS:
            // Reference: https://reactjs.org/docs/render-props.html
            if (_.isFunction(children)) {
                return children(response.data);
            } else {
                return children;
            }
        default:
            throw new Error(`Unexpected fetchStatus: ${response.status}`);
    }
};

export default ReduxSuspense;
