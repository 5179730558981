import React from 'react';
import LinkSelector from '../../Header/LinkSelector';

function ServiceInquiryTile({ stickyBoxLink }) {
    return (
        <div className="uk-tile uk-tile-secondary uk-padding-small uk-box-shadow-large uk-text-center">
            <LinkSelector
                type={stickyBoxLink.type}
                to={stickyBoxLink.to}
                label={
                    <>
                        <div
                            className="uk-background-primary uk-width-3-5 uk-position-relative uk-border-circle uk-margin-auto uk-margin-bottom"
                            style={{ paddingTop: '60%' }}
                        >
                            <div className="uk-position-cover uk-flex uk-flex-center uk-flex-middle">
                                <i className="fa fa-wrench fa-fw fa-2x" />
                            </div>
                        </div>
                        {stickyBoxLink.label}
                    </>
                }
            />
        </div>
    );
}
export default ServiceInquiryTile;
