import {
    FETCH_SERVICE_LANDINGPAGE_FAILURE,
    FETCH_SERVICE_LANDINGPAGE_REQUEST,
    FETCH_SERVICE_LANDINGPAGE_SUCCESS,
} from '../../../actionTypes';
import { FAILURE, FETCHING, SUCCESS } from '../../../fetchStatus';

const initialState = null;

export default function serviceLandingpage(state = initialState, action) {
    switch (action.type) {
        case FETCH_SERVICE_LANDINGPAGE_REQUEST:
            return {
                status: FETCHING,
            };
        case FETCH_SERVICE_LANDINGPAGE_SUCCESS:
            return {
                status: SUCCESS,
                data: action.payload.content,
            };
        case FETCH_SERVICE_LANDINGPAGE_FAILURE:
            return {
                status: FAILURE,
                error: action.payload.error,
            };
        default:
            return state;
    }
}
