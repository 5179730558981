import { combineReducers } from 'redux';
import product from './category/product';

const initialState = {};

const categoryReducers = combineReducers({
    product,
});

export default function category(state = initialState, action) {
    const categoryUrl = action?.payload?.categoryUrl;
    if (categoryUrl) {
        return {
            ...state,
            [categoryUrl]: categoryReducers(state[categoryUrl], action),
        };
    } else {
        return state;
    }
}
