import _ from 'lodash';
import { getStandortAwareHref, useStandortAwareHrefParams } from './internal/vHostHelper';
import { useEffect, useMemo, useState } from 'react';
import { historyPushServerSide, historyReplaceServerSide } from './internal/locationHelper';
import { useSelector } from 'react-redux';
import { pageSelector, standortUrlSelector } from '../../../modules/selectors/url';
import { createLocation } from 'history';
import { validatePage as propTypesValidatePage } from './internal/customPropTypesValidators';
import { IS_DEV } from '../../../config';
// This file provides the replacement for 'useHistory' and is therefore the only allowed importer of useHistory.
// eslint-disable-next-line no-restricted-imports
import { useHistory, useLocation } from 'react-router-dom';

function extractNewStandortUrl(locationOrPath) {
    if (_.isPlainObject(locationOrPath)) {
        const { newStandortUrl } = locationOrPath;
        return newStandortUrl;
    } else {
        return undefined;
    }
}

function validatePage({ page, currentStandortUrl, newStandortUrl }) {
    if (IS_DEV) {
        try {
            propTypesValidatePage({ page, currentStandortUrl, newStandortUrl }, 'page', 'useStandortHistory');
        } catch (e) {
            console.error(e);
        }
    }
}

export function useStandortHistory() {
    const history = useHistory();
    const currentLocation = useLocation();
    const currentPage = useSelector(pageSelector);

    const [{ type, locationOrPath, cb }, setHistoryCommand] = useState({});

    const newStandortUrl = extractNewStandortUrl(locationOrPath);

    const { currentHostname, currentHostIsShopSystem, newHostname, newHostIsShopSystem, protocol, port } = useStandortAwareHrefParams({
        newStandortUrl,
    });
    const currentStandortUrl = useSelector(standortUrlSelector);

    useEffect(() => {
        if (type) {
            const currentStandortLocation = {
                ...currentLocation,
                pathname: currentPage,
            };

            const { pathname: page, search, hash, state } = createLocation(locationOrPath, undefined, undefined, currentStandortLocation);

            validatePage({ page, currentStandortUrl, newStandortUrl });

            const { routeServerSide, to: pathname } = getStandortAwareHref({
                page,
                currentHostname,
                currentStandortUrl,
                currentHostIsShopSystem,
                newStandortUrl,
                newHostname,
                newHostIsShopSystem,
                protocol,
                port,
                forceServerSideRouting: false,
            });

            if (routeServerSide) {
                const url = `${pathname}${search}${hash}`;
                console.debug('StandortHistory navigation: server side', type, 'to', url);
                if (type === 'push') {
                    historyPushServerSide(url);
                } else if (type === 'replace') {
                    historyReplaceServerSide(url);
                } else {
                    throw new Error(`Unexpected history command type: ${type}`);
                }
            } else {
                const newLocation = _.omitBy(
                    {
                        pathname,
                        search,
                        state,
                        hash,
                    },
                    _.isEmpty
                );
                // console.debug('StandortHistory navigation: client side', type, 'to', JSON.stringify(newLocation));
                if (type === 'push') {
                    history.push(newLocation);
                } else if (type === 'replace') {
                    history.replace(newLocation);
                } else {
                    throw new Error(`Unexpected history command type: ${type}`);
                }
            }

            if (_.isFunction(cb)) {
                cb();
            }

            setHistoryCommand({});
        }
    }, [
        currentHostIsShopSystem,
        currentHostname,
        currentLocation,
        currentStandortUrl,
        history,
        locationOrPath,
        newHostIsShopSystem,
        newHostname,
        newStandortUrl,
        currentPage,
        port,
        protocol,
        type,
        cb,
    ]);

    return useMemo(
        () => ({
            location: currentLocation,
            push: (locationOrPath, cb) => {
                // console.debug('push', locationOrPath);
                setHistoryCommand({
                    type: 'push',
                    locationOrPath,
                    cb,
                });
            },
            replace: (locationOrPath, cb) => {
                // console.debug('replace', locationOrPath);
                setHistoryCommand({
                    type: 'replace',
                    locationOrPath,
                    cb,
                });
            },
            go: history.go,
            goBack: history.goBack,
            goForward: history.goForward,
        }),
        [currentLocation, history]
    );
}
