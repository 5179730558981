import { combineReducers } from 'redux';
import { has } from 'lodash';
import cart from './cart';
import cartStats from './cartStats';

const initialState = {};

const sessionReducers = combineReducers({
    cart,
    cartStats,
});

export default function session(state = initialState, action) {
    if (has(action, 'payload.sessionId')) {
        return {
            ...state,
            [action.payload.sessionId]: sessionReducers(state[action.payload.sessionId], action),
        };
    } else {
        return state;
    }
}
