import { useEffect, useState } from 'react';

// Reference env: https://razzlejs.org/docs/environment-variables
export const isServer = process.env.BUILD_TARGET === 'server';
export const isClient = !isServer;

/**
 * Do not render Elements on the server.
 *
 * Important: a simple condition on !isServer/isClient is not enough! Use this component instead.
 *
 * React *requires* us to render exactly the same content on the Server and the Client.
 * See react docs: https://reactjs.org/docs/react-dom.html#hydrate
 * The solution implemented by this component is called two-pass rendering:
 * - First the Server renders a fallback.
 * - The client hydrates the same fallback.
 * - Only *then* renders the client the real content (children).
 *
 * @param props.children - What to render only on the client.
 * @param [props.fallback] - What to render on the server until the client updates. Defaults to `null`.
 */
export const NoSSR = props => {
    const { children, fallback = null } = props;
    const [canRender, setCanRender] = useState(false);

    useEffect(() => {
        setCanRender(true);
    }, []);

    return canRender ? children : fallback;
};
