import { isServer } from '../helpers/SSRHelper';
import {
    DELETE_USER_SUCCESS,
    SET_AUTH_TOKEN,
    SET_SESSION_ID,
    SIGNIN_USER_SUCCESS,
    SIGNOUT_USER_SUCCESS,
    SIGNUP_USER_SUCCESS,
    UPDATE_USER_PASSWORD_SUCCESS,
} from './actionTypes';

const localStorageMiddleware = _store => next => action => {
    if (isServer) return next(action);

    const type = action?.type;
    if (type === SET_AUTH_TOKEN || type === SIGNIN_USER_SUCCESS || type === SIGNUP_USER_SUCCESS) {
        const token = action?.payload?.token;
        if (token) {
            localStorage.setItem('token', token);
        }
    } else if (type === SIGNOUT_USER_SUCCESS || type === UPDATE_USER_PASSWORD_SUCCESS || type === DELETE_USER_SUCCESS) {
        localStorage.removeItem('token');
    }

    if (type === SET_SESSION_ID) {
        const sessionId = action?.payload?.sessionId;
        if (sessionId) {
            localStorage.setItem('sessionId', sessionId);
        }
    }

    return next(action);
};
export default localStorageMiddleware;
