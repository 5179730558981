import React from 'react';
import loadable from '@loadable/component';
import { useSelector } from 'react-redux';
import { markenStandortThemeHeaderSelector } from '../../../modules/selectors/standort/theme';
import EFAHeader from '../themes/efa/header/EFAHeader';

const DefaultHeader = loadable(() => import('./DefaultHeader'));
const Header = loadable(() => import('../themes/legacy/header/Header'));
const NOAHeader = loadable(() => import('../themes/noa/header/NOAHeader'));
const TobabenHeader = loadable(() => import('../themes/tobaben/header/tobabenHeader'));
const AndersHeader = loadable(() => import('../themes/anders/header/AndersHeader'));
const LoehrBeckerHeader = loadable(() => import('../themes/loehrBecker/header/loehrBekerHeader'));
const MusterHeader = loadable(() => import('../themes/muster/header/musterHeader'));
const DelloHeader = loadable(() => import('../themes/dello/header/delloHeader'));
const GottfriedHeader = loadable(() => import('../themes/gottfried/header/GottfriedHeader')) ;
const AutoschmittHeader = loadable(() => import('../themes/autoschmitt/header/AutoschmittHeader'));

function HeaderSelector() {
    const markenStandortThemeHeader = useSelector(markenStandortThemeHeaderSelector);

    switch (markenStandortThemeHeader.componentName) {
        case 'AndersHeader':
            return <AndersHeader links={markenStandortThemeHeader.links} />;
        case 'TobabenHeader':
            return <TobabenHeader links={markenStandortThemeHeader.links} />;
        case 'EFAHeader':
            return <EFAHeader links={markenStandortThemeHeader.links} />;
        case 'NOAHeader':
            return <NOAHeader links={markenStandortThemeHeader.links} />;
        case 'DefaultHeader':
            return <DefaultHeader links={markenStandortThemeHeader.links} />;
        case 'LoehrBeckerHeader':
            return <LoehrBeckerHeader links={markenStandortThemeHeader.links} />;
        case 'MusterHeader':
            return <MusterHeader links={markenStandortThemeHeader.links} />;
        case 'DelloHeader':
            return <DelloHeader links={markenStandortThemeHeader.links} />;
        case 'GottfriedHeader':
            return <GottfriedHeader links={markenStandortThemeHeader.links} />;
        case 'AutoschmittHeader':
            return <AutoschmittHeader links={markenStandortThemeHeader.links} />;
        case 'LegacyHeader':
            return <Header />;
        default:
            throw new Error(`Unexpected markenStandortThemeHeader, got: ${markenStandortThemeHeader.componentName}`);
    }
}

export default HeaderSelector;
