import {
    DELETE_USER_FAILURE,
    DELETE_USER_REQUEST,
    DELETE_USER_SUCCESS,
    FETCH_USER_FAILURE,
    FETCH_USER_REQUEST,
    FETCH_USER_SUCCESS,
    INIT_USER_PASSWORD_FAILURE,
    INIT_USER_PASSWORD_REQUEST,
    INIT_USER_PASSWORD_SUCCESS,
    RESET_USER_PASSWORD_FAILURE,
    RESET_USER_PASSWORD_REQUEST,
    RESET_USER_PASSWORD_SUCCESS,
    SIGNIN_USER_FAILURE,
    SIGNIN_USER_REQUEST,
    SIGNIN_USER_SUCCESS,
    SIGNOUT_USER_SUCCESS,
    SIGNUP_USER_FAILURE,
    SIGNUP_USER_REQUEST,
    SIGNUP_USER_SUCCESS,
    UPDATE_USER_FAILURE,
    UPDATE_USER_PASSWORD_FAILURE,
    UPDATE_USER_PASSWORD_REQUEST,
    UPDATE_USER_PASSWORD_SUCCESS,
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS,
} from '../actionTypes';
import { shouldFetch } from '../fetchStatus';
import { apiClient } from '../../helpers/apiHelperV2';
import { userResponseSelector } from '../selectors/auth';
import { standortUrlSelector } from '../selectors/url';
import { isServer } from '../../helpers/SSRHelper';

export const fetchUserIfNeeded = () => {
    return async function (dispatch, getState) {
        if (isServer) return;
        const state = getState();
        const userResponse = userResponseSelector(state);
        if (shouldFetch(userResponse.status)) {
            dispatch({
                type: FETCH_USER_REQUEST,
                payload: {},
            });
            try {
                const res = await apiClient.get('/api/v1/auth/user/current', { authenticated: true });
                dispatch({
                    type: FETCH_USER_SUCCESS,
                    payload: { user: res.data ? res.data : undefined },
                });
            } catch (error) {
                dispatch({
                    type: FETCH_USER_FAILURE,
                    payload: { error },
                });
            }
        }
    };
};

export const signup = ({ user, standortUrl }) => {
    return async function (dispatch) {
        if (isServer) return;

        dispatch({
            type: SIGNUP_USER_REQUEST,
            payload: {},
        });
        try {
            const res = await apiClient.post('/api/v1/auth/signup', { user, standortUrl });
            dispatch({
                type: SIGNUP_USER_SUCCESS,
                payload: { user: res.data.user, token: res.data.token },
            });
        } catch (error) {
            dispatch({
                type: SIGNUP_USER_FAILURE,
                payload: { error },
            });
        }
    };
};

export const signin = ({ username, password }) => {
    return async function (dispatch, getState) {
        if (isServer) return;
        const state = getState();

        const standortUrl = standortUrlSelector(state);

        dispatch({
            type: SIGNIN_USER_REQUEST,
            payload: {},
        });
        try {
            const res = await apiClient.post('/api/v1/auth/signin', { username, password, standortUrl });
            dispatch({
                type: SIGNIN_USER_SUCCESS,
                payload: { user: res.data.user, token: res.data.token },
            });
        } catch (error) {
            const message = error.code === 401 ? 'Ihre angegebenen Anmeldedaten sind ungültig.' : undefined;
            dispatch({
                type: SIGNIN_USER_FAILURE,
                payload: { error, message },
            });
        }
    };
};

export const updateUser = ({ user }) => {
    return async function (dispatch) {
        if (isServer) return;

        dispatch({
            type: UPDATE_USER_REQUEST,
            payload: {},
        });
        try {
            const res = await apiClient.put('/api/v1/users', { user }, { authenticated: true });
            dispatch({
                type: UPDATE_USER_SUCCESS,
                payload: { user: res.data.user },
            });
        } catch (error) {
            dispatch({
                type: UPDATE_USER_FAILURE,
                payload: { error },
            });
        }
    };
};

export const deleteUser = ({ password }) => {
    return async function (dispatch) {
        if (isServer) return;
        dispatch({
            type: DELETE_USER_REQUEST,
            payload: {},
        });
        try {
            await apiClient.delete('/api/v1/users', { params: { password }, authenticated: true });
            dispatch({
                type: DELETE_USER_SUCCESS,
                payload: {},
            });
        } catch (error) {
            dispatch({
                type: DELETE_USER_FAILURE,
                payload: { error },
            });
        }
    };
};

export const updateUserPassword = ({ password }) => {
    return async function (dispatch) {
        if (isServer) return;

        dispatch({
            type: UPDATE_USER_PASSWORD_REQUEST,
            payload: {},
        });
        try {
            await apiClient.put('/api/v1/auth/password', { password }, { authenticated: true });
            dispatch({
                type: UPDATE_USER_PASSWORD_SUCCESS,
                payload: {},
            });
        } catch (error) {
            dispatch({
                type: UPDATE_USER_PASSWORD_FAILURE,
                payload: { error },
            });
        }
    };
};

export const signout = () => {
    return async function (dispatch) {
        if (isServer) return;
        dispatch({
            type: SIGNOUT_USER_SUCCESS,
            payload: {},
        });
    };
};

export const initiateResetPassword = ({ email }) => {
    return async function (dispatch, getState) {
        if (isServer) return;
        const state = getState();

        const standortUrl = standortUrlSelector(state);

        dispatch({
            type: INIT_USER_PASSWORD_REQUEST,
            payload: {},
        });
        try {
            const res = await apiClient.post('/api/v1/auth/resetPassword', { email, standortUrl });
            dispatch({
                type: INIT_USER_PASSWORD_SUCCESS,
                payload: {},
            });
            return res;
        } catch (error) {
            dispatch({
                type: INIT_USER_PASSWORD_FAILURE,
                payload: { error },
            });
        }
    };
};

export const resetUserPassword = ({ hash, email, password }) => {
    return async function (dispatch) {
        if (isServer) return;

        dispatch({
            type: RESET_USER_PASSWORD_REQUEST,
            payload: {},
        });
        try {
            await apiClient.put('/api/v1/auth/resetPassword', { hash, email, password }, { authenticated: true });
            dispatch({
                type: RESET_USER_PASSWORD_SUCCESS,
                payload: {},
            });
        } catch (error) {
            dispatch({
                type: RESET_USER_PASSWORD_FAILURE,
                payload: { error },
            });
        }
    };
};
