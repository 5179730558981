import {
    FETCH_ORDERS_FAILURE,
    FETCH_ORDERS_REQUEST,
    FETCH_ORDERS_SUCCESS,
    POST_ORDERS_FAILURE,
    POST_ORDERS_REQUEST,
    POST_ORDERS_SUCCESS,
} from '../../actionTypes';
import { FAILURE, FETCHING, SUCCESS } from '../../fetchStatus';

const initialState = null;

export default function orders(state = initialState, action) {
    switch (action.type) {
        case FETCH_ORDERS_REQUEST:
        case POST_ORDERS_REQUEST:
            return {
                status: FETCHING,
            };
        case FETCH_ORDERS_SUCCESS:
        case POST_ORDERS_SUCCESS:
            return {
                status: SUCCESS,
                data: action.payload.orders,
            };
        case FETCH_ORDERS_FAILURE:
        case POST_ORDERS_FAILURE:
            return {
                status: FAILURE,
                error: action.payload.error,
            };
        default:
            return state;
    }
}
