import React, { useMemo } from 'react';
import classnames from 'classnames';
import EFALink from './EFALink';

function TopBarPageItem({ page }) {
    const { layout, link } = useMemo(() => page.content || {}, [page]);

    return (
        <EFALink className={layout} to={link.url}>
            {link.link}
        </EFALink>
    );
}

function TopBar({ navigationSub, hiddenToggle }) {
    const { context, pages } = useMemo(() => navigationSub || {}, [navigationSub]);

    return (
        <div className={classnames('c-o-01-02-sub-navigation', { 'uk-visible@l': hiddenToggle })}>
            <div className="content-left" />
            <div className="content-right">
                {context && <span className="contact-phones" dangerouslySetInnerHTML={{ __html: context }} />}
                {pages && pages.map((page, index) => <TopBarPageItem key={index} page={page} />)}
            </div>
        </div>
    );
}

export default TopBar;
