import { FETCH_PRODUCT_FAILURE, FETCH_PRODUCT_REQUEST, FETCH_PRODUCT_SUCCESS, SET_PRODUCT_VARIANT_ID } from '../../../../actionTypes';
import { FAILURE, FETCHING, SUCCESS } from '../../../../fetchStatus';
import { isEmpty } from 'lodash';

const selectInitialVariantId = ({ isExact, product, productUrl, fabrikat }) => {
    let variant;
    if (isExact) {
        variant = product?.varianten?.find(v => v._id === productUrl || v.id === productUrl || v.slug === productUrl);
    } else {
        const filteredBySoldOut = product?.varianten?.filter(
            variant =>
                !(
                    (variant?.isOwnArticle && variant?.stockkeeping && variant?.stock < 1) ||
                    (!variant?.isOwnArticle && variant?.stock < 1) ||
                    variant?.soldOut
                )
        );
        const filteredByFabrikat = filteredBySoldOut.filter(variant => variant.fabrikat === fabrikat);
        if (!isEmpty(filteredByFabrikat)) {
            variant = filteredByFabrikat.sort((a, b) => a.vk_preis - b.vk_preis)[0];
        } else {
            variant = filteredBySoldOut.sort((a, b) => a.vk_preis - b.vk_preis)[0];
        }
    }
    return variant?._id;
};

export default function product(state = {}, action) {
    switch (action.type) {
        case FETCH_PRODUCT_REQUEST:
            return {
                ...state,
                [action.payload.productUrl]: {
                    status: FETCHING,
                },
            };
        case FETCH_PRODUCT_SUCCESS:
            const data = action.payload.data;
            const isExact = action.payload.isExact;
            const selectedVariantId = selectInitialVariantId({
                isExact,
                product: data.product,
                productUrl: action.payload.productUrl,
                fabrikat: action.payload.fabrikat,
            });
            return {
                ...state,
                [action.payload.productUrl]: {
                    status: SUCCESS,
                    data: {
                        ...data.product,
                        selectedVariantId,
                    },
                },
            };
        case FETCH_PRODUCT_FAILURE:
            return {
                ...state,
                [action.payload.productUrl]: {
                    status: FAILURE,
                    error: action.payload.error,
                },
            };
        case SET_PRODUCT_VARIANT_ID:
            return {
                ...state,
                [action.payload.productUrl]: {
                    status: state[action.payload.productUrl].status,
                    data: {
                        ...state[action.payload.productUrl].data,
                        selectedVariantId: action.payload.variantId,
                    },
                },
            };
        default:
            return state;
    }
}
