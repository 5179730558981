import { SET_SESSION_ID } from '../../actionTypes';

const initialState = null;

export default function sessionId(state = initialState, action) {
    switch (action.type) {
        case SET_SESSION_ID:
            return action.payload.sessionId;
        default:
            return state;
    }
}
