import { shouldFetch } from '../../fetchStatus';
import { FETCH_SEO_META_DATA_FAILURE, FETCH_SEO_META_DATA_REQUEST, FETCH_SEO_META_DATA_SUCCESS } from '../../actionTypes';
import { apiClient } from '../../../helpers/apiHelperV2';
import { seoMetaDataResponseSelector } from '../../selectors/standort/seoMetaData';
import { pageSelector, standortUrlSelector } from '../../selectors/url';

export const fetchSeoMetaDataIfNeeded = () => {
    return async function (dispatch, getState) {
        const state = getState();

        const standortUrl = standortUrlSelector(state);
        const page = pageSelector(state);
        const seoMetaDataResponse = seoMetaDataResponseSelector(state);

        if (shouldFetch(seoMetaDataResponse.status)) {
            dispatch({
                type: FETCH_SEO_META_DATA_REQUEST,
                payload: { standortUrl, page },
            });
            try {
                const res = await apiClient.get('/api/v1/SeoMetaData', {
                    params: {
                        standortUrl,
                        page,
                    },
                });

                dispatch({
                    type: FETCH_SEO_META_DATA_SUCCESS,
                    payload: { standortUrl, page, seoMetaData: res.data },
                });
            } catch (error) {
                dispatch({
                    type: FETCH_SEO_META_DATA_FAILURE,
                    payload: { standortUrl, page, error },
                });
            }
        }
    };
};
