import { SET_CATEGORY_FILTERS } from '../actionTypes';
import _ from 'lodash';

const initialState = {
    reifenFilter: {},
    productFilter: {},
    serviceFilter: {},
};

export default function filter(state = initialState, action) {
    switch (action.type) {
        case SET_CATEGORY_FILTERS:
            const { categoryUrl, values, customized } = action.payload;

            if (_.isString(categoryUrl) && _.isObject(values) && _.isBoolean(customized)) {
                return {
                    ...state,
                    [categoryUrl]: {
                        values,
                        customized,
                    },
                };
            }
            return state;
        default:
            return state;
    }
}
