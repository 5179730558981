import {
    FETCH_SERVICE_NAV_FAILURE,
    FETCH_SERVICE_NAV_REQUEST,
    FETCH_SERVICE_NAV_SUCCESS,
    SET_SERVICE_NAV_FILTER_VALUES,
} from '../../../actionTypes';
import { FAILURE, FETCHING, pendingResponse, SUCCESS } from '../../../fetchStatus';

export default function serviceNavigator(state = pendingResponse, action) {
    switch (action.type) {
        case FETCH_SERVICE_NAV_REQUEST:
            return {
                status: FETCHING,
            };
        case FETCH_SERVICE_NAV_SUCCESS: {
            const { ServicesCategory, Services } = action.payload;
            return {
                status: SUCCESS,
                data: { ServicesCategory, Services },
            };
        }
        case FETCH_SERVICE_NAV_FAILURE:
            return {
                status: FAILURE,
                error: action.payload.error,
            };
        case SET_SERVICE_NAV_FILTER_VALUES: {
            // const { repairCalculation } = action.payload;
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.payload,
                },
            };
        }
        default:
            return state;
    }
}
