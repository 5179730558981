import React from 'react';

function HolidayBanner() {
    const endDate = new Date('2022-01-02T00:00:00+01:00');

    if (!(new Date() <= endDate)) return null;

    return (
        <div
            className="aps-corona-banner uk-tile uk-padding-small uk-padding-remove-horizontal uk-animation-fade uk-sticky-fixed uk-width-1-1"
            style={{ backgroundColor: '#cc0099', color: 'white'}}
        >
            <div className="uk-container">
                <div className="uk-grid uk-flex-top">
                    <div className="uk-width-expand uk-text-light uk-text-center" style={{ fontSize: '18px'}}>
                            Herzlich Willkommen in unserem Shop! <br></br>
                            Aufgrund der anstehenden Feiertage kann es zu verlängerten Lieferzeiten kommen. Wir bedanken uns für Ihr Verständnis.
                    </div>
                    {/* <div className="uk-width-auto">
                        <button data-uk-toggle="target: .aps-corona-banner" data-uk-close="" />
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default HolidayBanner;
