import { Route, Switch, useLocation } from 'react-router-dom';
import React from 'react';

/**
 * All redirects which don't depend on external data.
 *
 * This is a performance optimization, since we don't have to wait for GlobalFrontload to send the redirect.
 */
const StaticRedirects = ({ children }) => {
    const { pathname, search } = useLocation();

    return (
        <Switch>
            {/* Redirect root */}
            {/* TODO: replace*/}
            {/*<Redirect exact from="/" to="/de" />*/}
            {/* Remove trailing slash from URL */}
            <Route exact strict from="/:url*(/)">
                {({ history }) => {
                    // Can't use <Redirect to/> for user generated paths (parameter injection).
                    history.replace(`${pathname.slice(0, -1)}${search}`);
                    return null;
                }}
            </Route>
            {/* Default */}
            <Route>{children}</Route>
        </Switch>
    );
};

export default StaticRedirects;
