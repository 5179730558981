import { isClient } from '../../../../helpers/SSRHelper';

export function historyPushServerSide(url) {
    if (isClient) {
        // Reference: https://developer.mozilla.org/en-US/docs/Web/API/Location/assign
        window.location.assign(url);
    } else {
        console.error('Unexpected SSR call to historyPushServerSide with url:', url);
    }
}

export function historyReplaceServerSide(url) {
    if (isClient) {
        // Reference: https://developer.mozilla.org/en-US/docs/Web/API/Location/replace
        window.location.replace(url);
    } else {
        console.error('Unexpected SSR call to historyReplaceServerSide with url:', url);
    }
}
