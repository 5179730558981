import React from 'react';
import { useSelector } from 'react-redux';
import ReduxSuspense from '../ReduxSuspense/ReduxSuspense';
import FooterCopyright from './FooterCopyright';
import { markenStandortResponseSelector } from '../../../modules/selectors/standort/markenStandort';
import PaymentColumns from './PaymentColumns';
import FooterSelector from './FooterSelector';

function Footer() {
    const markenStandortResponse = useSelector(markenStandortResponseSelector);

    return (
        <ReduxSuspense response={markenStandortResponse} fallback={null} error={null}>
            <PaymentColumns />
            <FooterSelector />
            <FooterCopyright />
        </ReduxSuspense>
    );
}

export default Footer;
