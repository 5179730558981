import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import React from 'react';
import StandortFrontload from './StandortFrontload';
import ReduxSuspense from './components/ReduxSuspense/ReduxSuspense';
import { fetchHostsIfNeeded } from '../modules/actions/hosts';
import { hostsResponseSelector } from '../modules/selectors/hosts';
import Status from './components/Status/Status';
import HostRedirects from './components/Redirects/HostRedirects';

const HostsFrontload = ({ hostsResponse }) => {
    return (
        <ReduxSuspense
            response={hostsResponse}
            fallback={null}
            error={
                <Status code={500}>
                    <div>Es ist ein Fehler aufgetreten, bitte versuchen Sie es später erneut.</div>
                </Status>
            }
        >
            <HostRedirects>
                <StandortFrontload />
            </HostRedirects>
        </ReduxSuspense>
    );
};

const frontload = async ({ fetchHostsIfNeeded }) => {
    await Promise.all([fetchHostsIfNeeded()]);
};

const mapStateToProps = state => ({
    hostsResponse: hostsResponseSelector(state),
});

const mapDispatchToProps = {
    fetchHostsIfNeeded,
};

export default connect(mapStateToProps, mapDispatchToProps)(frontloadConnect(frontload, { onUpdate: true })(HostsFrontload));
