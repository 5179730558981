import React from 'react';
import { historyPushServerSide } from '../../../VHost/internal/locationHelper';
import { serializeSearchParams } from '../../../../../helpers/UrlHelper';

function LoginDropdown({ dropdownClassName, setIsLoginDropdownVisible, toggleLoginFormVisible, children }) {
    return (
        <div className={dropdownClassName}>
            <button className="uk-button-link" onClick={() => setIsLoginDropdownVisible(false)}>
                <i className="icon-close login-close" />
            </button>
            <div>
                <button
                    className="btn-primary btn-max trail-icon-profile"
                    onClick={() => {
                        historyPushServerSide(
                            `https://emilfrey.carmato.io/keycloak/?${serializeSearchParams({ redirect: window.location.href })}`
                        );
                    }}
                >
                    KUNDE
                </button>
                <div className="padding-24">
                    <button className="btn-primary-ghost btn-max icon-chevrondown toggle-icon" onClick={toggleLoginFormVisible}>
                        Mitarbeiter
                    </button>
                </div>
                {children}
            </div>
        </div>
    );
}

export default LoginDropdown;
