import React from 'react';
import classnames from 'classnames';
import ServiceCategoryCard from './ServiceCategoryCard';

function ServiceCategoryCards({ dienstleistungen, trackingAction, limitMobileCardCount = Infinity }) {
    return (
        <div
            className="uk-grid uk-grid-small uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l"
            data-uk-grid=""
        >
            {dienstleistungen.map((service, index) => (
                   <div key={service._id} className={classnames({ 'uk-visible@l': index >= limitMobileCardCount })}>
                    <ServiceCategoryCard service={service} trackingAction={trackingAction} />
                </div>              
            ))}
        </div>
    );
}

export default ServiceCategoryCards;
