import { createSelector } from 'reselect';
import { pendingResponse } from '../fetchStatus';

const authSelector = state => state?.auth;

export const userResponseSelector = createSelector([authSelector], auth => auth?.user ?? pendingResponse);
export const userSelector = createSelector([userResponseSelector], userResponse => userResponse?.data);

export const tokenSelector = createSelector([authSelector], auth => auth?.token);

export const sessionIdSelector = createSelector([authSelector], auth => auth?.sessionId);
