import React from 'react';
import Image from '../Image/Image';
import Anchor from '../form-elements/Anchor';

function ServiceStationBanner() {
    return (
        <section className="uk-section-primary">
            <div className="uk-container">
                <div className="uk-grid uk-grid-small uk-flex uk-flex-middle">
                    <div>
                        <Image
                            src="https://techno-images.s3.eu-central-1.amazonaws.com/tecar/tecar_meister.png"
                            alt="Werkstattmeister"
                            width={100}
                            resizeWidth={100}
                        />
                    </div>
                    <div>
                        <p>
                            Wählen Sie eine <b>Meisterwerkstatt in Ihrer Nähe.</b> <br />
                            Ist Ihr gesuchter Service nicht hinterlegt, stellen Sie Ihre Anfrage bitte über das <b>Kontaktformular</b> und
                            Sie erhalten ein <b>individuelles Angebot.</b>
                        </p>
                    </div>
                    <div className="uk-width-expand uk-inline">
                        <Anchor className="uk-flex uk-flex-right">
                            <i className="fa fa-3x fa-info-circle uk-text-danger" />
                        </Anchor>
                        <div data-uk-drop="pos: bottom-right">
                            <div className="uk-card uk-card-default uk-card-small uk-card-body">
                                Je nach Service werden Ihnen nicht immer Festpreise angezeigt, weil es zu viele unterschiedliche Modelle
                                gibt. Für diese unterbreiten wir Ihnen aber gerne ein individuelles Angebot.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServiceStationBanner;
