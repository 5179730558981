import _ from 'lodash';
import { pendingResponse } from '../fetchStatus';
import { createSelector } from 'reselect';
import { hostnameSelector } from './vHostRequestLocation';
import { extraParamsSelector } from './util';

export const hostsResponseSelector = state => state.hosts || pendingResponse;

export const hostsSelector = createSelector([hostsResponseSelector], hostsResponse => hostsResponse.data ?? []);

const newStandortUrlSelector = createSelector([extraParamsSelector], params => {
    if (!params.hasOwnProperty('newStandortUrl')) {
        throw new Error(`Expected second selector parameter to contain the property "newStandortUrl", instead got: ${params}`);
    }
    const { newStandortUrl } = params;
    if (_.isUndefined(newStandortUrl)) {
        return newStandortUrl;
    }
    if (!_.isString(newStandortUrl) || _.isEmpty(newStandortUrl)) {
        throw new Error(`Expected valid or undefined newStandortUrl, instead got: ${newStandortUrl}`);
    }
    return newStandortUrl;
});

// Host selectors
const currentHostSelector = createSelector([hostnameSelector, hostsSelector], (hostname, hosts) => _.find(hosts, { hostname }));
const fallbackHostSelector = createSelector([hostsSelector], hosts => _.find(hosts, { isFallback: true }));
const newHostSelector = createSelector(
    [hostsSelector, newStandortUrlSelector, fallbackHostSelector],
    (hosts, newStandortUrl, fallbackHost) => _.find(hosts, { standortUrl: newStandortUrl }) ?? fallbackHost
);

// Host property selector factories
const makeHostHostnameSelector = hostSelector => createSelector([hostSelector], host => host?.hostname);
const makeHostStandortUrlSelector = hostSelector => createSelector([hostSelector], host => host?.standortUrl);
const makeHostIsShopSystemSelector = hostSelector => createSelector([hostSelector], host => host && !!host.isShopSystem);
const makeHostIsUnknownSelector = hostSelector => createSelector([hostSelector], host => _.isNil(host));
const makeHostIsUniqSelector = (hostSelector, hostIsUnknownSelector) =>
    createSelector([hostSelector, hostsSelector, hostIsUnknownSelector], (host, hosts, hostIsUnknown) =>
        hostIsUnknown ? undefined : _.filter(hosts, { hostname: host.hostname }).length === 1
    );
const makeHostIndexStandortUrlSelector = (hostSelector, hostIsShopSystemSelector, hostStandortUrlSelector) =>
    createSelector([hostSelector, hostIsShopSystemSelector, hostStandortUrlSelector], (host, hostIsShopSystem, hostStandortUrl) =>
        host && hostIsShopSystem ? host.indexStandortUrl : hostStandortUrl
    );
const makeHostCookieProDomainIdSelector = hostSelector => createSelector([hostSelector], host => host?.cookieProDomainId);
const makeHostMatomoSiteIdSelector = hostSelector => createSelector([hostSelector], host => host?.matomoSiteId);

// Current host property selectors
export const currentHostHostnameSelector = makeHostHostnameSelector(currentHostSelector);
export const currentHostStandortUrlSelector = makeHostStandortUrlSelector(currentHostSelector);
export const currentHostIsShopSystemSelector = makeHostIsShopSystemSelector(currentHostSelector);
export const currentHostIsUnknownSelector = makeHostIsUnknownSelector(currentHostSelector);
export const currentHostIsUniqSelector = makeHostIsUniqSelector(currentHostSelector, currentHostIsUnknownSelector);
export const currentHostIndexStandortUrlSelector = makeHostIndexStandortUrlSelector(
    currentHostSelector,
    currentHostIsShopSystemSelector,
    currentHostStandortUrlSelector
);
export const currentHostCookieProDomainIdSelector = makeHostCookieProDomainIdSelector(currentHostSelector);
export const currentHostMatomoSiteIdSelector = makeHostMatomoSiteIdSelector(currentHostSelector);

// Fallback host property selectors
export const fallbackHostHostnameSelector = makeHostHostnameSelector(fallbackHostSelector);
export const fallbackHostStandortUrlSelector = makeHostStandortUrlSelector(fallbackHostSelector);
export const fallbackHostIsShopSystemSelector = makeHostIsShopSystemSelector(fallbackHostSelector);
export const fallbackHostIsUnknownSelector = makeHostIsUnknownSelector(fallbackHostSelector);
export const fallbackHostIsUniqSelector = makeHostIsUniqSelector(fallbackHostSelector, fallbackHostIsUnknownSelector);
export const fallbackHostIndexStandortUrlSelector = makeHostIndexStandortUrlSelector(
    fallbackHostSelector,
    fallbackHostIsShopSystemSelector,
    fallbackHostStandortUrlSelector
);
export const fallbackHostCookieProDomainIdSelector = makeHostCookieProDomainIdSelector(fallbackHostSelector);
export const fallbackHostMatomoSiteIdSelector = makeHostMatomoSiteIdSelector(fallbackHostSelector);

// New host property selectors
export const newHostHostnameSelector = makeHostHostnameSelector(newHostSelector);
export const newHostStandortUrlSelector = makeHostStandortUrlSelector(newHostSelector);
export const newHostIsShopSystemSelector = makeHostIsShopSystemSelector(newHostSelector);
export const newHostIsUnknownSelector = makeHostIsUnknownSelector(newHostSelector);
export const newHostIsUniqSelector = makeHostIsUniqSelector(newHostSelector, newHostIsUnknownSelector);
export const newHostIndexStandortUrlSelector = makeHostIndexStandortUrlSelector(
    newHostSelector,
    newHostIsShopSystemSelector,
    newHostStandortUrlSelector
);
export const newHostCookieProDomainIdSelector = makeHostCookieProDomainIdSelector(newHostSelector);
export const newHostMatomoSiteIdSelector = makeHostMatomoSiteIdSelector(newHostSelector);
