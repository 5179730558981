import React from 'react';
import exact from 'prop-types-exact';
import PropTypes from 'prop-types';

function Anchor({ className, style, children, 'data-uk-scroll': dataUkScroll, 'data-uk-slider-item': dataUkSliderItem }) {
    return (
        // The only allowed <a href="#"/> in this project.
        // eslint-disable-next-line react/forbid-elements
        <a href="#" style={style} className={className} data-uk-scroll={dataUkScroll} data-uk-slider-item={dataUkSliderItem}>
            {children}
        </a>
    );
}

Anchor.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    style: PropTypes.object,
    'data-uk-scroll': PropTypes.string,
    'data-uk-slider-item': PropTypes.string,
};

Anchor.propTypes = exact(Anchor.propTypes);

export default Anchor;
