import { NOT_FOUND_DID_MOUNT, NOT_FOUND_DID_UNMOUNT } from '../actionTypes';

const initialState = {
    mountCount: 0,
};

export default function notFound(state = initialState, action) {
    switch (action.type) {
        case NOT_FOUND_DID_MOUNT:
            return {
                mountCount: state.mountCount + 1,
            };
        case NOT_FOUND_DID_UNMOUNT:
            return {
                mountCount: state.mountCount - 1,
            };
        default:
            return state;
    }
}
