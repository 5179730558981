export const SET_SESSION_ID = 'SET_SESSION_ID';
export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';

export const LOAD_UIKIT_SUCCESS = 'LOAD_UIKIT_SUCCESS';

export const SET_CART = 'SET_CART';
export const RESET_CART = 'RESET_CART';

export const SELECT_SUBLOCATION = 'SELECT_SUBLOCATION';

export const SET_CATEGORY_FILTERS = 'SET_CATEGORY_FILTERS';
export const SET_SEARCH_STATS = 'SET_SEARCH_STATS';

export const SET_VHOST_REQUEST_LOCATION = 'SET_DOMAIN_HOSTNAME';

export const NOT_FOUND_DID_MOUNT = 'NOT_FOUND_DID_MOUNT';
export const NOT_FOUND_DID_UNMOUNT = 'NOT_FOUND_DID_UNMOUNT';

export const SET_COOKIE_CONSENT_CATEGORIES = 'SET_COOKIE_CONSENT_CATEGORIES';

export const UPDATE_TIRES_FILTER_VALUES = 'UPDATE_TIRES_FILTER_VALUES';
export const UPDATE_COMPLETE_WHEELS_FILTER_VALUES = 'UPDATE_COMPLETE_WHEELS_FILTER_VALUES';

export const SHOW_TECAR_TILE = 'SHOW_TECAR_TILE';
export const HIDE_TECAR_TILE = 'HIDE_TECAR_TILE';

export const SHOW_OPTIONS_SIDEBAR = 'SHOW_OPTIONS_SIDEBAR';
export const HIDE_OPTIONS_SIDEBAR = 'HIDE_OPTIONS_SIDEBAR';
export const TOGGLE_OPTIONS_SIDEBAR = 'TOGGLE_OPTIONS_SIDEBAR';
export const SET_IS_OPTIONS_SIDEBAR_VISIBLE = 'SET_IS_OPTIONS_SIDEBAR_VISIBLE';

export const SELECT_VEHICLE = 'SELECT_VEHICLE';
export const SELECT_SEARCH_VEHICLE = 'SELECT_SEARCH_VEHICLE';
export const SELECT_MANUAL_VEHICLE = 'SELECT_MANUAL_VEHICLE';
export const SET_FILTER_FORM = 'SET_FILTER_FORM';
export const SET_VEHICLE_MANUFACTURE = 'SET_VEHICLE_MANUFACTURE';
export const SET_KBA_FORM = 'SET_KBA_FORM';
export const SET_HSN_TSN_FORM = 'SET_HSN_TSN_FORM';

// New Action Types SSR
export const FETCH_HOSTS_REQUEST = 'FETCH_HOSTS_REQUEST';
export const FETCH_HOSTS_SUCCESS = 'FETCH_HOSTS_SUCCESS';
export const FETCH_HOSTS_FAILURE = 'FETCH_HOSTS_FAILURE';

export const FETCH_MARKENSTANDORT_REQUEST = 'FETCH_MARKENSTANDORT_REQUEST';
export const FETCH_MARKENSTANDORT_SUCCESS = 'FETCH_MARKENSTANDORT_SUCCESS';
export const FETCH_MARKENSTANDORT_FAILURE = 'FETCH_MARKENSTANDORT_FAILURE';

export const FETCH_PREVIEW_REQUEST = 'FETCH_PREVIEW_REQUEST';
export const FETCH_PREVIEW_SUCCESS = 'FETCH_PREVIEW_SUCCESS';
export const FETCH_PREVIEW_FAILURE = 'FETCH_PREVIEW_FAILURE';

export const FETCH_DIENSTLEISTUNGEN_REQUEST = 'FETCH_DIENSTLEISTUNGEN_REQUEST';
export const FETCH_DIENSTLEISTUNGEN_SUCCESS = 'FETCH_DIENSTLEISTUNGEN_SUCCESS';
export const FETCH_DIENSTLEISTUNGEN_FAILURE = 'FETCH_DIENSTLEISTUNGEN_FAILURE';

export const FETCH_DIENSTLEISTUNG_REQUEST = 'FETCH_DIENSTLEISTUNG_REQUEST';
export const FETCH_DIENSTLEISTUNG_SUCCESS = 'FETCH_DIENSTLEISTUNG_SUCCESS';
export const FETCH_DIENSTLEISTUNG_FAILURE = 'FETCH_DIENSTLEISTUNG_FAILURE';

export const FETCH_SERVICE_ANGEBOTE_REQUEST = 'FETCH_SERVICE_ANGEBOTE_REQUEST';
export const FETCH_SERVICE_ANGEBOTE_SUCCESS = 'FETCH_SERVICE_ANGEBOTE_SUCCESS';
export const FETCH_SERVICE_ANGEBOTE_FAILURE = 'FETCH_SERVICE_ANGEBOTE_FAILURE';
export const SET_SERVICE_ANGEBOTE_FILTER_VALUES = 'SET_SERVICE_ANGEBOTE_FILTER_VALUES';

export const FETCH_SERVICE_ARBEITSWERTE_REQUEST = 'FETCH_SERVICE_ARBEITSWERTE_REQUEST';
export const FETCH_SERVICE_ARBEITSWERTE_SUCCESS = 'FETCH_SERVICE_ARBEITSWERTE_SUCCESS';
export const FETCH_SERVICE_ARBEITSWERTE_FAILURE = 'FETCH_SERVICE_ARBEITSWERTE_FAILURE';
export const SET_SERVICE_ARBEITSWERTE_FILTER_VALUES = 'SET_SERVICE_ARBEITSWERTE_FILTER_VALUES';

export const FETCH_DAT_DVN_REQUEST = 'FETCH_DAT_DVN_REQUEST';
export const FETCH_DAT_DVN_SUCCESS = 'FETCH_DAT_DVN_SUCCESS';
export const FETCH_DAT_DVN_FAILURE = 'FETCH_DAT_DVN_FAILURE';
export const SET_DAT_DVN_VALUES = 'SET_DAT_DVN_VALUES';


export const FETCH_SERVICE_NAV_REQUEST = 'FETCH_SERVICE_NAV_REQUEST';
export const FETCH_SERVICE_NAV_SUCCESS = 'FETCH_SERVICE_NAV_SUCCESS';
export const FETCH_SERVICE_NAV_FAILURE = 'FETCH_SERVICE_NAV_FAILURE';
export const SET_SERVICE_NAV_FILTER_VALUES = 'SET_SERVICE_NAV_FILTER_VALUES';

export const FETCH_CAR_BRANDS_REQUEST = 'FETCH_CAR_BRANDS_REQUEST';
export const FETCH_CAR_BRANDS_SUCCESS = 'FETCH_CAR_BRANDS_SUCCESS';
export const FETCH_CAR_BRANDS_FAILURE = 'FETCH_CAR_BRANDS_FAILURE';

export const FETCH_ADD_CRUMBS_REQUEST = 'FETCH_ADD_CRUMBS_REQUEST';
export const FETCH_ADD_CRUMBS_SUCCESS = 'FETCH_ADD_CRUMBS_SUCCESS';
export const FETCH_ADD_CRUMBS_FAILURE = 'FETCH_ADD_CRUMBS_FAILURE';

export const FETCH_RELATED_CATEGORIES_REQUEST = 'FETCH_RELATED_CATEGORIES_REQUEST';
export const FETCH_RELATED_CATEGORIES_SUCCESS = 'FETCH_RELATED_CATEGORIES_SUCCESS';
export const FETCH_RELATED_CATEGORIES_FAILURE = 'FETCH_RELATED_CATEGORIES_FAILURE';

export const FETCH_OFFERS_REQUEST = 'FETCH_OFFERS_REQUEST';
export const FETCH_OFFERS_SUCCESS = 'FETCH_OFFERS_SUCCESS';
export const FETCH_OFFERS_FAILURE = 'FETCH_OFFERS_FAILURE';

export const FETCH_CATEGORIES_REQUEST = 'FETCH_CATEGORIES_REQUEST';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORIES_FAILURE';

export const FETCH_PRODUCTS_REQUEST = 'FETCH_PRODUCTS_REQUEST';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_FAILURE = 'FETCH_PRODUCTS_FAILURE';
export const SET_PRODUCTS_FILTER_VALUES = 'SET_PRODUCTS_FILTER_VALUES';

export const FETCH_PRODUCT_REQUEST = 'FETCH_PRODUCT_REQUEST';
export const FETCH_PRODUCT_SUCCESS = 'FETCH_PRODUCT_SUCCESS';
export const FETCH_PRODUCT_FAILURE = 'FETCH_PRODUCT_FAILURE';
export const SET_PRODUCT_VARIANT_ID = 'SET_PRODUCT_VARIANT_ID';

export const FETCH_SEO_META_DATA_REQUEST = 'FETCH_SEO_META_DATA_REQUEST';
export const FETCH_SEO_META_DATA_SUCCESS = 'FETCH_SEO_META_DATA_SUCCESS';
export const FETCH_SEO_META_DATA_FAILURE = 'FETCH_SEO_META_DATA_FAILURE';

export const FETCH_COUPONS_REQUEST = 'FETCH_COUPONS_REQUEST';
export const FETCH_COUPONS_SUCCESS = 'FETCH_COUPONS_SUCCESS';
export const FETCH_COUPONS_FAILURE = 'FETCH_COUPONS_FAILURE';

export const FETCH_CART_REQUEST = 'FETCH_CART_REQUEST';
export const FETCH_CART_SUCCESS = 'FETCH_CART_SUCCESS';
export const FETCH_CART_FAILURE = 'FETCH_CART_FAILURE';

export const FETCH_CART_STATS_REQUEST = 'FETCH_CART_STATS_REQUEST';
export const FETCH_CART_STATS_SUCCESS = 'FETCH_CART_STATS_SUCCESS';
export const FETCH_CART_STATS_FAILURE = 'FETCH_CART_STATS_FAILURE';

export const FETCH_RIM_PRODUCER_LOGOS_REQUEST = 'FETCH_RIM_PRODUCER_LOGOS_REQUEST';
export const FETCH_RIM_PRODUCER_LOGOS_SUCCESS = 'FETCH_RIM_PRODUCER_LOGOS_SUCCESS';
export const FETCH_RIM_PRODUCER_LOGOS_FAILURE = 'FETCH_RIM_PRODUCER_LOGOS_FAILURE';

export const FETCH_CATEGORY_LANDING_PAGE_CONTENT_FAILURE = 'FETCH_CATEGORY_LANDING_PAGE_CONTENT_FAILURE';
export const FETCH_CATEGORY_LANDING_PAGE_CONTENT_REQUEST = 'FETCH_CATEGORY_LANDING_PAGE_CONTENT_REQUEST';
export const FETCH_CATEGORY_LANDING_PAGE_CONTENT_SUCCESS = 'FETCH_CATEGORY_LANDING_PAGE_CONTENT_SUCCESS';

export const FETCH_WHEEL_DETAIL_REQUEST = 'FETCH_WHEEL_DETAIL_REQUEST';
export const FETCH_WHEEL_DETAIL_FAILURE = 'FETCH_WHEEL_DETAIL_FAILURE';
export const FETCH_WHEEL_DETAIL_SUCCESS = 'FETCH_WHEEL_DETAIL_SUCCESS';

export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';

export const SIGNUP_USER_REQUEST = 'SIGNUP_USER_REQUEST';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNUP_USER_FAILURE = 'SIGNUP_USER_FAILURE';

export const SIGNIN_USER_REQUEST = 'SIGNIN_USER_REQUEST';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNIN_USER_FAILURE = 'SIGNIN_USER_FAILURE';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';

export const INIT_USER_PASSWORD_REQUEST = 'INIT_USER_PASSWORD_REQUEST';
export const INIT_USER_PASSWORD_SUCCESS = 'INIT_USER_PASSWORD_SUCCESS';
export const INIT_USER_PASSWORD_FAILURE = 'INIT_USER_PASSWORD_FAILURE';

export const RESET_USER_PASSWORD_REQUEST = 'RESET_USER_PASSWORD_REQUEST';
export const RESET_USER_PASSWORD_SUCCESS = 'RESET_USER_PASSWORD_SUCCESS';
export const RESET_USER_PASSWORD_FAILURE = 'RESET_USER_PASSWORD_FAILURE';

export const UPDATE_USER_PASSWORD_REQUEST = 'UPDATE_USER_PASSWORD_REQUEST';
export const UPDATE_USER_PASSWORD_SUCCESS = 'UPDATE_USER_PASSWORD_SUCCESS';
export const UPDATE_USER_PASSWORD_FAILURE = 'UPDATE_USER_PASSWORD_FAILURE';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

export const FETCH_ORDERS_REQUEST = 'FETCH_ORDERS_REQUEST';
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS';
export const FETCH_ORDERS_FAILURE = 'FETCH_ORDERS_FAILURE';

export const POST_ORDERS_REQUEST = 'POST_ORDERS_REQUEST';
export const POST_ORDERS_SUCCESS = 'POST_ORDERS_SUCCESS';
export const POST_ORDERS_FAILURE = 'POST_ORDERS_FAILURE';

export const FETCH_TIRES_FAILURE = 'FETCH_TIRES_FAILURE';
export const FETCH_TIRES_REQUEST = 'FETCH_TIRES_REQUEST';
export const FETCH_TIRES_SUCCESS = 'FETCH_TIRES_SUCCESS';

export const FETCH_TIRE_OPTIONS_REQUEST = 'FETCH_TIRE_OPTIONS_REQUEST';
export const FETCH_TIRE_OPTIONS_SUCCESS = 'FETCH_TIRE_OPTIONS_SUCCESS';
export const FETCH_TIRE_OPTIONS_FAILURE = 'FETCH_TIRE_OPTIONS_FAILURE';

export const FETCH_GLOBAL_TIRE_OPTIONS_REQUEST = 'FETCH_GLOBAL_TIRE_OPTIONS_REQUEST';
export const FETCH_GLOBAL_TIRE_OPTIONS_SUCCESS = 'FETCH_GLOBAL_TIRE_OPTIONS_SUCCESS';
export const FETCH_GLOBAL_TIRE_OPTIONS_FAILURE = 'FETCH_GLOBAL_TIRE_OPTIONS_FAILURE';

export const FETCH_COMPLETE_WHEEL_OPTIONS_FAILURE = 'FETCH_COMPLETE_WHEEL_OPTIONS_FAILURE';
export const FETCH_COMPLETE_WHEEL_OPTIONS_REQUEST = 'FETCH_COMPLETE_WHEEL_OPTIONS_REQUEST';
export const FETCH_COMPLETE_WHEEL_OPTIONS_SUCCESS = 'FETCH_COMPLETE_WHEEL_OPTIONS_SUCCESS';

export const FETCH_COMPLETE_WHEELS_FAILURE = 'FETCH_COMPLETE_WHEELS_FAILURE';
export const FETCH_COMPLETE_WHEELS_REQUEST = 'FETCH_COMPLETE_WHEELS_REQUEST';
export const FETCH_COMPLETE_WHEELS_SUCCESS = 'FETCH_COMPLETE_WHEELS_SUCCESS';

export const FETCH_SERVICE_LANDINGPAGE_REQUEST = 'FETCH_SERVICE_LANDINGPAGE_REQUEST';
export const FETCH_SERVICE_LANDINGPAGE_SUCCESS = 'FETCH_SERVICE_LANDINGPAGE_SUCCESS';
export const FETCH_SERVICE_LANDINGPAGE_FAILURE = 'FETCH_SERVICE_LANDINGPAGE_FAILURE';

export const FETCH_COMPLETE_WHEEL_REQUEST = 'FETCH_COMPLETE_WHEEL_REQUEST';
export const FETCH_COMPLETE_WHEEL_SUCCESS = 'FETCH_COMPLETE_WHEEL_SUCCESS';
export const FETCH_COMPLETE_WHEEL_FAILURE = 'FETCH_COMPLETE_WHEEL_FAILURE';

export const FETCH_ADD_COUPON_HEIGHT_FAILURE = 'FETCH_ADD_COUPON_HEIGHT_FAILURE';
export const FETCH_ADD_COUPON_HEIGHT_REQUEST = 'FETCH_ADD_COUPON_HEIGHT_REQUEST';
export const FETCH_ADD_COUPON_HEIGHT_SUCCESS = 'FETCH_ADD_COUPON_HEIGHT_SUCCESS';

export const FETCH_ADD_PRODUCT_FAILURE = 'FETCH_ADD_PRODUCT_FAILURE';
export const FETCH_ADD_PRODUCT_REQUEST = 'FETCH_ADD_PRODUCT_REQUEST';
export const FETCH_ADD_PRODUCT_SUCCESS = 'FETCH_ADD_PRODUCT_SUCCESS';
export const FETCH_ADD_PRODUCT_RESET = 'FETCH_ADD_PRODUCT_RESET';

export const FETCH_VEHICLE_IDENTIFICTION_REQUEST = 'FETCH_VEHICLE_IDENTIFICTION_REQUEST';
export const FETCH_VEHICLE_IDENTIFICTION_SUCCESS = 'FETCH_VEHICLE_IDENTIFICTION_SUCCESS';
export const FETCH_VEHICLE_IDENTIFICTION_FAILURE = 'FETCH_VEHICLE_IDENTIFICTION_FAILURE';
