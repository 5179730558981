import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setCookieConsentCategories } from '../../../modules/actions/oneTrust';
import { useCookies } from 'react-cookie';
import { parseSearch } from '../../../helpers/UrlHelper';

const OneTrust = () => {
    const [{ OptanonConsent: consentCookie }] = useCookies(['OptanonConsent']);

    const dispatch = useDispatch();

    // OneTrust sadly does not give use a easy API for accessing the currently active consent categories.
    // To work around this, on first load we get the initial consent categories via the OptanonConsent cookie.
    useEffect(() => {
        const parsedConsentCookie = parseSearch(consentCookie);
        const consentCategoriesWithStatus = parsedConsentCookie?.groups?.split(',') ?? [];
        const consentCategories = consentCategoriesWithStatus.flatMap(categoryWithStatus => {
            const [consentCategory, status] = categoryWithStatus.split(':');

            if (status === '1') {
                return [consentCategory];
            } else {
                return [];
            }
        });
        dispatch(setCookieConsentCategories(consentCategories));

        // We explicitly want to only run this effect on the first render.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // If the user updates his consent, we listen for a event from CookiePro.
    useEffect(() => {
        const listener = e => {
            const consentCategories = e?.detail;

            dispatch(setCookieConsentCategories(consentCategories));
        };
        window.addEventListener('consent.onetrust', listener);

        return () => {
            window.removeEventListener('consent.onetrust', listener);
        };
    }, [dispatch]);

    return null;
};

export default OneTrust;
