import React from 'react';
import { InternalEventLink } from '../form-elements/Link';

function ServiceCategoryCard({ service, trackingAction }) {
    const { url, title, icon } = service;
    return (
        <InternalEventLink action={trackingAction} label={url} to={`/reparatur-wartung/${url}`} modifier="reset">
            <div className="uk-card uk-card-small uk-text-center uk-card-default">
                <div
                    className="uk-card-body uk-flex uk-flex-center aps-icon"
                    dangerouslySetInnerHTML={{ __html: icon }}
                    style={{ height: 150 }}
                />
                <div className="uk-card-footer uk-card-primary uk-text-truncate">{title}</div>
            </div>
        </InternalEventLink>
    );
}

export default ServiceCategoryCard;
