import {
    FETCH_SERVICE_ANGEBOTE_FAILURE,
    FETCH_SERVICE_ANGEBOTE_REQUEST,
    FETCH_SERVICE_ANGEBOTE_SUCCESS,
    SET_SERVICE_ANGEBOTE_FILTER_VALUES,
} from '../../../actionTypes';
import { FAILURE, FETCHING, pendingResponse, SUCCESS } from '../../../fetchStatus';

export default function serviceAngebote(state = pendingResponse, action) {
    switch (action.type) {
        case FETCH_SERVICE_ANGEBOTE_REQUEST:
            return {
                status: FETCHING,
            };
        case FETCH_SERVICE_ANGEBOTE_SUCCESS: {
            const { serviceAngebote, serviceTitle, filterValues } = action.payload.data;
            return {
                status: SUCCESS,
                data: { serviceAngebote, serviceTitle, filterValues },
            };
        }
        case FETCH_SERVICE_ANGEBOTE_FAILURE:
            return {
                status: FAILURE,
                error: action.payload.error,
            };
        case SET_SERVICE_ANGEBOTE_FILTER_VALUES: {
            const { filterValues } = action.payload;
            return {
                ...state,
                data: {
                    ...state.data,
                    filterValues,
                },
            };
        }
        default:
            return state;
    }
}
