import { createSelector } from 'reselect';
import { categoryUrlSelector } from './url';

export const isSearchResultsSelector = createSelector([categoryUrlSelector], categoryUrl => {
    return categoryUrl?.toLowerCase?.() === 'search';
});

const searchSelector = state => state.search;

export const searchStatsSelector = createSelector([searchSelector], search => search.stats);
