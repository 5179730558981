import { FETCH_MARKENSTANDORT_FAILURE, FETCH_MARKENSTANDORT_REQUEST, FETCH_MARKENSTANDORT_SUCCESS } from '../../actionTypes';
import { FAILURE, FETCHING, SUCCESS } from '../../fetchStatus';

const initialState = null;

export default function markenStandort(state = initialState, action) {
    switch (action.type) {
        case FETCH_MARKENSTANDORT_REQUEST:
            return {
                status: FETCHING,
            };
        case FETCH_MARKENSTANDORT_SUCCESS:
            return {
                status: SUCCESS,
                data: action.payload.markenStandort,
            };
        case FETCH_MARKENSTANDORT_FAILURE:
            return {
                status: FAILURE,
                error: action.payload.error,
            };
        default:
            return state;
    }
}
