import { FETCH_VEHICLE_IDENTIFICTION_FAILURE,FETCH_VEHICLE_IDENTIFICTION_SUCCESS,FETCH_VEHICLE_IDENTIFICTION_REQUEST } from '../../../src/modules/actionTypes';
import { FAILURE, FETCHING, SUCCESS } from '../../../src/modules/fetchStatus';

const identication ={
}

export default function vehicleidentification(state = identication, action) {
    switch (action.type) {
        case FETCH_VEHICLE_IDENTIFICTION_REQUEST:
            return {
                ...state,
                [action.payload]: {
                    status: FETCHING,
                },
            };
        case FETCH_VEHICLE_IDENTIFICTION_SUCCESS:
            const data = action.payload;
            return {
                [action.payload]: {
                    status: SUCCESS,
                    data: {
                        data
                    },
                },
            };
        case FETCH_VEHICLE_IDENTIFICTION_FAILURE:
            return {
                ...state,
                [action.payload]: {
                    status: FAILURE,
                    error: action.payload.error,
                },
            };
        default:
            return state;
    }
}
