import { FAILURE, FETCHING, SUCCESS } from '../fetchStatus';
import { FETCH_HOSTS_FAILURE, FETCH_HOSTS_REQUEST, FETCH_HOSTS_SUCCESS } from '../actionTypes';

const initialState = null;

export default function hosts(state = initialState, action) {
    switch (action.type) {
        case FETCH_HOSTS_REQUEST:
            return {
                status: FETCHING,
            };
        case FETCH_HOSTS_SUCCESS:
            return {
                status: SUCCESS,
                data: action.payload.hosts,
            };
        case FETCH_HOSTS_FAILURE:
            return {
                status: FAILURE,
                error: action.payload.error,
            };
        default:
            return state;
    }
}
