import React from 'react';

function InlineSpinner() {
    return (
        <div className="uk-width-1-1 uk-flex uk-flex-center">
            <div data-uk-spinner="" className="uk-spinner" />
        </div>
    );
}

export default InlineSpinner;
