import { SET_SESSION_ID } from '../../actionTypes';

// TODO: implement expire logic

export const setSessionId = sessionId => ({
    type: SET_SESSION_ID,
    payload: { sessionId },
});

export const setNewSessionId = () => {
    const newSessionId = getNewSessionId();

    return setSessionId(newSessionId);
};

export const getNewSessionId = () =>
    Math.random().toString(36).substr(2, 34) + Math.random().toString(36).substr(2, 34) + Math.random().toString(36).substr(2, 34);
