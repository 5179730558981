import _ from 'lodash';
import { FETCH_DIENSTLEISTUNGEN_FAILURE, FETCH_DIENSTLEISTUNGEN_REQUEST, FETCH_DIENSTLEISTUNGEN_SUCCESS } from '../../actionTypes';
import { FAILURE, FETCHING, SUCCESS } from '../../fetchStatus';

const initialState = null;

export default function dienstleistungen(state = initialState, action) {
    switch (action.type) {
        case FETCH_DIENSTLEISTUNGEN_REQUEST:
            return {
                status: FETCHING,
            };
        case FETCH_DIENSTLEISTUNGEN_SUCCESS:
            return {
                status: SUCCESS,
                data: _.sortBy(action.payload.dienstleistungen, [
                    function (dienstleistung) {
                        if (dienstleistung.url === 'serviceAnfrage') {
                            return 'ZZZ';
                            // Laut Techno soll die Kachel, welche zur serviceAnfrage leitet, immer am Ende stehen.
                        } else return dienstleistung.title.replace('Ö', 'OE').replace('Ü', 'UE').replace('Ä', 'AE');
                    },
                ]),
            };
        case FETCH_DIENSTLEISTUNGEN_FAILURE:
            return {
                status: FAILURE,
                error: action.payload.error,
            };
        default:
            return state;
    }
}
