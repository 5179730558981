import { Route } from 'react-router-dom';
import React from 'react';
import { useSelector } from 'react-redux';
import { useStandortAwareHref } from './internal/vHostHelper';
import PropTypes from 'prop-types';
import { validatePage } from './internal/customPropTypesValidators';
import { standortUrlSelector } from '../../../modules/selectors/url';
import { historyPushServerSide, historyReplaceServerSide } from './internal/locationHelper';
// StandortRedirect abstracts Redirect.
// eslint-disable-next-line no-restricted-imports
import { Redirect } from 'react-router-dom';

function ServerSideRedirect({ to, push }) {
    return (
        <Route
            render={({ staticContext }) => {
                if (staticContext) {
                    // Server side only
                    staticContext.url = to;
                } else {
                    if (push) {
                        historyPushServerSide(to);
                    } else {
                        historyReplaceServerSide(to);
                    }
                }

                return null;
            }}
        />
    );
}

function InnerStandortRedirect({ currentStandortUrl, to: page, newStandortUrl, forceServerSideRouting = false, push, debug }) {
    const { routeServerSide, to } = useStandortAwareHref({ page, newStandortUrl, forceServerSideRouting, currentStandortUrl });

    if (debug) return <pre>{JSON.stringify({ routeServerSide, to }, null, 2)}</pre>;

    if (routeServerSide) {
        return <ServerSideRedirect to={to} push={push} />;
    } else {
        // TODO: fix route params injection
        return <Redirect to={to} />;
    }
}

InnerStandortRedirect.propTypes = {
    currentStandortUrl: PropTypes.string,
    to: validatePage,
    newStandortUrl: PropTypes.string,
    forceServerSideRouting: PropTypes.bool,
    push: PropTypes.bool,
};

function StandortRedirect({ to, newStandortUrl, forceServerSideRouting, push, debug }) {
    const currentStandortUrl = useSelector(standortUrlSelector);

    return (
        <InnerStandortRedirect
            currentStandortUrl={currentStandortUrl}
            to={to}
            newStandortUrl={newStandortUrl}
            forceServerSideRouting={forceServerSideRouting}
            push={push}
            debug={debug}
        />
    );
}

StandortRedirect.propTypes = {
    to: PropTypes.string.isRequired,
    newStandortUrl: PropTypes.string,
    forceServerSideRouting: PropTypes.bool,
    push: PropTypes.bool,
    debug: PropTypes.bool,
};

export default StandortRedirect;
