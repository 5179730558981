import { createSelector } from 'reselect';

export const oneTrustSelector = state => state.oneTrust;

export const consentCategoriesSelector = createSelector([oneTrustSelector], oneTrust => oneTrust?.consentCategories);

export const canShowGoogleMapsSelector = createSelector([consentCategoriesSelector], consentCategories => {
    // Allow Google Maps if "Funktionelle Cookies" (ID = "C0003") has consent
    return consentCategories?.includes?.('C0003') ?? false;
});

export const canTrackSelector = createSelector([consentCategoriesSelector], consentCategories => {
    // Allow tracking if "Leistungs-Cookies" (ID = "C0002") has consent
    return consentCategories?.includes?.('C0002') ?? false;
});
