import React from 'react';
import { useStandortHistory } from '../../../../VHost/StandortHistory';

export function LoginButtonsDesktop({ toggleLoginDropdown }) {
    const history = useStandortHistory();
    return (
        <div className="login-button uk-grid uk-grid-small uk-child-width-1-2" data-uk-grid="">
            <div>
                <button className="btn-secondary-ghost btn-sm trail-icon-profile uk-flex-right" onClick={() => history.push('/account')}>
                    Shop-Login
                </button>
            </div>
            <div>
                <button className="btn-secondary-ghost btn-sm icon-chevrondown trail-icon-profile" onClick={toggleLoginDropdown}>
                    Login
                </button>
            </div>
        </div>
    );
}

export function LoginButtonsMobile({ toggleLoginDropdown, closeSidebar }) {
    const history = useStandortHistory();

    return (
        <div className="login-button-mobile active">
            <button
                className="btn-secondary-ghost btn-sm icon-profile"
                onClick={() => {
                    history.push('/account', () => {
                        closeSidebar();
                    });
                }}
            >
                Shop-Login
            </button>
            <button className="btn-secondary-ghost btn-sm icon-profile uk-margin-small-top" onClick={toggleLoginDropdown}>
                Login
            </button>
        </div>
    );
}
