import { createSelector } from 'reselect';
import { standortStateSelector } from '../standort';
import _ from 'lodash';
import { pendingResponse } from '../../fetchStatus';
import { categoryUrlSelector, standortUrlSelector } from '../url';
import { userSelector } from '../auth';

export const categoriesResponseSelector = createSelector(
    [standortStateSelector],
    standortState => standortState.categories || pendingResponse
);

export const rawCategoriesSelector = createSelector([categoriesResponseSelector], categoriesResponse => categoriesResponse?.data);

const filterNOAEmployeeCategory = (categories = []) => {
    return categories.flatMap(category => {
        if (category?.isNOAEmployeeCategory) {
            return [];
        } else {
            return [
                {
                    ...category,
                    subCategories: filterNOAEmployeeCategory(category.subCategories),
                },
            ];
        }
    });
};

export const categoriesSelector = createSelector(
    [rawCategoriesSelector, standortUrlSelector, userSelector],
    (rawCategories, standortUrl, user) => {
        if (['nord-ostsee-automobile-schleswig-holstein', 'nord-ostsee-automobile-hamburg'].includes(standortUrl) || user?.isNOAEmployee) {
            return rawCategories;
        } else {
            return filterNOAEmployeeCategory(rawCategories);
        }
    }
);

const urlPresentationPairs = categories =>
    _.flatMap(categories, category => [[category.url, category], ...urlPresentationPairs(category.subCategories)]);

export const categoryUrlToCategoryMapSelector = createSelector([categoriesSelector], categories => {
    return new Map(urlPresentationPairs(categories));
});

const categorySelector = createSelector([categoryUrlSelector, categoryUrlToCategoryMapSelector], (categoryUrl, categoryUrlToCategory) =>
    categoryUrlToCategory.get(categoryUrl)
);

export const presentationSelector = createSelector([categorySelector], category => category?.presentation);
export const subCategoriesSelector = createSelector([categorySelector], category => category?.subCategories);
