import _ from 'lodash';

/**
 * PropTypes validator function for page.
 *
 * Previously/also known as to, toWithoutStandortUrl, pathnameWithoutStandortUrl, pathWithoutStandortUrl.
 *
 * Requires auxiliary props `currentStandortUrl` and `newStandortUrl` for proper validation.
 */
export function validatePage(props, propName, componentName) {
    const { currentStandortUrl, newStandortUrl, [propName]: to } = props;
    if (!_.isString(to) || _.isEmpty(to)) {
        throw new Error(`Invalid prop \`${propName}\` supplied to \`${componentName}\`: A non-empty string is required.`);
    }
    if (!to.startsWith('/')) {
        throw new Error(`Invalid prop \`${propName}\` supplied to \`${componentName}\`: Must start with "/", instead got: "${to}"`);
    }
    if (currentStandortUrl && to.startsWith(`/${currentStandortUrl}`)) {
        throw new Error(
            `Invalid prop \`${propName}\` supplied to \`${componentName}\`: Must not start with the current standortUrl "${currentStandortUrl}", instead got: "${to}"`
        );
    }
    if (newStandortUrl && to.startsWith(`/${newStandortUrl}`)) {
        throw new Error(
            `Invalid prop \`${propName}\` supplied to \`${componentName}\`: Must not start with the new standortUrl "${newStandortUrl}", instead got: "${to}"`
        );
    }
}
