import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import { fetchDienstleistungenIfNeeded } from '../modules/actions/standort/dienstleistungen';
import { fetchCategoriesIfNeeded } from '../modules/actions/standort/categories';
import { fetchSeoMetaDataIfNeeded } from '../modules/actions/standort/seoMetaData';
import Layout from './Layout';
import React from 'react';
import SeoRedirects from './components/Redirects/SeoRedirects';
import { seoMetaDataResponseSelector } from '../modules/selectors/standort/seoMetaData';
import { dienstleistungenResponseSelector } from '../modules/selectors/standort/dienstleistungen';
import { categoriesResponseSelector } from '../modules/selectors/standort/categories';
import MarkenStandortRedirects from './components/Redirects/MarkenStandortRedirects';
import { markenStandortResponseSelector } from '../modules/selectors/standort/markenStandort';
import { fetchMarkenStandortIfNeeded } from '../modules/actions/standort/markenStandort';

// Frontload all global data which depends on a correct standortUrl.
const StandortFrontload = ({ markenStandortResponse, seoMetaDataResponse }) => {
    return (
        <MarkenStandortRedirects>
            <SeoRedirects>
                <Layout markenStandortResponse={markenStandortResponse} seoMetaDataResponse={seoMetaDataResponse} />
            </SeoRedirects>
        </MarkenStandortRedirects>
    );
};

const frontload = async ({
    fetchCategoriesIfNeeded,
    fetchDienstleistungenIfNeeded,
    fetchMarkenStandortIfNeeded,
    fetchSeoMetaDataIfNeeded,
}) => {
    await Promise.all([
        fetchMarkenStandortIfNeeded(),
        fetchSeoMetaDataIfNeeded(),
        fetchDienstleistungenIfNeeded(),
        fetchCategoriesIfNeeded(),
    ]);
};

const mapStateToProps = state => ({
    // Every frontload component needs to access the responses it triggers inside frontload.
    // Otherwise the `onUpdate` parameter of `frontloadConnect` will not trigger a new call of the frontload function,
    // even though some redux/external state has changed which would require a refetch of external data.
    markenStandortResponse: markenStandortResponseSelector(state),
    seoMetaDataResponse: seoMetaDataResponseSelector(state),
    dienstleistungenResponse: dienstleistungenResponseSelector(state),
    categoriesResponse: categoriesResponseSelector(state),
});

const mapDispatchToProps = {
    fetchMarkenStandortIfNeeded,
    fetchSeoMetaDataIfNeeded,
    fetchDienstleistungenIfNeeded,
    fetchCategoriesIfNeeded,
};

export default connect(mapStateToProps, mapDispatchToProps)(frontloadConnect(frontload, { onUpdate: true })(StandortFrontload));
