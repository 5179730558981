import { HIDE_OPTIONS_SIDEBAR, SET_IS_OPTIONS_SIDEBAR_VISIBLE, SHOW_OPTIONS_SIDEBAR, TOGGLE_OPTIONS_SIDEBAR } from '../actionTypes';

const initialState = { isVisible: false };

export default function optionsSidebar(state = initialState, action) {
    switch (action.type) {
        case SHOW_OPTIONS_SIDEBAR:
            return {
                ...state,
                isVisible: true,
            };
        case HIDE_OPTIONS_SIDEBAR:
            return {
                ...state,
                isVisible: false,
            };
        case TOGGLE_OPTIONS_SIDEBAR:
            return {
                ...state,
                isVisible: !state.isVisible,
            };
        case SET_IS_OPTIONS_SIDEBAR_VISIBLE:
            return {
                ...state,
                isVisible: action.payload.isVisible,
            };
        default:
            return state;
    }
}
