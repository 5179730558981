import React from 'react';
import { connect } from 'react-redux';
import { seoMetaDataSelector } from '../../../modules/selectors/standort/seoMetaData';
import { isEmpty } from 'lodash';
import Image from '../Image/Image';
import { isNotFoundMountedSelector } from '../../../modules/selectors/notFound';
import { standortUrlSelector } from '../../../modules/selectors/url';
import { clearScrollTo } from '../../../modules/actions/scrollToViewport';

class SeoContainer extends React.Component {
    componentDidUpdate(prevProps) {
        const { scrollTo } = this.props;
        if (scrollTo && scrollTo !== prevProps.scrollTo) {
            const element = document.getElementById(scrollTo);
            if (element) {
                const offset = 150;
                const topOffset = element.getBoundingClientRect().top - offset;
                window.scrollBy({ top: topOffset, behavior: 'smooth' });
                this.props.dispatch(clearScrollTo());
            }
        }
    }

    render() {
        const { seo, isNotFoundMounted } = this.props;

        if (isEmpty(seo)) {
            return null;
        }

        // Don't render SEO data on 404 pages.
        if (isNotFoundMounted) {
            return null;
        }

        const { 'block-1': block1, 'block-2': block2, 'block-3': block3, 'block-4': block4, 'block-5': block5, 'block-6': block6 } = seo;

        const hasContent = seo.einleitung || block1 || block2 || block3 || block4 || block5 || block6;

        if (!hasContent) return null;
        return (
            <section className="uk-section uk-section-default uk-section-small" id="seo-container">
                <div className="uk-container">
                    <div className="seo_text_block">
                        <div className="--shop-secondary" dangerouslySetInnerHTML={{ __html: seo.einleitung }} />
                    </div>
                    {/* {seo.seoImage && <Image src={`https://techno-images.s3.eu-central-1.amazonaws.com/seo-images/${seo.seoImage}`} alt="seo-gross" />} */}
                    <div className="seo_text_block">
                        <div className="--shop-secondary" dangerouslySetInnerHTML={{ __html: block1 }} />
                    </div>
                    <div className="seo_text_block">
                        <div className="--shop-secondary" dangerouslySetInnerHTML={{ __html: block2 }} />
                    </div>
                    <div className="seo_text_block">
                        <div className="--shop-secondary" dangerouslySetInnerHTML={{ __html: block3 }} />
                    </div>
                    <div className="seo_text_block">
                        <div className="--shop-secondary" dangerouslySetInnerHTML={{ __html: block4 }} />
                    </div>
                    <div className="seo_text_block">
                        <div className="--shop-secondary" dangerouslySetInnerHTML={{ __html: block5 }} />
                    </div>
                    <div className="seo_text_block">
                        <div className="--shop-secondary" dangerouslySetInnerHTML={{ __html: block6 }} />
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = state => ({
    seo: seoMetaDataSelector(state),
    standortUrl: standortUrlSelector(state),
    isNotFoundMounted: isNotFoundMountedSelector(state),
    scrollTo: state.scrollToViewport.scrollTo
});

export default connect(mapStateToProps)(SeoContainer);
