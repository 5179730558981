import {
    DELETE_USER_FAILURE,
    DELETE_USER_REQUEST,
    DELETE_USER_SUCCESS,
    FETCH_USER_FAILURE,
    FETCH_USER_REQUEST,
    FETCH_USER_SUCCESS,
    RESET_USER_PASSWORD_FAILURE,
    RESET_USER_PASSWORD_REQUEST,
    RESET_USER_PASSWORD_SUCCESS,
    SIGNIN_USER_FAILURE,
    SIGNIN_USER_REQUEST,
    SIGNIN_USER_SUCCESS,
    SIGNOUT_USER_SUCCESS,
    SIGNUP_USER_FAILURE,
    SIGNUP_USER_REQUEST,
    SIGNUP_USER_SUCCESS,
    UPDATE_USER_FAILURE,
    UPDATE_USER_PASSWORD_FAILURE,
    UPDATE_USER_PASSWORD_REQUEST,
    UPDATE_USER_PASSWORD_SUCCESS,
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS,
} from '../../actionTypes';
import { FAILURE, FETCHING, SUCCESS } from '../../fetchStatus';

const initialState = null;

export default function user(state = initialState, action) {
    switch (action.type) {
        case FETCH_USER_REQUEST:
        case SIGNIN_USER_REQUEST:
        case SIGNUP_USER_REQUEST:
        case UPDATE_USER_REQUEST:
        case UPDATE_USER_PASSWORD_REQUEST:
        case DELETE_USER_REQUEST:
        case RESET_USER_PASSWORD_REQUEST:
            return {
                status: FETCHING,
            };
        case FETCH_USER_SUCCESS:
        case SIGNIN_USER_SUCCESS:
        case SIGNUP_USER_SUCCESS:
        case SIGNOUT_USER_SUCCESS:
        case UPDATE_USER_SUCCESS:
        case UPDATE_USER_PASSWORD_SUCCESS:
        case DELETE_USER_SUCCESS:
        case RESET_USER_PASSWORD_SUCCESS:
            return {
                status: SUCCESS,
                data: action.payload.user,
            };
        case FETCH_USER_FAILURE:
        case SIGNIN_USER_FAILURE:
        case SIGNUP_USER_FAILURE:
        case UPDATE_USER_FAILURE:
        case UPDATE_USER_PASSWORD_FAILURE:
        case DELETE_USER_FAILURE:
        case RESET_USER_PASSWORD_FAILURE:
            return {
                status: FAILURE,
                error: action.payload.error,
            };
        default:
            return state;
    }
}
