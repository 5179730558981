import { FETCH_ADD_CRUMBS_FAILURE, FETCH_ADD_CRUMBS_REQUEST, FETCH_ADD_CRUMBS_SUCCESS } from '../actionTypes';
import { FAILURE, FETCHING, SUCCESS } from '../fetchStatus';

const initialState = null;

export default function additionalCrumbs(state = initialState, action) {
    switch (action.type) {
        case FETCH_ADD_CRUMBS_REQUEST:
            return {
                status: FETCHING,
            };
        case FETCH_ADD_CRUMBS_SUCCESS:
            return {
                status: SUCCESS,
                data: action.payload.additionalCrumbs,
            };
        case FETCH_ADD_CRUMBS_FAILURE:
            return {
                status: FAILURE,
                error: action.payload.error,
            };
        default:
            return state;
    }
}
