import { FETCH_RELATED_CATEGORIES_FAILURE, FETCH_RELATED_CATEGORIES_REQUEST, FETCH_RELATED_CATEGORIES_SUCCESS } from '../../actionTypes';
import { FAILURE, FETCHING, SUCCESS } from '../../fetchStatus';

const initialState = null;

export default function relatedCategories(state = initialState, action) {
    switch (action.type) {
        case FETCH_RELATED_CATEGORIES_REQUEST:
            return {
                status: FETCHING,
            };
        case FETCH_RELATED_CATEGORIES_SUCCESS:
            return {
                status: SUCCESS,
                data: action.payload.relatedCategories,
            };
        case FETCH_RELATED_CATEGORIES_FAILURE:
            return {
                status: FAILURE,
                error: action.payload.error,
            };
        default:
            return state;
    }
}
