import { combineReducers } from 'redux';

import categories from './standort/categories';
import dienstleistungen from './standort/dienstleistungen';
import landingPage from './standort/landingPage';
import markenStandort from './standort/markenStandort';
import offers from './standort/offers';
import previews from './standort/previews';
import relatedCategories from './standort/relatedCategories';
import seoMetaData from './standort/seoMetaData';
import service from './standort/service';
import session from './standort/session';
import subLocation from './standort/subLocation';
import tires from './standort/tires';
import vehicleFilter from './standort/vehicleFilter';
import wheelDetail from './standort/wheelDetail';
import completeWheels from './standort/completeWheels';
import coupons from './standort/coupons';

const initialState = {};

const standortReducers = combineReducers({
    categories,
    completeWheels,
    dienstleistungen,
    landingPage,
    markenStandort,
    offers,
    previews,
    relatedCategories,
    seoMetaData,
    service,
    session,
    subLocation,
    tires,
    vehicleFilter,
    wheelDetail,
    coupons,
});

export default function standort(state = initialState, action) {
    const standortUrl = action?.payload?.standortUrl;

    if (standortUrl) {
        return {
            ...state,
            [standortUrl]: standortReducers(state[standortUrl], action),
        };
    } else {
        return state;
    }
}
