import { createSelector } from 'reselect';
import { getOrigin } from '../../app/components/VHost/internal/vHostHelper';

const vHostRequestLocationSelector = state => state.vHostRequestLocation;

export const protocolSelector = createSelector([vHostRequestLocationSelector], vHostRequestLocation => vHostRequestLocation.protocol);
export const hostnameSelector = createSelector([vHostRequestLocationSelector], vHostRequestLocation => vHostRequestLocation.hostname);
export const portSelector = createSelector([vHostRequestLocationSelector], vHostRequestLocation => vHostRequestLocation.port);
export const originSelector = createSelector([protocolSelector, hostnameSelector, portSelector], (protocol, hostname, port) => {
    return getOrigin({ protocol, hostname, port });
});
