import useSWR from 'swr';
import { apiClient } from '../../../../helpers/apiHelperV2';
import { toast } from '../../../../helpers/toastHelper';
import { themeContextApisSelector } from '../../../../modules/selectors/standort/theme';
import { useSelector } from 'react-redux';

function useEFAData() {
    const themeContextApis = useSelector(themeContextApisSelector);

    const efaDataUrl = themeContextApis.efaData;

    const { data: content, error } = useSWR(
        efaDataUrl || null,
        async url => {
            const { data } = await apiClient.get(url);
            return data;
        },
        { revalidateOnFocus: false, onError: err => toast({ err }) }
    );

    return { content, error };
}

export default useEFAData;
