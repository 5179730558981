import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import { fetchUserIfNeeded } from '../modules/actions/user';
import { userResponseSelector } from '../modules/selectors/auth';

const GlobalClientFrontload = () => {
    return null;
};

const frontload = async props => {
    await Promise.all([props.fetchUserIfNeeded()]);
};

const mapStateToProps = state => ({
    userResponse: userResponseSelector(state),
});

const mapDispatchToProps = {
    fetchUserIfNeeded,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(frontloadConnect(frontload, { onUpdate: true, noServerRender: true })(GlobalClientFrontload));
