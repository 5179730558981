import { FETCH_WHEEL_DETAIL_FAILURE, FETCH_WHEEL_DETAIL_REQUEST, FETCH_WHEEL_DETAIL_SUCCESS } from '../../actionTypes';
import { FAILURE, FETCHING, SUCCESS } from '../../fetchStatus';

export default function wheelDetail(state = [], action) {
    switch (action.type) {
        case FETCH_WHEEL_DETAIL_REQUEST:
            return {
                ...state,
                [action.payload.wheelId]: {
                    status: FETCHING,
                },
            };
        case FETCH_WHEEL_DETAIL_SUCCESS:
            const { tire } = action.payload.data;
            return {
                ...state,
                [action.payload.wheelId]: {
                    status: SUCCESS,
                    data: tire,
                },
            };

        case FETCH_WHEEL_DETAIL_FAILURE:
            return {
                ...state,
                [action.payload.wheelId]: {
                    status: FAILURE,
                    error: action.payload.error,
                },
            };
        default:
            return state;
    }
}
