import { combineReducers } from 'redux';
import categoryLandingPageContent from './landingPage/categoryLandingPageContent';

const initialState = {};

const landingPageReducers = combineReducers({
    categoryLandingPageContent,
});

export default function landingPage(state = initialState, action) {
    const landingPageUrl = action?.payload?.landingPageUrl;
    if (landingPageUrl) {
        return {
            ...state,
            [landingPageUrl]: landingPageReducers(state[landingPageUrl], action),
        };
    } else {
        return state;
    }
}
