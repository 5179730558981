import { createSelector } from 'reselect';
import { standortStateSelector } from '../standort';
import _ from 'lodash';
import { pendingResponse } from '../../fetchStatus';
import { extraParamsSelector } from '../util';

export const dienstleistungenResponseSelector = createSelector(
    [standortStateSelector],
    standortState => standortState.dienstleistungen || pendingResponse
);

export const dienstleistungenSelector = createSelector(
    [dienstleistungenResponseSelector],
    dienstleistungenResponse => dienstleistungenResponse?.data
);

export const serviceAnfrageSelector = createSelector([dienstleistungenSelector], dienstleistungen =>
    _.find(dienstleistungen, { url: 'serviceAnfrage' })
);

const serviceUrlSelector = createSelector([extraParamsSelector], params => {
    const { serviceUrl } = params;
    if (_.isNil(serviceUrl) || (_.isString(serviceUrl) && _.isEmpty(serviceUrl))) return undefined;
    if (!_.isString(serviceUrl)) throw new Error(`Expected valid or not existing serviceUrl, instead got: ${serviceUrl}`);
    return serviceUrl;
});

export const serviceAnfrageByServiceUrlSelector = createSelector(
    [dienstleistungenSelector, serviceUrlSelector],
    (dienstleistungen, serviceUrl) => (serviceUrl ? _.find(dienstleistungen, { url: serviceUrl }) : undefined)
);
