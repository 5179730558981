import React, { useMemo, useState } from 'react';
import { ExternalEventLink } from '../form-elements/Link';
import Select from '../form-elements/Select';

function ExternalServiceSelector({ externalServices = [] }) {
    const [selectedServiceUrl, setSelectedServiceUrl] = useState(() => externalServices.find(service => service.default)?.url ?? '');
    const selectedServiceName = useMemo(() => {
        return externalServices.find(externalService => externalService.url === selectedServiceUrl)?.name;
    }, [externalServices, selectedServiceUrl]);

    if (!externalServices.length) return null;

    return (
        <section className="uk-section uk-section-primary uk-section-small">
            <div className="uk-container">
                <div className="uk-width-1-1 uk-text-bold uk-text-lead">Werkstatttermin online buchen</div>
                <div className="uk-grid-small uk-flex uk-flex-middle uk-flex-center uk-flex-left@s" data-uk-grid="">
                    <div className="uk-width-1-1 uk-width-expand@s uk-text-large">
                        <Select
                            onChange={({ value }) => setSelectedServiceUrl(value)}
                            name="externalService"
                            value={selectedServiceUrl}
                            options={externalServices.map(externalService => {
                                return {
                                    label: externalService.name,
                                    value: externalService.url,
                                };
                            })}
                        />
                    </div>
                    <div className="uk-width-auto@s">
                        <ExternalEventLink
                            action="Klick-Externe-Werkstatt"
                            label={selectedServiceName}
                            to={selectedServiceUrl}
                            modifier="secondary"
                        >
                            Zur Termin-Buchung
                        </ExternalEventLink>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ExternalServiceSelector;
