import { FETCH_ADD_PRODUCT_FAILURE, FETCH_ADD_PRODUCT_REQUEST, FETCH_ADD_PRODUCT_SUCCESS, FETCH_ADD_PRODUCT_RESET } from '../actionTypes';
import { FAILURE, FETCHING, SUCCESS } from '../fetchStatus';

const initialState = null;

export default function productId(state = initialState, action) {
    switch (action.type) {
        case FETCH_ADD_PRODUCT_REQUEST:
            return {
                status: FETCHING,
            };
        case FETCH_ADD_PRODUCT_SUCCESS:
            return {
                status: SUCCESS,
                data: action.payload.productID,
            };
        case FETCH_ADD_PRODUCT_FAILURE:
            return {
                status: FAILURE,
                error: action.payload.error,
            };
        case FETCH_ADD_PRODUCT_RESET:
            return null;
            
        default:
            return state;
    }
}
