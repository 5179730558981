import { FETCH_PREVIEW_FAILURE, FETCH_PREVIEW_REQUEST, FETCH_PREVIEW_SUCCESS } from '../../actionTypes';
import { FAILURE, FETCHING, SUCCESS } from '../../fetchStatus';

const initialState = {};

export default function previews(state = initialState, action) {
    switch (action.type) {
        case FETCH_PREVIEW_REQUEST:
            return {
                ...state,
                [action.payload.previewCategoriesKey]: {
                    status: FETCHING,
                },
            };
        case FETCH_PREVIEW_SUCCESS:
            return {
                ...state,
                [action.payload.previewCategoriesKey]: {
                    status: SUCCESS,
                    data: action.payload.preview,
                },
            };
        case FETCH_PREVIEW_FAILURE:
            return {
                ...state,
                [action.payload.previewCategoriesKey]: {
                    status: FAILURE,
                    error: action.payload.error,
                },
            };
        default:
            return state;
    }
}
