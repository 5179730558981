export const SCROLL_TO_ELEMENT = 'SCROLL_TO_ELEMENT';
export const CLEAR_SCROLL_TO = 'CLEAR_SCROLL_TO';

export const scrollToElement = (elementId) => ({
    type: SCROLL_TO_ELEMENT,
    payload: elementId,
});

export const clearScrollTo = () => ({
    type: CLEAR_SCROLL_TO,
});