import {
    FETCH_COMPLETE_WHEEL_FAILURE,
    FETCH_COMPLETE_WHEEL_OPTIONS_FAILURE,
    FETCH_COMPLETE_WHEEL_OPTIONS_REQUEST,
    FETCH_COMPLETE_WHEEL_OPTIONS_SUCCESS,
    FETCH_COMPLETE_WHEEL_REQUEST,
    FETCH_COMPLETE_WHEEL_SUCCESS,
    FETCH_COMPLETE_WHEELS_FAILURE,
    FETCH_COMPLETE_WHEELS_REQUEST,
    FETCH_COMPLETE_WHEELS_SUCCESS,
    UPDATE_COMPLETE_WHEELS_FILTER_VALUES,
} from '../../actionTypes';
import { FAILURE, FETCHING, SUCCESS } from '../../fetchStatus';

const initialState = {
    completeWheelsResponseBySearchParams: {},
    completeWheelOptionsResponseBySearchParams: {},
    selectedOptions: {},
};

export default function completeWheels(state = initialState, action) {
    switch (action.type) {
        case FETCH_COMPLETE_WHEELS_REQUEST:
            return {
                ...state,
                completeWheelsResponseBySearchParams: {
                    ...state.completeWheelsResponseBySearchParams,
                    [action.payload.searchParams]: {
                        status: FETCHING,
                    },
                },
            };
        case FETCH_COMPLETE_WHEELS_SUCCESS: {
            const { data } = action.payload;
            return {
                ...state,
                completeWheelsResponseBySearchParams: {
                    ...state.completeWheelsResponseBySearchParams,
                    [action.payload.searchParams]: {
                        status: SUCCESS,
                        data: {
                            completeWheels: data.completeWheels,
                            count: data.count,
                        },
                    },
                },
            };
        }
        case FETCH_COMPLETE_WHEELS_FAILURE:
            return {
                ...state,
                completeWheelsResponseBySearchParams: {
                    ...state.completeWheelsResponseBySearchParams,
                    [action.payload.searchParams]: {
                        status: FAILURE,
                        error: action.payload.error,
                    },
                },
            };
        case FETCH_COMPLETE_WHEEL_OPTIONS_REQUEST:
            return {
                ...state,
                completeWheelOptionsResponseBySearchParams: {
                    ...state.completeWheelOptionsResponseBySearchParams,
                    [action.payload.searchParams]: {
                        status: FETCHING,
                    },
                },
            };
        case FETCH_COMPLETE_WHEEL_OPTIONS_SUCCESS: {
            const { options } = action.payload.data;
            return {
                ...state,
                completeWheelOptionsResponseBySearchParams: {
                    ...state.completeWheelOptionsResponseBySearchParams,
                    [action.payload.searchParams]: {
                        status: SUCCESS,
                        data: options,
                    },
                },
            };
        }
        case FETCH_COMPLETE_WHEEL_OPTIONS_FAILURE:
            return {
                ...state,
                completeWheelOptionsResponseBySearchParams: {
                    ...state.completeWheelOptionsResponseBySearchParams,
                    [action.payload.searchParams]: {
                        status: FAILURE,
                        error: action.payload.error,
                    },
                },
            };
        case UPDATE_COMPLETE_WHEELS_FILTER_VALUES:
            const { selectedOptionsUpdate } = action.payload;
            return {
                ...state,
                selectedOptions: {
                    ...state.selectedOptions,
                    ...selectedOptionsUpdate,
                },
            };
        case FETCH_COMPLETE_WHEEL_REQUEST:
            return {
                ...state,
                completeWheelResponseById: {
                    ...state.completeWheelResponseById,
                    [action.payload.completeWheelId]: {
                        status: FETCHING,
                    },
                },
            };
        case FETCH_COMPLETE_WHEEL_SUCCESS: {
            return {
                ...state,
                completeWheelResponseById: {
                    ...state.completeWheelResponseById,
                    [action.payload.completeWheelId]: {
                        status: SUCCESS,
                        data: action.payload.completeWheel,
                    },
                },
            };
        }
        case FETCH_COMPLETE_WHEEL_FAILURE:
            return {
                ...state,
                completeWheelResponseById: {
                    ...state.completeWheelResponseById,
                    [action.payload.completeWheelId]: {
                        status: FAILURE,
                        error: action.payload.error,
                    },
                },
            };
        default:
            return state;
    }
}
