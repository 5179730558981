import React from 'react';
import { ExternalLink } from '../../../../form-elements/Link';
import { useSelector } from 'react-redux';
import { carDealerLinkSelector } from '../../../../../../modules/selectors/standort/theme';
import isAbsoluteUrl from 'is-absolute-url';

function EFALink({ to, ...props }) {
    const { to: baseURL } = useSelector(carDealerLinkSelector);

    return <ExternalLink {...props} to={isAbsoluteUrl(to) ? to : `${baseURL}${to}`} />;
}

export default EFALink;
