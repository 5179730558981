import {
    FETCH_SERVICE_ARBEITSWERTE_FAILURE,
    FETCH_SERVICE_ARBEITSWERTE_REQUEST,
    FETCH_SERVICE_ARBEITSWERTE_SUCCESS,
    SET_SERVICE_ARBEITSWERTE_FILTER_VALUES,
} from '../actionTypes';
import { FAILURE, FETCHING, pendingResponse, SUCCESS } from '../fetchStatus';

export default function serviceArbeitswerte(state = pendingResponse, action) {
    switch (action.type) {
        case FETCH_SERVICE_ARBEITSWERTE_REQUEST:
            return {
                status: FETCHING,
            };
        case FETCH_SERVICE_ARBEITSWERTE_SUCCESS: {
            const { foundArbeitswerteServices, fromSelectedStandort  } = action.payload;
            return {
                status: SUCCESS,
                data: { foundArbeitswerteServices, fromSelectedStandort },
            };
        }
        case FETCH_SERVICE_ARBEITSWERTE_FAILURE:
            return {
                status: FAILURE,
                error: action.payload.error,
            };
        case SET_SERVICE_ARBEITSWERTE_FILTER_VALUES: {
            const { filterValues } = action.payload;
            return {
                ...state,
                data: {
                    ...state.data,
                    filterValues,
                },
            };
        }
        default:
            return state;
    }
}
