import { Route, Switch } from 'react-router-dom';
import React from 'react';
import { useSelector } from 'react-redux';
import { markenStandortSelector } from '../../../modules/selectors/standort/markenStandort';
import _ from 'lodash';
import { useGetStandortPath } from '../VHost/StandortRoute';
import { currentHostIsShopSystemSelector } from '../../../modules/selectors/hosts';
import StandortRedirect from '../VHost/StandortRedirect';
import { useStandortHistory } from '../VHost/StandortHistory';
import { pageSelector } from '../../../modules/selectors/url';

function MarkenStandortRedirects({ children }) {
    const history = useStandortHistory();

    const markenStandort = useSelector(markenStandortSelector);
    const page = useSelector(pageSelector);
    const currentHostIsShopSystem = useSelector(currentHostIsShopSystemSelector);

    const getStandortPath = useGetStandortPath({ currentHostIsShopSystem });

    if (_.isEmpty(markenStandort)) return children;

    const { redirectUrl, sublocationUrl, redirectSublocations } = markenStandort;

    if (!redirectUrl) return children;

    return (
        <Switch>
            {/* home => area or sublocation */}
            <Route exact path={getStandortPath('/')}>
                <StandortRedirect newStandortUrl={redirectUrl} to={`/${sublocationUrl || ''}`} />
            </Route>
            {/* old sublocation => home */}
            {!_.isEmpty(redirectSublocations) &&
                redirectSublocations.map((redirectSublocation, i) => (
                    <Route key={i} exact path={getStandortPath(`/${redirectSublocation}`)}>
                        <StandortRedirect newStandortUrl={redirectUrl} to="/" />
                    </Route>
                ))}
            {/* (nested) category => same site at new area */}
            <Route>
                {() => {
                    // Can't use <Redirect to/> for user generated paths (parameter injection).
                    history.replace({
                        pathname: page,
                        newStandortUrl: redirectUrl,
                    });
                    return null;
                }}
            </Route>
        </Switch>
    );
}

export default MarkenStandortRedirects;
