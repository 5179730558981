// seoMetadata.redirectTo expects the ability to execute relative redirects.
// TODO: introduce seoMetadata.redirectToPage
// eslint-disable-next-line no-restricted-imports
import { matchPath, Redirect } from 'react-router-dom';
import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { seoMetaDataSelector } from '../../../modules/selectors/standort/seoMetaData';
import StandortRedirect from '../VHost/StandortRedirect';

function SeoRedirects({ children }) {
    const seoMetaData = useSelector(seoMetaDataSelector);

    if (_.isEmpty(seoMetaData)) return children;

    const { redirectTo } = seoMetaData;

    if (!redirectTo) return children;

    if (redirectTo.startsWith('/')) {
        const {
            params: { newStandortUrl, page = '' },
        } = matchPath(redirectTo, { path: '/:newStandortUrl?/:page*' });

        return <StandortRedirect newStandortUrl={newStandortUrl} to={`/${page}`} />;
    }
    return <Redirect to={redirectTo} />;
}

export default SeoRedirects;
