import { SELECT_SUBLOCATION } from '../../actionTypes';

const initialState = {};

export default function subLocation(state = initialState, action) {
    switch (action.type) {
        case SELECT_SUBLOCATION:
            return {
                ...state,
                selectedSublocationUrl: action.payload.selectedSublocationUrl,
                selectedCarBrand: action.payload.selectedCarBrand,
            };
        default:
            return state;
    }
}
