import { createSelector } from 'reselect';
import { standortStateSelector } from '../standort';
import { pendingResponse } from '../../fetchStatus';
import { pageSelector } from '../url';

export const seoMetaDataByPathSelector = createSelector([standortStateSelector], standortState => standortState.seoMetaData);

export const seoMetaDataResponseSelector = createSelector(
    [seoMetaDataByPathSelector, pageSelector],
    (seoMetaDataByPath, page) => seoMetaDataByPath?.[page] ?? pendingResponse
);

export const seoMetaDataSelector = createSelector([seoMetaDataResponseSelector], seoMetaDataResponse => seoMetaDataResponse?.data);
