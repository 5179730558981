import React from 'react';
import { NoSSR } from '../../../helpers/SSRHelper';

function Spinner() {
    return (
        <NoSSR>
            <div>
                <div className="overlay">
                    <div className="uk-flex uk-flex-column-reverse">
                        <div className="spin" />
                        <div className="spintext">Einen kurzen Augenblick bitte...</div>
                    </div>
                </div>
            </div>
        </NoSSR>
    );
}

export default Spinner;
