import React from 'react';
import { useSelector } from 'react-redux';
import { standortUrlSelector } from '../../../modules/selectors/url';
import PropTypes from 'prop-types';
import { ListenerLink } from '../Tracking/Matomo';
import { useStandortAwareHref } from './internal/vHostHelper';
import { validatePage } from './internal/customPropTypesValidators';
// StandortLink abstracts internal shop links.
// eslint-disable-next-line no-restricted-imports
import { Link } from 'react-router-dom';

function InnerStandortLink({ currentStandortUrl, to: page, newStandortUrl, children, forceServerSideRouting = false, ...otherProps }) {
    const { routeServerSide, to } = useStandortAwareHref({ page, newStandortUrl, forceServerSideRouting, currentStandortUrl });

    return routeServerSide ? (
        // StandortLink abstracts links to shops on different hosts.
        // eslint-disable-next-line react/forbid-elements
        <ListenerLink {...otherProps} to={to}>
            {children}
        </ListenerLink>
    ) : (
        <Link {...otherProps} to={to}>
            {children}
        </Link>
    );
}

InnerStandortLink.propTypes = {
    currentStandortUrl: PropTypes.string.isRequired,
    to: validatePage,
    newStandortUrl: PropTypes.string,
    forceServerSideRouting: PropTypes.bool,
    children: PropTypes.node.isRequired,
};

function StandortLink({ to, newStandortUrl, children, forceServerSideRouting = false, ...otherProps }) {
    const currentStandortUrl = useSelector(standortUrlSelector);

    return (
        <InnerStandortLink
            {...otherProps}
            to={to}
            newStandortUrl={newStandortUrl}
            currentStandortUrl={currentStandortUrl}
            forceServerSideRouting={forceServerSideRouting}
        >
            {children}
        </InnerStandortLink>
    );
}

StandortLink.propTypes = {
    to: PropTypes.string.isRequired,
    newStandortUrl: PropTypes.string,
    forceServerSideRouting: PropTypes.bool,
    children: PropTypes.node.isRequired,
};

export default StandortLink;
