import { createSelector } from 'reselect';
import { markenStandortSelector } from './markenStandort';
import * as Yup from 'yup';
import { IS_DEV } from '../../../config';

const linkSchema = Yup.object().shape({
    type: Yup.string().oneOf(['internalLink', 'internalEventLink', 'externalLink', 'externalEventLink']).required(),
    to: Yup.string().required(),
    label: Yup.string().required(),
    icon: Yup.string(),
    children: Yup.array().of(Yup.lazy(() => linkSchema.default(undefined))),
});

const apiEntrySchema = Yup.object().shape({
    id: Yup.string().required(),
    url: Yup.string().required(),
});
const themeSchema = Yup.object().shape({
    componentName: Yup.string().oneOf(['NOATheme', 'DefaultTheme', 'LegacyTheme', 'EFATheme', 'TobabenTheme', 'AndersTheme', 'LoehrBeckerTheme', 'MusterTheme', 'DelloTheme', 'GottfriedTheme', 'AutoschmittTheme']).required(),
    header: Yup.object()
        .shape({
            componentName: Yup.string().oneOf(['NOAHeader', 'DefaultHeader', 'LegacyHeader', 'EFAHeader', 'TobabenHeader' , 'AndersHeader', 'LoehrBeckerHeader', 'MusterHeader', 'DelloHeader', 'GottfriedHeader', 'AutoschmittHeader']).required(),
            hideHomeHeroBanner: Yup.boolean(),
            mobileVehicleSelection: Yup.boolean(),
            brandsFilter: Yup.boolean(),
            hideHeroBanner: Yup.boolean(),
            hideLogin: Yup.boolean(),
            pageContent: Yup.object().shape({}),
            heroContentByPage: Yup.array().of(
                Yup.object().shape({
                    imageLink: Yup.string().required(),
                    description: Yup.string().required(),
                    page: Yup.string().required(),
                    headline: Yup.string().required(),
                    to: Yup.string(),
                })
            ),
            height: Yup.object()
                .shape({
                    large: Yup.number().required(),
                    medium: Yup.number().required(),
                    small: Yup.number().required(),
                    fallback: Yup.number().required(),
                })
                .required(),
            links: Yup.array().of(linkSchema),
            carDealerLink: linkSchema.required(),
        })
        .required(),
    body: Yup.object()
        .shape({
            home: Yup.object()
                .shape({
                    componentName: Yup.string().oneOf(['DefaultHome', 'LegacyHome']).required(),
                })
                .required(),
            hideServiceForm: Yup.boolean(),
            stickyBoxLink: linkSchema,
            showCouponInput: Yup.boolean(),
            enablePromotionLandingpages: Yup.boolean(),
            enableCompleteWheels: Yup.boolean(),
        })
        .required(),
    footer: Yup.object()
        .shape({
            componentName: Yup.string().oneOf(['NOAFooter', 'DefaultFooter', 'LegacyFooter', 'EFAFooter', 'MusterFooter']).required(),
            links: Yup.object()
                .shape({
                    ueberUns: linkSchema.required(),
                    lieferungUndVersand: linkSchema.required(),
                    impressum: linkSchema.required(),
                    datenschutz: linkSchema.required(),
                    agb: linkSchema.required(),
                    widerrufsrecht: linkSchema.required(),
                    serviceAnfrage: linkSchema,
                })
                .required(),
        })
        .required(),
    context: Yup.object().shape({
        apis: Yup.array().of(apiEntrySchema),
    }),
});

function validateTheme(theme) {
    return themeSchema.validateSync(theme, { strict: true, stripUnknown: true });
}

const FALLBACK_THEME = {
    componentName: 'DefaultTheme',
    header: {
        componentName: 'DefaultHeader',
        height: {
            large: 60,
            medium: 60,
            small: 60,
            fallback: 60,
        },
        carDealerLink: { to: '/', label: 'Autohaus', type: 'externalLink' },
        pageContent: Yup.object().shape({}),
    },
    body: {
        home: { componentName: 'DefaultHome' },
        stickyBoxLink: { to: '/serviceAnfrage', label: 'SERVICE ANFRAGE STELLEN', type: 'internalLink' },
        enablePromotionLandingpages: true,
    },
    footer: {
        componentName: 'DefaultFooter',
        links: {
            agb: { label: 'AGB', type: 'internalLink', to: '/agb' },
            ueberUns: { label: 'Über uns', type: 'internalLink', to: '/ueberUns' },
            lieferungUndVersand: { label: 'Lieferung und Versand', type: 'internalLink', to: '/lieferUndVersand' },
            impressum: { label: 'Impressum', type: 'internalLink', to: '/impressum' },
            datenschutz: { label: 'Datenschutz', type: 'internalLink', to: '/datenschutz' },
            widerrufsrecht: { label: 'Widerrufsrecht', type: 'internalLink', to: '/widerrufsrecht' },
        },
    },
};

if (IS_DEV) {
    validateTheme(FALLBACK_THEME);
}

export const markenStandortThemeSelector = createSelector([markenStandortSelector], markenStandort => {
    const theme = markenStandort?.theme;
    try {
        const validatedTheme = validateTheme(theme);
        return validatedTheme ?? FALLBACK_THEME;
    } catch (err) {
        console.error('Theme ignored, invalid markenStandort.theme:', err);
        return FALLBACK_THEME;
    }
});
export const markenStandortThemeHeaderSelector = createSelector(
    [markenStandortThemeSelector],
    markenStandortTheme => markenStandortTheme.header
);
export const markenStandortThemeFooterSelector = createSelector(
    [markenStandortThemeSelector],
    markenStandortTheme => markenStandortTheme.footer
);
export const markenStandortThemeBodySelector = createSelector(
    [markenStandortThemeSelector],
    markenStandortTheme => markenStandortTheme.body
);

export const hideHomeHeroBannerSelector = createSelector(
    [markenStandortThemeHeaderSelector],
    markenStandortThemeHeader => !!markenStandortThemeHeader.hideHomeHeroBanner
);

export const mobileVehicleSelectionSelector = createSelector(
    [markenStandortThemeHeaderSelector],
    markenStandortThemeHeader => !!markenStandortThemeHeader.mobileVehicleSelection
);

export const brandsFilterSelector = createSelector(
    [markenStandortThemeHeaderSelector],
    markenStandortThemeHeader => !!markenStandortThemeHeader.brandsFilter
);
export const showCouponInputSelector = createSelector(
    [markenStandortThemeBodySelector],
    markenStandortThemeBody => !!markenStandortThemeBody.showCouponInput
);

export const hideServiceFormSelector = createSelector(
    [markenStandortThemeBodySelector],
    markenStandortThemeBody => !!markenStandortThemeBody.hideServiceForm
);

export const stickyBoxLinkSelector = createSelector(
    [markenStandortThemeBodySelector],
    markenStandortThemeBody => markenStandortThemeBody.stickyBoxLink
);
export const enablePromotionLandingpagesSelector = createSelector(
    [markenStandortThemeBodySelector],
    markenStandortThemeBody => !!markenStandortThemeBody.enablePromotionLandingpages
);
export const enableCompleteWheelsSelector = createSelector(
    [markenStandortThemeBodySelector],
    markenStandortThemeBody => !!markenStandortThemeBody.enableCompleteWheels
);
export const hideHeroBannerSelector = createSelector(
    [markenStandortThemeHeaderSelector],
    markenStandortThemeHeader => !!markenStandortThemeHeader.hideHeroBanner
);

export const carDealerLinkSelector = createSelector(
    [markenStandortThemeHeaderSelector],
    markenStandortThemeHeader => markenStandortThemeHeader.carDealerLink
);

export const heroContentByPageSelector = createSelector(
    [markenStandortThemeHeaderSelector],
    markenStandortThemeHeader => markenStandortThemeHeader.heroContentByPage || []
);

export const linksFooterSelector = createSelector(
    [markenStandortThemeFooterSelector],
    markenStandortThemeFooter => markenStandortThemeFooter.links
);
export const hideLoginSelector = createSelector(
    [markenStandortThemeHeaderSelector],
    markenStandortThemeHeader => !!markenStandortThemeHeader.hideLogin
);
const themeContextSelector = createSelector([markenStandortThemeSelector], markenStandortTheme => markenStandortTheme.context);
export const themeContextApisSelector = createSelector([themeContextSelector], themeContext => {
    /**
     * @type {{[string]: string}}
     */
    return Object.fromEntries(themeContext?.apis?.map(({ id, url }) => [id, url]) ?? []);
});
