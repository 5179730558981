import { createSelector } from 'reselect';
import { FAILURE, pendingResponse } from '../../fetchStatus';
import { standortStateSelector } from '../standort';
import { standortUrlSelector } from '../url';

export const markenStandortResponseSelector = createSelector(
    [standortStateSelector],
    standortState => standortState.markenStandort ?? pendingResponse
);

export const markenStandortSelector = createSelector(
    [markenStandortResponseSelector],
    markenStandortResponse => markenStandortResponse?.data
);

const markenStandortResponseStatusSelector = createSelector(
    [markenStandortResponseSelector],
    markenStandortResponse => markenStandortResponse.status
);
export const markenStandortFailureSelector = createSelector(
    [markenStandortResponseStatusSelector],
    markenStandortResponseStatus => markenStandortResponseStatus === FAILURE
);

// todo: VHost aware
export const markenStandortUrlWithFallbackSelector = createSelector(
    [standortUrlSelector, markenStandortFailureSelector],
    (standortUrl, markenStandortFailure) => (markenStandortFailure ? 'de' : standortUrl)
);

export const categoryBlacklistSelector = createSelector(
    [markenStandortSelector],
    markenStandort => markenStandort?.categoryBlacklist ?? []
);

export const matomoTagManagerContainerSelector = createSelector(
    [markenStandortSelector],
    markenStandort => markenStandort?.matomoTagManagerContainer
);

export const categoryBlacklistUrlsSelector = createSelector([categoryBlacklistSelector], categoryBlacklist =>
    categoryBlacklist.map(category => category.url)
);

export const areaShopSubLocationsSelector = createSelector(
    [markenStandortSelector],
    markenStandort => markenStandort?.areaShopSubLocations ?? []
);

export const markenStandortHasDatSelector = createSelector([markenStandortSelector], markenStandort => markenStandort?.hasDat);

export const markenstandortHasFahrzeugSpezifischeSucheSelector = createSelector([markenStandortSelector], markenStandort => markenStandort?.stammblatt?.fahrzeugSpezifischeSuche);
