import _ from 'lodash';
import { useCallback } from 'react';

export function useGetStandortPath({ currentHostIsShopSystem }) {
    if (!_.isBoolean(currentHostIsShopSystem))
        throw new Error(`Expected currentHostIsShopSystem to be a bool, instead got: ${currentHostIsShopSystem}`);

    return useCallback(
        page => {
            let path;
            if (currentHostIsShopSystem) {
                if (_.isArray(page)) {
                    path = page.map(realPathWithoutStandortUrl => `/:__standortUrl__${realPathWithoutStandortUrl}`);
                } else {
                    path = `/:__standortUrl__${page}`;
                }
            } else {
                path = page;
            }
            return path;
        },
        [currentHostIsShopSystem]
    );
}
