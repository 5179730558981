import { combineReducers } from 'redux';
import serviceAngebote from './service/serviceAngebote';
import dienstleistungByID from './service/dienstleistungByID';
import serviceLandingpage from './service/serviceLandingpage';

const initialState = {};

const serviceReducers = combineReducers({
    serviceAngebote,
    dienstleistungByID,
    serviceLandingpage
});

export default function service(state = initialState, action) {
    const serviceUrl = action?.payload?.serviceUrl;
    if (serviceUrl) {
        return {
            ...state,
            [serviceUrl]: serviceReducers(state[serviceUrl], action),
        };
    } else {
        return state;
    }
}
