import React from 'react';
import { useSelector } from 'react-redux';
import loadable from '@loadable/component';
import { showTecarTileSelector } from '../../../modules/selectors/floatingSidebar';
import ServiceInquiryTile from './Tiles/ServiceInquiryTile';
import { stickyBoxLinkSelector } from '../../../modules/selectors/standort/theme';

const TecarTile = loadable(() => import('./Tiles/TecarTile'));

function FloatingSidebar() {
    const showTecarTile = useSelector(showTecarTileSelector);
    const stickyBoxLink = useSelector(stickyBoxLinkSelector);

    return (
        <div
            className="uk-position-center-left uk-position-center-right uk-position-fixed uk-visible@xl"
            style={{ height: 0, zIndex: 990 }}
        >
            <div className="uk-container uk-padding-remove uk-background-primary" style={{ height: 0 }}>
                <div className="uk-inline-block uk-width-1-1">
                    <div className="uk-position-large uk-position-center-right-out">
                        <div className="uk-width-small">
                            <div className="uk-grid uk-grid-small uk-child-width-1-1" data-uk-grid="">
                                {stickyBoxLink && (
                                    <div>
                                        <ServiceInquiryTile stickyBoxLink={stickyBoxLink} />
                                    </div>
                                )}
                                {showTecarTile && (
                                    <div>
                                        <TecarTile />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FloatingSidebar;
