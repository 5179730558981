export function getBaseCrumbs({ categories, categoryUrl }) {
    const url = '';
    const crumbs = [{ label: 'Home', url: '/' }];
    if( categories ) {
        const matchingCategories = categories
            .flatMap(category => {
                if (category.url === categoryUrl) return [category];
                const matchingSubCategory = category?.subCategories.find(subCategory => subCategory.url === categoryUrl);
                if (matchingSubCategory) return [category, matchingSubCategory];
                return [];
            })
            .map(category => ({ label: category?.title ?? '---', url: `${url}/${category.url}` }));
            return [...crumbs, ...matchingCategories];
    }
    return crumbs;

}
