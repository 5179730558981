export const PENDING = 'PENDING';
export const FETCHING = 'FETCHING';
export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';

export function shouldFetch(status) {
    switch (status) {
        case PENDING:
            return true;
        case FETCHING:
            return false;
        case FAILURE:
            // TODO: improve error retry
            //  References:
            //  https://github.com/zeit/swr#error-retries
            //  https://redux-toolkit.js.org/api/createAsyncThunk
            return false;
        case SUCCESS:
            return false;
        default:
            throw new Error(`Unexpected fetchStatus: ${status}`);
    }
}

export const pendingResponse = {
    status: PENDING,
};
