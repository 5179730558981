import { SCROLL_TO_ELEMENT, CLEAR_SCROLL_TO } from '../actions/scrollToViewport';

const initialState = {
    scrollTo: null,
};

const scrollToViewport = (state = initialState, action) => {
    switch (action.type) {
        case SCROLL_TO_ELEMENT:
            return {
                ...state,
                scrollTo: action.payload,
            };
        case CLEAR_SCROLL_TO:
            return {
                ...state,
                scrollTo: null,
            };
        default:
            return state;
    }
};

export default scrollToViewport;