import {
    DELETE_USER_SUCCESS,
    SET_AUTH_TOKEN,
    SIGNIN_USER_SUCCESS,
    SIGNOUT_USER_SUCCESS,
    SIGNUP_USER_SUCCESS,
    UPDATE_USER_PASSWORD_SUCCESS,
} from '../../actionTypes';

const initialState = null;

export default function token(state = initialState, action) {
    switch (action.type) {
        case SET_AUTH_TOKEN:
        case SIGNIN_USER_SUCCESS:
        case SIGNUP_USER_SUCCESS:
            return action.payload.token;
        case SIGNOUT_USER_SUCCESS:
        case UPDATE_USER_PASSWORD_SUCCESS:
        case DELETE_USER_SUCCESS:
            return initialState;
        default:
            return state;
    }
}
