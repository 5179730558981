import React from 'react';
import { ExternalLink } from '../../../form-elements/Link';

export default function LoginForm({ formClassName }) {
    // TODO: fix form submit
    // POST https://services.carmato-labs.de/JWTservice/login
    // body: {email: "asdf", pwd: "asdf", aud: "fahrzeuge.emilfrey.de"}
    return (
        <form noValidate="" className={formClassName}>
            <h6>Einloggen</h6>
            <div className="padding-24">
                <p className="input-label">E-Mail Adresse</p>
                <span className="input-wrap">
                    <input className="input-text" autoComplete="off" placeholder="E-Mail Adresse" autoFocus="" />
                </span>
            </div>
            <div>
                <p className="input-label">Passwort</p>
                <span className="input-wrap">
                    <input type="password" className="input-text" placeholder="Passwort" autoComplete="off" />
                </span>
            </div>
            <div className="padding-24">
                <button className="btn-secondary btn-max" disabled="">
                    Einloggen
                </button>
            </div>
            <div className="bottom-links-wrap">
                <ExternalLink
                    className="forgot-pwd"
                    to="https://stage-b2c-de.emilfreydigital.hr/auth/realms/DE_B2C/login-actions/reset-credentials"
                >
                    Passwort vergessen?
                </ExternalLink>
                <ExternalLink
                    className="register"
                    to="https://stage-b2c-de.emilfreydigital.hr/auth/realms/DE_B2C/protocol/openid-connect/registrations?client_id=carmato&amp;response_type=code&amp;scope=openid+email&amp;redirect_uri=https://emilfrey-dev.carmato.io/keycloak_stage/"
                >
                    Registrieren
                </ExternalLink>
            </div>
        </form>
    );
}
