import { apiClient } from '../../../helpers/apiHelperV2';
import { FETCH_MARKENSTANDORT_FAILURE, FETCH_MARKENSTANDORT_REQUEST, FETCH_MARKENSTANDORT_SUCCESS } from '../../actionTypes';
import { markenStandortResponseSelector } from '../../selectors/standort/markenStandort';
import { shouldFetch } from '../../fetchStatus';
import { standortUrlSelector } from '../../selectors/url';

export const fetchMarkenStandortIfNeeded = () => {
    return async function (dispatch, getState) {
        const state = getState();

        const standortUrl = standortUrlSelector(state);
        const markenStandortResponse = markenStandortResponseSelector(state);

        if (shouldFetch(markenStandortResponse.status)) {
            dispatch({
                type: FETCH_MARKENSTANDORT_REQUEST,
                payload: { standortUrl },
            });

            try {
                const { data: markenStandort } = await apiClient.get(`/api/v1/standorte/${standortUrl}`);
                dispatch({
                    type: FETCH_MARKENSTANDORT_SUCCESS,
                    payload: { standortUrl, markenStandort },
                });
            } catch (error) {
                dispatch({
                    type: FETCH_MARKENSTANDORT_FAILURE,
                    payload: { standortUrl, error },
                });
            }
        }
    };
};
