import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import loadable from '@loadable/component';
import { markenStandortSelector } from '../../../modules/selectors/standort/markenStandort';
import { markenStandortThemeSelector } from '../../../modules/selectors/standort/theme';

const DefaultTheme = loadable(() => import('./default/DefaultTheme'));
const LegacyTheme = loadable(() => import('./legacy/LegacyTheme'));
const NOATheme = loadable(() => import('./noa/NOATheme'));
const EFATheme = loadable(() => import('./efa/EFATheme'));
const TobabenTheme = loadable(() => import('./tobaben/tobabenTheme'));
const AndersTheme = loadable(() => import('./anders/AndersTheme'));
const LoehrBeckerTheme = loadable(() => import('./loehrBecker/loehrBeckerTheme'));
const MusterTheme = loadable(() => import('./muster/musterTheme'));
const DelloTheme = loadable(() => import('./dello/delloTheme'));
const GottfriedTheme = loadable(()=> import('./gottfried/GottfriedTheme'))
const AutoschmittTheme = loadable(() => import('./autoschmitt/AutoschmittTheme'));

function ThemeSelector() {
    const { componentName } = useSelector(markenStandortThemeSelector);
    if (componentName === 'NOATheme') {
        return <NOATheme />;
    } else if (componentName === 'EFATheme') {
        return <EFATheme />;
    } else if (componentName === 'DefaultTheme') {
        return <DefaultTheme />;
    } else if (componentName === 'LegacyTheme') {
        return <LegacyTheme />;
    } else if (componentName === 'TobabenTheme') {
        return <TobabenTheme />;
    } else if (componentName === 'AndersTheme') {
        return <AndersTheme />;
    } else if (componentName === 'LoehrBeckerTheme') {
        return <LoehrBeckerTheme />;
    } else if (componentName === 'MusterTheme') {
        return <MusterTheme />;
    } else if (componentName === 'DelloTheme') {
        return <DelloTheme />;
    }  else if (componentName === 'GottfriedTheme') {
        return <GottfriedTheme />;
    } else if (componentName === 'AutoschmittTheme') {
        return <AutoschmittTheme />;
    } 
    else {
        throw new Error(`Unexpected componentName: ${componentName}`);
    }
}

function ColorSelector() {
    const markenStandort = useSelector(markenStandortSelector);

    const colors = markenStandort?.colors;

    if (_.isEmpty(colors)) return null;

    return (
        <style>
            {`:root {${Object.entries(colors)
                .map(([cssCustomPropertyName, value]) => `--${cssCustomPropertyName}: ${value};`)
                .join('\n')}}`}
        </style>
    );
}

function Theme() {
    return (
        <>
            <ThemeSelector />
            <ColorSelector />
        </>
    );
}

export default Theme;
