import { FETCH_CAR_BRANDS_FAILURE, FETCH_CAR_BRANDS_REQUEST, FETCH_CAR_BRANDS_SUCCESS } from '../actionTypes';
import { FAILURE, FETCHING, SUCCESS } from '../fetchStatus';

const initialState = null;

export default function carBrands(state = initialState, action) {
    switch (action.type) {
        case FETCH_CAR_BRANDS_REQUEST:
            return {
                status: FETCHING,
            };
        case FETCH_CAR_BRANDS_SUCCESS:
            return {
                status: SUCCESS,
                data: action.payload.carBrands,
            };
        case FETCH_CAR_BRANDS_FAILURE:
            return {
                status: FAILURE,
                error: action.payload.error,
            };
        default:
            return state;
    }
}
