import { apiClient } from '../../helpers/apiHelperV2';
import { FETCH_HOSTS_FAILURE, FETCH_HOSTS_REQUEST, FETCH_HOSTS_SUCCESS } from '../actionTypes';
import { shouldFetch } from '../fetchStatus';
import { hostsResponseSelector } from '../selectors/hosts';

export const fetchHostsIfNeeded = () => {
    return async function (dispatch, getState) {
        const state = getState();

        const hostsResponse = hostsResponseSelector(state);

        if (shouldFetch(hostsResponse.status)) {
            dispatch({
                type: FETCH_HOSTS_REQUEST,
                payload: {},
            });
            try {
                let {
                    data: { hosts },
                } = await apiClient.get(`/api/v1/hosts`);
                dispatch({
                    type: FETCH_HOSTS_SUCCESS,
                    payload: { hosts },
                });
            } catch (error) {
                dispatch({
                    type: FETCH_HOSTS_FAILURE,
                    payload: { error },
                });
            }
        }
    };
};
