import {
    FETCH_CATEGORY_LANDING_PAGE_CONTENT_FAILURE,
    FETCH_CATEGORY_LANDING_PAGE_CONTENT_REQUEST,
    FETCH_CATEGORY_LANDING_PAGE_CONTENT_SUCCESS,
} from '../../../actionTypes';
import { FAILURE, FETCHING, SUCCESS } from '../../../fetchStatus';

const initialState = null;

export default function categoryLandingPageContent(state = initialState, action) {
    switch (action.type) {
        case FETCH_CATEGORY_LANDING_PAGE_CONTENT_REQUEST:
            return {
                status: FETCHING,
            };
        case FETCH_CATEGORY_LANDING_PAGE_CONTENT_SUCCESS:
            return {
                status: SUCCESS,
                data: action.payload.categoryLandingPageContent,
            };
        case FETCH_CATEGORY_LANDING_PAGE_CONTENT_FAILURE:
            return {
                status: FAILURE,
                error: action.payload.error,
            };
        default:
            return state;
    }
}
