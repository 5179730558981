import { useSelector } from 'react-redux';
import { currentHostIsShopSystemSelector, newHostHostnameSelector, newHostIsShopSystemSelector } from '../../../../modules/selectors/hosts';
import { hostnameSelector, portSelector, protocolSelector } from '../../../../modules/selectors/vHostRequestLocation';
import { useDebugValue } from 'react';
import { DEPLOYMENT_ENV, IS_DEV } from '../../../../config';
import { validateGetStandortAwareHrefParams } from './vHostHelperValidators';

function getShopSystemUri({ protocol, hostname, port, standortUrl, page }) {
    return `${getOrigin({ protocol, hostname, port })}${getShopSystemPath({ standortUrl, page })}`;
}

function getShopSystemPath({ standortUrl, page }) {
    return `/${standortUrl}${page === '/' ? '' : page}`;
}

function getSingleHostUri({ protocol, hostname, port, page }) {
    return `${getOrigin({ protocol, hostname, port })}${getSingleHostPath({ page })}`;
}

function getSingleHostPath({ page }) {
    return page;
}

export function getOrigin({ protocol, hostname, port }) {
    return `${protocol}://${hostname}${DEPLOYMENT_ENV === 'local' ? '.localhost' : ''}${port ? `:${port}` : ''}`;
}

export function getStandortAwareHref({
    page,
    currentHostname,
    currentStandortUrl,
    currentHostIsShopSystem,
    newStandortUrl,
    newHostname,
    newHostIsShopSystem,
    protocol,
    port,
    forceServerSideRouting,
}) {
    if (IS_DEV) {
        const isValid = validateGetStandortAwareHrefParams({
            page,
            currentHostname,
            currentStandortUrl,
            currentHostIsShopSystem,
            newStandortUrl,
            newHostname,
            newHostIsShopSystem,
            protocol,
            port,
            forceServerSideRouting,
        });
        if (!isValid) return { routeServerSide: undefined, to: '' };
    }

    let routeServerSide = forceServerSideRouting;
    let to;
    if (
        newStandortUrl &&
        !(newStandortUrl === currentStandortUrl && newHostname === currentHostname && newHostIsShopSystem === currentHostIsShopSystem)
    ) {
        // The target standortUrl is different from the current one.
        // Depending on the host configuration, this requires special handling.

        if (currentHostIsShopSystem) {
            if (newHostIsShopSystem) {
                if (currentHostname === newHostname) {
                    // Route inside the same shop system.
                    //  APS/de => APS/foo-gs
                    to = getShopSystemPath({ page, standortUrl: newStandortUrl });
                } else {
                    // Route between two different shop systems.
                    //  APS => Emil Frey APS 2.0
                    routeServerSide = true;
                    to = getShopSystemUri({ protocol, hostname: newHostname, port, standortUrl: newStandortUrl, page });
                }
            } else {
                // Route from the current shop system to a new single shop host.
                routeServerSide = true;
                to = getSingleHostUri({ protocol, hostname: newHostname, port, page });
            }
        } else {
            if (newHostIsShopSystem) {
                // Route from the current single shop host to a new shop system.
                routeServerSide = true;
                to = getShopSystemUri({ protocol, hostname: newHostname, port, standortUrl: newStandortUrl, page });
            } else {
                // Route from the current single shop host to a new single shop host.
                routeServerSide = true;
                to = getSingleHostUri({ protocol, hostname: newHostname, port, page });
            }
        }
    } else {
        if (currentHostIsShopSystem) {
            to = getShopSystemPath({ page, standortUrl: currentStandortUrl });
        } else {
            to = getSingleHostPath({ page });
        }
    }
    return { routeServerSide, to };
}

export function useStandortAwareHrefParams({ newStandortUrl }) {
    const currentHostname = useSelector(hostnameSelector);
    const currentHostIsShopSystem = useSelector(currentHostIsShopSystemSelector);
    const newHostname = useSelector(state => newHostHostnameSelector(state, { newStandortUrl }));
    const newHostIsShopSystem = useSelector(state => newHostIsShopSystemSelector(state, { newStandortUrl }));
    const protocol = useSelector(protocolSelector);
    const port = useSelector(portSelector);

    return {
        currentHostname,
        currentHostIsShopSystem,
        newHostname,
        newHostIsShopSystem,
        protocol,
        port,
    };
}

export function useStandortAwareHref({ page, newStandortUrl, forceServerSideRouting, currentStandortUrl }) {
    const { currentHostname, currentHostIsShopSystem, newHostname, newHostIsShopSystem, protocol, port } = useStandortAwareHrefParams({
        newStandortUrl,
    });

    useDebugValue({
        page,
        currentHostname,
        currentStandortUrl,
        currentHostIsShopSystem,
        newStandortUrl,
        newHostname,
        newHostIsShopSystem,
        protocol,
        port,
        forceServerSideRouting,
    });

    const { routeServerSide, to } = getStandortAwareHref({
        page,
        currentHostname,
        currentStandortUrl,
        currentHostIsShopSystem,
        newStandortUrl,
        newHostname,
        newHostIsShopSystem,
        protocol,
        port,
        forceServerSideRouting,
    });

    useDebugValue({ routeServerSide, to });

    return { routeServerSide, to };
}
