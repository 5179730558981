import { FETCH_CART_STATS_FAILURE, FETCH_CART_STATS_REQUEST, FETCH_CART_STATS_SUCCESS } from '../../../actionTypes';
import { FAILURE, FETCHING, SUCCESS } from '../../../fetchStatus';

const initialState = null;

export default function cartStats(state = initialState, action) {
    switch (action.type) {
        case FETCH_CART_STATS_REQUEST:
            return {
                status: FETCHING,
            };
        case FETCH_CART_STATS_SUCCESS:
            return {
                status: SUCCESS,
                data: action.payload.cartStats,
            };
        case FETCH_CART_STATS_FAILURE:
            return {
                status: FAILURE,
                error: action.payload.error,
            };
        default:
            return state;
    }
}
