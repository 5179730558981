import { isClient } from '../helpers/SSRHelper';
import { toast } from '../helpers/toastHelper';

const errorNotificationMiddleware = _store => next => action => {
    const payload = action?.payload;
    if (payload?.error) {
        console.error(action);
        if (isClient && !payload?.hideNotification) {
            toast({ err: payload.error, message: payload.message });
        }
    }
    return next(action);
};

export default errorNotificationMiddleware;
