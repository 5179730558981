/* eslint-disable react/forbid-elements */

import React, { useCallback, useEffect, useState } from 'react';
import { isClient } from '../../../helpers/SSRHelper';
import { serializeSearchParams } from '../../../helpers/UrlHelper';
import { IMAGE_API_URL } from '../../../config';
import PropTypes from 'prop-types';

// Constants
const resizeApiUrl = '/api/v1/resizeImages';
const webPMimeType = 'image/webp';
const defaultFallbackMimeType = 'image/png';

const hasWebPSupport = (() => {
    if (isClient) {
        return testWebPSupport();
    } else {
        // SSR: we optimistically render images with WebP.
        // Should the client not support it,
        // the images get re-rendered on hydration using a fallback format.
        return true;
    }
})();

function testWebPSupport() {
    if (isClient) {
        let elem = document.createElement('canvas');
        if (!!(elem.getContext && elem.getContext('2d'))) {
            // was able or not to get WebP representation
            return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
        }
    } else {
        throw new Error("Server can't test for WebP image compatibility");
    }
}

/**
 * Client with WebP support:
 *  Server: image/webp
 *  Client: image/webp
 * Client without WebP support:
 *  Server: image/webp (optimistic SSR)
 *  Client: image/webp (hydration MUST match SSR, or else: https://github.com/facebook/react/issues/10591)
 *   after hydration/first render: image/png (or other fallback)
 */
function useMimeType(fallbackMimeType) {
    const [mimeType, setMimeType] = useState(webPMimeType);

    useEffect(() => {
        if (!hasWebPSupport) {
            setMimeType(fallbackMimeType);
        }
    }, [fallbackMimeType]);

    return mimeType;
}

const getResizedUrl = ({ src, resizeWidth, resizeHeight, mimeType, enlarge, fit, background }) => {
    const resizeParams = serializeSearchParams({
        src,
        width: resizeWidth,
        height: resizeHeight,
        type: mimeType,
        enlarge,
        fit,
        background,
    });

    return `${IMAGE_API_URL}${resizeApiUrl}?${resizeParams}`;
};

const preloadImage = (src) => {
    const link = document.createElement('link');
    link.rel = 'preload';
    link.as = 'image';
    link.href = src;
    document.head.appendChild(link);
};

/**
 * This component ensures correct integration of sharp and lazy loading.
 *
 * Properties not listed in PropTypes will be passed directly to the img element.
 */
const Image = props => {
    const {
        src,
        alt,
        className,
        pictureClassName,
        resizeWidth,
        resizeHeight,
        fallbackMimeType = defaultFallbackMimeType,
        skipResize = false,
        preload = false,
        lazyloading = false,
        enlarge,
        fit,
        background,
        children,
        'data-src': dataSrc,
        ...otherProps
    } = props;

    const resizedSrc = skipResize ? src : getResizedUrl({ mimeType: fallbackMimeType, src, resizeWidth, resizeHeight, enlarge, fit, background });

    useEffect(() => {
        if(preload) {
            preloadImage(resizedSrc);
        }
    }, [preload, resizedSrc]);

    if (skipResize) {
        return <img src={src} alt={alt} className={className} loading={lazyloading ? '' : "lazy"} {...otherProps} />;
    } else {
        return (
            <picture className={pictureClassName}>
                <source
                    type={webPMimeType}
                    srcSet={getResizedUrl({ mimeType: webPMimeType, src, resizeWidth, resizeHeight, enlarge, fit, background })}
                />
                <img
                    src={getResizedUrl({ mimeType: fallbackMimeType, src, resizeWidth, resizeHeight, enlarge, fit, background })}
                    alt={alt}
                    className={className}
                    loading={lazyloading ? '' : "lazy"}
                    {...otherProps}
                />
            </picture>
        );
    }
};

Image.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    /**
     * Additional classNames for the <img /> element. No need to include "lazyload" or similar.
     */
    className: PropTypes.string,
    /**
     * ClassName for the <picture /> element which contains the <img /> element.
     */
    pictureClassName: PropTypes.string,
    resizeWidth: PropTypes.number,
    resizeHeight: PropTypes.number,
    /**
     * The MIME type to use if the browser does not support WebP. Defaults to "image/png".
     */
    fallbackMimeType: PropTypes.string,
    /**
     * Don't use sharp to resize the image. Use case: source image is a SVG.
     */
    skipResize: PropTypes.bool,
    /**
     * Allow enlarging if the source image width or height are less than the specified dimensions.
     **/
    enlarge: PropTypes.bool,
    /**
     * How the image should fit into the new dimensions.
     * See [Sharp Documentation](https://sharp.pixelplumbing.com/api-resize#resize)
     * @default "cover"
     **/
    fit: PropTypes.oneOf(['cover', 'contain', 'fill', 'inside', 'outside']),
    /**
     * Only used if fit is "contain".
     * See [Sharp Documentation](https://sharp.pixelplumbing.com/api-resize#parameters)
     * @default "black"
     */
    background: PropTypes.string,

    'data-src': PropTypes.oneOf([]),
    children: PropTypes.oneOf([]),
};

export default Image;

/**
 * Render a div with a backgroundImage inline style.
 *
 * This component ensures correct integration of sharp and lazyload.
 *
 * Properties not listed in PropTypes will be passed directly to the div element.
 */
export const BackgroundImage = props => {
    const {
        src,
        className,
        resizeWidth,
        resizeHeight,
        fallbackMimeType = defaultFallbackMimeType,
        skipResize = false,
        enlarge,
        fit,
        background,
        children,
        'data-src': dataSrc,
        ...otherProps
    } = props;

    const mimeType = useMimeType(fallbackMimeType);

    if (src.includes('url(') || src.includes(')')) {
        console.error('Unexpected BackgroundImage src containing CSS url function:', src);
        return null;
    }

    return (
        <div
            style={{
                backgroundImage: `url(${skipResize ? src : getResizedUrl({ src, resizeWidth, resizeHeight, mimeType, enlarge, fit, background })
                    })`,
            }}
            className={className}
            {...otherProps}
        >
            {children}
        </div>
    );
};

BackgroundImage.propTypes = {
    src: PropTypes.string.isRequired,
    /**
     * Additional classNames for the <div /> element. No need to include "lazyload" or similar.
     */
    className: PropTypes.string,
    resizeWidth: PropTypes.number,
    resizeHeight: PropTypes.number,
    /**
     * The MIME type to use if the browser does not support WebP. Defaults to "image/png".
     */
    fallbackMimeType: PropTypes.string,
    /**
     * Don't use sharp to resize the image. Use case: source image is a SVG.
     */
    skipResize: PropTypes.bool,
    /**
     * Allow enlarging if the source image width or height are less than the specified dimensions.
     **/
    enlarge: PropTypes.bool,
    /**
     * How the image should fit into the new dimensions.
     * See [Sharp Documentation](https://sharp.pixelplumbing.com/api-resize#resize)
     * @default "cover"
     **/
    fit: PropTypes.oneOf(['cover', 'contain', 'fill', 'inside', 'outside']),
    /**
     * Only used if fit is "contain".
     * See [Sharp Documentation](https://sharp.pixelplumbing.com/api-resize#parameters)
     * @default "black"
     */
    background: PropTypes.string,

    'data-src': PropTypes.oneOf([]),
    children: PropTypes.node,
};

/**
 * This component ensures correct integration of sharp and lazyload for images in a svg context.
 *
 * Properties not listed in PropTypes will be passed directly to the image SVG element.
 */
export const InlineSvgImage = props => {
    const {
        src,
        className,
        resizeWidth,
        resizeHeight,
        fallbackMimeType = defaultFallbackMimeType,
        skipResize = false,
        enlarge,
        fit,
        background,
        'data-src': dataSrc,
        ...otherProps
    } = props;

    const mimeType = useMimeType(fallbackMimeType);

    // noinspection HtmlDeprecatedTag
    return (
        <image
            href={skipResize ? src : getResizedUrl({ src, resizeWidth, resizeHeight, mimeType, enlarge, fit, background })}
            className={className}
            {...otherProps}
        />
    );
};

InlineSvgImage.propTypes = {
    src: PropTypes.string.isRequired,
    /**
     * Additional classNames for the <image /> SVG element. No need to include "lazyload" or similar.
     */
    className: PropTypes.string,
    resizeWidth: PropTypes.number,
    resizeHeight: PropTypes.number,
    /**
     * The MIME type to use if the browser does not support WebP. Defaults to "image/png".
     */
    fallbackMimeType: PropTypes.string,
    /**
     * Don't use sharp to resize the image. Use case: source image is a SVG.
     */
    skipResize: PropTypes.bool,
    /**
     * Allow enlarging if the source image width or height are less than the specified dimensions.
     **/
    enlarge: PropTypes.bool,
    /**
     * How the image should fit into the new dimensions.
     * See [Sharp Documentation](https://sharp.pixelplumbing.com/api-resize#resize)
     * @default "cover"
     **/
    fit: PropTypes.oneOf(['cover', 'contain', 'fill', 'inside', 'outside']),
    /**
     * Only used if fit is "contain".
     * See [Sharp Documentation](https://sharp.pixelplumbing.com/api-resize#parameters)
     * @default "black"
     */
    background: PropTypes.string,

    'data-src': PropTypes.oneOf([]),
    children: PropTypes.oneOf([]),
};

/**
 * Custom react hook providing fallback image src and onError callback.
 *
 * Direct modifications of `ev.target.src` do not work with resizing and lazyloading.
 * Additionally, react prop updates do not get reflected in the fallbackSrc.
 *
 * @example
 * const [src, onError] = useFallbackImageSrc({
 *       src: `/main.png`,
 *       fallbackSrc: `/fallback.png`,
 * });
 *
 * return <Image src={src} alt="Image" onError={onError} />
 *
 * @param {string} src
 * @param {string} fallbackSrc
 * @return {[string, function]} First element is the current image src, second element is the onError callback for the Image element
 */
export const useFallbackImageSrc = ({ src, fallbackSrc }) => {
    const [isFallback, setIsFallback] = useState(false);

    const onError = useCallback(() => {
        setIsFallback(true);
    }, []);

    return [isFallback ? fallbackSrc : src, onError];
};
