import { Route, Switch } from 'react-router-dom';
import React from 'react';
import {
    currentHostIndexStandortUrlSelector,
    currentHostIsShopSystemSelector,
    currentHostIsUniqSelector,
    currentHostIsUnknownSelector,
    currentHostStandortUrlSelector,
    fallbackHostIndexStandortUrlSelector,
    fallbackHostIsUniqSelector,
    fallbackHostIsUnknownSelector,
} from '../../../modules/selectors/hosts';
import { useSelector } from 'react-redux';
import StandortRedirect from '../VHost/StandortRedirect';
import Status from '../Status/Status';
import { currentHostDoesNotHostCurrentStandortUrlSelector, pageSelector, standortUrlSelector } from '../../../modules/selectors/url';

function InvalidHostConfiguration({ message }) {
    return (
        <Status code={500}>
            <h1>Invalid host configuration</h1>
            <p>{message}</p>
        </Status>
    );
}

/**
 * All redirects which depend only on hosts and domain.
 */
const HostRedirects = ({ children }) => {
    const standortUrl = useSelector(standortUrlSelector);
    const currentHostDoesNotHostCurrentStandortUrl = useSelector(currentHostDoesNotHostCurrentStandortUrlSelector);

    // Host selectors
    const currentHostStandortUrl = useSelector(currentHostStandortUrlSelector);
    const currentHostIsShopSystem = useSelector(currentHostIsShopSystemSelector);
    const currentHostIsUnknown = useSelector(currentHostIsUnknownSelector);
    const currentHostIsUniq = useSelector(currentHostIsUniqSelector);
    const currentHostIndexStandortUrl = useSelector(currentHostIndexStandortUrlSelector);
    const fallbackHostIsUnknown = useSelector(fallbackHostIsUnknownSelector);
    const fallbackHostIsUniq = useSelector(fallbackHostIsUniqSelector);
    const fallbackHostIndexStandortUrl = useSelector(fallbackHostIndexStandortUrlSelector);

    const page = useSelector(pageSelector);

    // Sanity checks
    //  Fallback host
    if (fallbackHostIsUnknown) return <InvalidHostConfiguration message="Missing fallbackHost." />;
    if (!fallbackHostIsUniq) return <InvalidHostConfiguration message="Ambiguous fallbackHost." />;
    if (currentHostIsUnknown && !fallbackHostIndexStandortUrl) {
        return <InvalidHostConfiguration message="The fallbackhost must specify a index standortUrl." />;
    }
    //  Current host
    if (!currentHostIsUnknown && !currentHostIsUniq) return <InvalidHostConfiguration message="Ambiguous currentHost." />;
    if (!currentHostIsUnknown && !currentHostIsShopSystem && !currentHostStandortUrl)
        return <InvalidHostConfiguration message="All non-shop-system hosts must be assigned a single shop." />;
    if (!currentHostIsUnknown && currentHostIsShopSystem && !currentHostIndexStandortUrl)
        return <InvalidHostConfiguration message="All shop-system hosts must be assigned a index standortUrl." />;

    return (
        <Switch>
            {/* If the current host is unknown, redirect to the indexStandortUrl of the fallback host */}
            {currentHostIsUnknown && <StandortRedirect to="/" newStandortUrl={fallbackHostIndexStandortUrl} />}
            {/* Redirect index on shop systems */}
            {currentHostIsShopSystem && (
                <Route exact path="/">
                    <StandortRedirect to="/" newStandortUrl={currentHostIndexStandortUrl} />
                </Route>
            )}
            {/* If the current host is a shop system, but does not host the currentStandortUrl, redirect to the correct host */}
            {currentHostDoesNotHostCurrentStandortUrl && <StandortRedirect to={page} newStandortUrl={standortUrl} />}
            {/* Default */}
            <Route>{children}</Route>
        </Switch>
    );
};

export default HostRedirects;
