import _ from 'lodash';
import { combineReducers } from 'redux';

import products from './vehicleFilter/products';
import category from './vehicleFilter/category';

const initialState = {};

const vehicleFilterReducers = combineReducers({
    products,
    category,
});

export default function vehicleFilter(state = initialState, action) {
    const datECodePrefix = action?.payload?.datECodePrefix;
    if (_.isString(datECodePrefix)) {
        return {
            ...state,
            [datECodePrefix]: vehicleFilterReducers(state[datECodePrefix], action),
        };
    } else {
        return state;
    }
}
