import React from 'react';
import StaticRedirects from './components/Redirects/StaticRedirects';
import GlobalClientFrontload from './GlobalClientFrontload';
import HostsFrontload from './HostsFrontload';

const App = () => {
    return (
        <StaticRedirects>
            <HostsFrontload />
            <GlobalClientFrontload />
        </StaticRedirects>
    );
};

export default App;
