import { FETCH_GLOBAL_TIRE_OPTIONS_FAILURE, FETCH_GLOBAL_TIRE_OPTIONS_REQUEST, FETCH_GLOBAL_TIRE_OPTIONS_SUCCESS } from '../actionTypes';
import { FAILURE, FETCHING, SUCCESS } from '../fetchStatus';

const initialState = {
    globalTireOptionsBySearchParams: {},
};

export default function tires(state = initialState, action) {
    switch (action.type) {
        case FETCH_GLOBAL_TIRE_OPTIONS_REQUEST:
            return {
                ...state,
                globalTireOptionsBySearchParams: {
                    ...state.globalTireOptionsBySearchParams,
                    [action.payload.searchParams]: {
                        status: FETCHING,
                    },
                },
            };
        case FETCH_GLOBAL_TIRE_OPTIONS_SUCCESS: {
            const { options } = action.payload.data;
            return {
                ...state,
                globalTireOptionsBySearchParams: {
                    ...state.globalTireOptionsBySearchParams,
                    [action.payload.searchParams]: {
                        status: SUCCESS,
                        data: options,
                    },
                },
            };
        }
        case FETCH_GLOBAL_TIRE_OPTIONS_FAILURE:
            return {
                ...state,
                globalTireOptionsBySearchParams: {
                    ...state.globalTireOptionsBySearchParams,
                    [action.payload.searchParams]: {
                        status: FAILURE,
                        error: action.payload.error,
                    },
                },
            };
        default:
            return state;
    }
}
