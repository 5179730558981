import { FETCH_CART_FAILURE, FETCH_CART_REQUEST, FETCH_CART_SUCCESS, RESET_CART, SET_CART } from '../../../actionTypes';
import { FAILURE, FETCHING, SUCCESS } from '../../../fetchStatus';

const initialState = null;

// TODO: refactor Cart invalidation

export default function cart(state = initialState, action) {
    switch (action.type) {
        case FETCH_CART_REQUEST:
            return {
                status: FETCHING,
            };
        case FETCH_CART_SUCCESS:
            return {
                status: SUCCESS,
                data: action.payload.cart,
            };
        case FETCH_CART_FAILURE:
            return {
                status: FAILURE,
                error: action.payload.error,
            };
        case RESET_CART:
            return {
                status: SUCCESS,
                data: action.payload.cart,
            };
        case SET_CART:
            return {
                status: SUCCESS,
                data: action.payload.cart,
            };
        default:
            return state;
    }
}
