import React from 'react';
import { sortBy } from 'lodash';
import { CategoryLink } from '../../../form-elements/Link';

function NavItem({ category }) {
    const [title, subTitle] = category.title.split(' & ');
    return (
        <li className="uk-flex-1 uk-text-center">
            <CategoryLink category={category}>
                <div>
                    {title}
                    {subTitle && (
                        <>
                            {' '}
                            & <div className="uk-navbar-subtitle">{subTitle}</div>
                        </>
                    )}
                </div>
            </CategoryLink>
            {category.subCategories.length >= 1 && (
                <div className="uk-navbar-dropdown">
                    <ul className="uk-nav uk-navbar-dropdown-nav">
                        {/* filtered nord-ostsee category from all shops*/}
                        {sortBy(category.subCategories, ['title']).filter(subCategory => subCategory.title !== 'Collection Nord-Ostsee Automobile').map((subCategory, index) => (
                            <li key={index}>
                                <CategoryLink category={subCategory}>{subCategory.title}</CategoryLink>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </li>
    );
}

function Navbar({ categories = [], markenStandort = {} }) {
    return (
        <section className="uk-section uk-section-default uk-padding-remove">
            <div className="uk-container">
                <nav className="uk-navbar-container uk-navbar-transparent uk-visible@m" data-uk-navbar="">
                    <ul className="uk-navbar-nav uk-width-1-1 uk-flex uk-flex-center">
                        {markenStandort.url &&
                            categories.map((category, index) => (
                                <NavItem markenStandort={markenStandort} category={category} key={index} />
                            ))}
                    </ul>
                </nav>
            </div>
        </section>
    );
}

export default Navbar;
