import axios from 'axios';
import { API_URL } from '../config';
import { isClient, isServer } from './SSRHelper';
import { __clientStore } from '../modules/createStore';
import { tokenSelector } from '../modules/selectors/auth';
import { useEffect, useState } from 'react';
import { FAILURE, FETCHING, SUCCESS } from '../modules/fetchStatus';

const timeout = 60 * 1000; // 1 minute

let extraOptions = {};

// In order to omit the 'http' and 'https' dependencies from the client bundle, we need to manually inline `isServer`,
// since webpack doesn't seem to inline constants across imports.
if (process.env.BUILD_TARGET === 'server') {
    const http = require('http');
    const https = require('https');

    // https://docs.microsoft.com/en-us/azure/app-service/app-service-web-nodejs-best-practices-and-troubleshoot-guide#my-node-application-is-making-excessive-outbound-calls
    const agentOptions = {
        keepAlive: true,
        maxSockets: 160,
        maxFreeSockets: 10,
        timeout,
        keepAliveTimeout: 300000,
    };
    extraOptions = {
        httpAgent: new http.Agent(agentOptions),
        httpsAgent: new https.Agent(agentOptions),
    };
}

export const apiClient = axios.create({
    baseURL: API_URL,
    timeout,
    maxRedirects: 10,
    ...extraOptions,
});

export const authenticationHeaders = apiClient.interceptors.request.use(
    function (config) {
        if (config.authenticated) {
            if (isClient) {
                const state = __clientStore.getState();
                const token = tokenSelector(state);

                if (token) {
                    config.headers['Authorization'] = `Bearer ${token}`;
                }
            } else {
                console.warn('Authenticated requests are only allowed client-side.');
            }
        }

        return config;
    },
    async error => {
        throw error;
    }
);

apiClient.interceptors.response.use(
    async response => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        if (response?.data?.error) {
            // eslint-disable-next-line no-throw-literal
            throw response?.data;
        }
        return response;
    },
    async error => {
        const status = error?.response?.status;

        if (status) {
            // Patch axios error to include the response status in "error.toJSON()"
            error.code = status;
        }

        if (isServer) {
            if (error.isAxiosError) {
                // Remove httpAgent and httpsAgent from error config.
                // This prevents "TypeError: Converting circular structure to JSON" when persisting the error in Redux.
                const { httpAgent, httpsAgent, ...newConfig } = error.config;
                error.config = newConfig;
            }
        }

        throw error;
    }
);

const initialState = { data: null, error: null, status: FETCHING };

/**
 * Custom hook to use an async function. Only client-side.
 *
 * @param {function():Promise<any>} asyncFunction - The async function to execute.
 * @param {Array} deps - If present, effect will only activate if the values in the list change.
 * @return {{data: object|null, error: Error|null, status: 'FETCHING'|'SUCCESS'|'FAILURE'}}
 */
export function useAsyncFunction(asyncFunction, deps) {
    const [state, setState] = useState(initialState);
    useEffect(() => {
        setState(state => {
            // If a refetch is caused by a dependency update, we need to restore the initial state.
            if (state.status !== 'FETCHING') {
                return initialState;
            }
            return state;
        });
        let promise;
        if (typeof asyncFunction === 'function') {
            promise = asyncFunction();
        } else {
            throw new Error(`Expected function as first parameter, instead got: ${asyncFunction}`);
        }
        let active = true;
        promise
            .then(data => {
                if (active) {
                    setState({ data, error: null, status: SUCCESS });
                }
            })
            .catch(error => {
                if (active) {
                    setState({ data: null, error, status: FAILURE });
                }
            });
        return () => {
            active = false;
        };
        // This is a custom hook with a dependency array. The usages of this hook get linted instead.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
    return state;
}
