/**
Which link should I use?

- What is the link target?
  - Internal Page (part of Routes.js)
    - Should a custom matomo event be triggered onClick?
      - No => `<InternalLink />`
      - Yes => `<InternalEventLink />`
  - External Page (e.g. https://example.com)
    - Should a custom matomo event be triggered onClick?
      - No => `<ExternalLink />`
      - Yes => `<ExternalEventLink />`
  - Category Page => `<CategoryLink />`
  - "#" => `<Anchor />`
  // TODO: scroll abstraction
  - Scroll to Element
    - Should a custom matomo event be triggered onClick?
      - No => `<ScrollLink />`
      - Yes => `<ScrollEventLink />`
 */

import { getUkButtonClassName } from './internal/ukClassName';
import React, { useCallback } from 'react';
import { ListenerLink, trackEvent } from '../Tracking/Matomo';
import PropTypes from 'prop-types';
import { validateClassName } from './internal/customPropTypesValidators';
import exact from 'prop-types-exact';
import StandortLink from '../VHost/StandortLink';
import { useSelector } from 'react-redux';
import { markenStandortSelector } from '../../../modules/selectors/standort/markenStandort';

function newTabToTarget(newTab) {
    return newTab ? '_blank' : undefined;
}

/**
 * Themeable internal link.
 *
 * See the [Styleguide](http://localhost:3000/de/styleguide) for usage examples.
 */
export function InternalLink({ children, className: additionalClassName, fullWidth, hideArrow, modifier, to, newStandortUrl, newTab, forceServerSideRouting = false }) {
    const className = getUkButtonClassName({ modifier, fullWidth, hideArrow, additionalClassName });

    return (
        <StandortLink className={className} to={to} newStandortUrl={newStandortUrl} target={newTabToTarget(newTab)} forceServerSideRouting={forceServerSideRouting}>
            {children}
        </StandortLink>
    );
}

InternalLink.propTypes = {
    children: PropTypes.node.isRequired,
    className: validateClassName,
    fullWidth: PropTypes.bool,
    hideArrow: PropTypes.bool,
    modifier: PropTypes.oneOf(['default', 'primary', 'secondary', 'danger', 'text', 'reset']),
    newStandortUrl: PropTypes.string,
    newTab: PropTypes.bool,
    to: PropTypes.string.isRequired,
    forceServerSideRouting: PropTypes.bool
};
InternalLink.propTypes = exact(InternalLink.propTypes);

/**
 * Themeable internal link, which triggers a Matomo event onClick.
 *
 * The event is configured via the props `category`, `action` and `label`.
 */
export function InternalEventLink({
    action,
    category,
    children,
    className: additionalClassName,
    fullWidth,
    hideArrow,
    label,
    modifier,
    to,
    newStandortUrl,
    newTab,
}) {
    const className = getUkButtonClassName({ modifier, fullWidth, hideArrow, additionalClassName });
    const markenStandort = useSelector(markenStandortSelector);

    const onClick = useCallback(() => {
        trackEvent({ category, action, label, standort: markenStandort });
    }, [category, action, label, markenStandort]);

    return (
        <StandortLink className={className} to={to} onClick={onClick} newStandortUrl={newStandortUrl} target={newTabToTarget(newTab)}>
            {children}
        </StandortLink>
    );
}

InternalEventLink.propTypes = {
    action: PropTypes.string.isRequired,
    category: PropTypes.string,
    children: PropTypes.node.isRequired,
    className: validateClassName,
    fullWidth: PropTypes.bool,
    hideArrow: PropTypes.bool,
    label: PropTypes.string,
    modifier: PropTypes.oneOf(['default', 'primary', 'secondary', 'danger', 'text', 'reset']),
    newStandortUrl: PropTypes.string,
    newTab: PropTypes.bool,
    to: PropTypes.string.isRequired,
};
InternalEventLink.propTypes = exact(InternalEventLink.propTypes);

/**
 * Themeable external link.
 *
 * Renders an anchor element with Matomo link tracking.
 */
export function ExternalLink({ children, className: additionalClassName, fullWidth, hideArrow, modifier, to, newTab = true }) {
    const className = getUkButtonClassName({ modifier, fullWidth, hideArrow, additionalClassName });

    return (
        // ExternalLink is an abstraction for ListenerLink.
        // eslint-disable-next-line react/forbid-elements
        <ListenerLink className={className} to={to} target={newTabToTarget(newTab)}>
            {children}
        </ListenerLink>
    );
}

ExternalLink.propTypes = {
    children: PropTypes.node.isRequired,
    className: validateClassName,
    fullWidth: PropTypes.bool,
    hideArrow: PropTypes.bool,
    modifier: PropTypes.oneOf(['default', 'primary', 'secondary', 'danger', 'text', 'reset']),
    newTab: PropTypes.bool,
    to: PropTypes.string.isRequired,
};
ExternalLink.propTypes = exact(ExternalLink.propTypes);

/**
 * Themeable external link, which triggers a Matomo event onClick.
 *
 * The event is configured via the props `category` (defaults to 'Outbound-Link'), `action` and `label`.
 *
 * Renders an anchor element with Matomo link tracking *and* triggers a custom event onClick.
 * This duplicate tracking is required to provide additional context for reports. [DOCS](https://matomo.org/docs/event-tracking#how-to-set-up-matomo-event-tracking-with-javascript)
 */
export function ExternalEventLink({
    action,
    category = 'Outbound-Link',
    children,
    className: additionalClassName,
    fullWidth,
    hideArrow,
    label,
    modifier,
    newTab = true,
    to,
}) {
    const className = getUkButtonClassName({ modifier, fullWidth, hideArrow, additionalClassName });
    const markenStandort = useSelector(markenStandortSelector)

    const onClick = useCallback(() => {
        trackEvent({ category, action, label, standort: markenStandort });
    }, [category, action, label, markenStandort]);

    return (
        // ExternalEventLink is an abstraction for ListenerLink.
        // eslint-disable-next-line react/forbid-elements
        <ListenerLink className={className} to={to} target={newTabToTarget(newTab)} onClick={onClick}>
            {children}
        </ListenerLink>
    );
}

ExternalEventLink.propTypes = {
    action: PropTypes.oneOf([
        'Klick-Slider',
        'Klick-Kachel',
        'Klick-Email',
        'Klick-Telefon',
        'Klick-Externe-Werkstatt',
        'Klick-Route-berechnen',
        'Klick-Reifen-Label',
        'Klick-Reifen-Datenblatt',
    ]).isRequired,
    category: PropTypes.string,
    children: PropTypes.node.isRequired,
    className: validateClassName,
    fullWidth: PropTypes.bool,
    hideArrow: PropTypes.bool,
    label: PropTypes.string,
    modifier: PropTypes.oneOf(['default', 'primary', 'secondary', 'danger', 'text', 'reset']),
    newTab: PropTypes.bool,
    to: PropTypes.string.isRequired,
};
ExternalEventLink.propTypes = exact(ExternalEventLink.propTypes);

/**
 * Link to a category.
 *
 * This wrapper is useful since Categories can be hosted outside the React SPA context.
 * These categories need to be routed server side.
 */
export function CategoryLink({ category, children, className: additionalClassName, fullWidth, hideArrow, modifier, newStandortUrl }) {
    const className = getUkButtonClassName({ modifier, fullWidth, hideArrow, additionalClassName });

    const to = `/${category.url}`;

    return (
        <StandortLink className={className} to={to} newStandortUrl={newStandortUrl} forceServerSideRouting={category.isExternal}>
            {children}
        </StandortLink>
    );
}

CategoryLink.propTypes = {
    category: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    fullWidth: PropTypes.bool,
    hideArrow: PropTypes.bool,
    modifier: PropTypes.oneOf(['default', 'primary', 'secondary', 'danger', 'text', 'reset']),
    newStandortUrl: PropTypes.string,
};
CategoryLink.propTypes = exact(CategoryLink.propTypes);
