import _ from 'lodash';
import { createMatchSelector } from 'connected-react-router';
import { createSelector } from 'reselect';
import { currentHostIsShopSystemSelector, currentHostIsUnknownSelector, currentHostStandortUrlSelector, hostsSelector } from './hosts';

const shopSystemUrlMatchSelector = createMatchSelector({ path: '/:standortUrl/:categoryUrl?' });
const singleShopUrlMatchSelector = createMatchSelector({ path: '/:categoryUrl?' });
const landingPageShopUrlMatchSelector = createMatchSelector({ path: '/duesseldorf/:landingPageShop?' });

export const standortUrlSelector = createSelector(
    [shopSystemUrlMatchSelector, currentHostIsShopSystemSelector, currentHostStandortUrlSelector],
    (shopSystemUrlMatch, currentHostIsShopSystem, currentHostStandortUrl) => {
        if (_.isNil(currentHostIsShopSystem)) return undefined;

        let standortUrl;
        if (currentHostIsShopSystem) {
            standortUrl = shopSystemUrlMatch?.params?.standortUrl;
        } else {
            standortUrl = currentHostStandortUrl;
        }
        return _.isNil(standortUrl) ? undefined : encodeURIComponent(standortUrl);
    }
);

export const landingPageUrlSelector = createSelector(
    [landingPageShopUrlMatchSelector, currentHostIsShopSystemSelector],
    (landingPageUrlMatch, currentHostIsShopSystem) => {
        if (_.isNil(currentHostIsShopSystem)) return undefined;

        const landingUrl = landingPageUrlMatch?.params?.landingPageShop;

        return _.isNil(landingUrl) ? undefined : landingUrl;
    }
);

export const categoryUrlSelector = createSelector(
    [shopSystemUrlMatchSelector, singleShopUrlMatchSelector, currentHostIsShopSystemSelector],
    (shopSystemUrlMatch, singleShopUrlMatch, currentHostIsShopSystem) => {
        if (_.isNil(currentHostIsShopSystem)) return undefined;

        const categoryUrl = (currentHostIsShopSystem ? shopSystemUrlMatch : singleShopUrlMatch)?.params?.categoryUrl;

        return _.isNil(categoryUrl) ? undefined : encodeURIComponent(categoryUrl);
    }
);

export const currentHostDoesNotHostCurrentStandortUrlSelector = createSelector(
    [currentHostIsUnknownSelector, currentHostIsShopSystemSelector, hostsSelector, standortUrlSelector],
    (currentHostIsUnknown, currentHostIsShopSystem, hosts, standortUrl) => {
        if (currentHostIsUnknown) return undefined;
        if (currentHostIsShopSystem) {
            const otherHost = _.find(hosts, { standortUrl });

            return !!otherHost;
        } else {
            return false;
        }
    }
);

export const pathnameSelector = state => state?.router?.location?.pathname;

export const pageSelector = createSelector(
    [pathnameSelector, standortUrlSelector, currentHostIsShopSystemSelector],
    (pathname, standortUrl, currentHostIsShop) => {
        if (currentHostIsShop) {
            return pathname.replace(`/${standortUrl}`, '') || '/';
        } else {
            return pathname || '/';
        }
    }
);
