import { FETCH_RIM_PRODUCER_LOGOS_FAILURE, FETCH_RIM_PRODUCER_LOGOS_REQUEST, FETCH_RIM_PRODUCER_LOGOS_SUCCESS } from '../actionTypes';
import { FAILURE, FETCHING, SUCCESS } from '../fetchStatus';

const initialState = null;

export default function rimProducerLogos(state = initialState, action) {
    switch (action.type) {
        case FETCH_RIM_PRODUCER_LOGOS_REQUEST:
            return {
                status: FETCHING,
            };
        case FETCH_RIM_PRODUCER_LOGOS_SUCCESS:
            return {
                status: SUCCESS,
                data: action.payload.rimProducerLogos,
            };
        case FETCH_RIM_PRODUCER_LOGOS_FAILURE:
            return {
                status: FAILURE,
                error: action.payload.error,
            };
        default:
            return state;
    }
}
