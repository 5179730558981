import React from 'react';
import { useSelector } from 'react-redux';
import { markenStandortSelector } from '../../../modules/selectors/standort/markenStandort';

function FooterCopyright() {
    const markenStandort = useSelector(markenStandortSelector);
    return (
        <section className="uk-section-secondary uk-padding-small">
            <div className="uk-container uk-text-center uk-text-uppercase">
                © {`${markenStandort.anschrift.firma} ${new Date().getFullYear()}`}
            </div>
        </section>
    );
}

export default FooterCopyright;
