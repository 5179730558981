import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import loadable from '@loadable/component';
import ExternalServiceSelector from '../../components/ServiceHeader/ExternalServiceSelector';
import ServiceStationBanner from '../../components/ServiceStationFinder/ServiceStationBanner';
import ServiceCategoryCards from '../../components/ServiceCategories/ServiceCategoryCards';
import ServiceLocationLandingpageList from '../../components/ServiceCategories/serviceLocationLandingpageList';
import ReduxSuspense from '../../components/ReduxSuspense/ReduxSuspense';
import { dienstleistungenResponseSelector } from '../../../modules/selectors/standort/dienstleistungen';
import { markenStandortSelector } from '../../../modules/selectors/standort/markenStandort';
import { apiClient } from '../../../helpers/apiHelperV2';
import useSWR from 'swr';

const SubLocationFinder = loadable(() => import('../../components/SubLocationFinder/SubLocationFinder'));
const ServiceStationFinder = loadable(() => import('../../components/ServiceStationFinder/ServiceStationFinder'));

function ServiceCategoryList() {
    const dienstleistungenResponse = useSelector(dienstleistungenResponseSelector);
    const markenStandort = useSelector(markenStandortSelector);

    const { data: serviceNavigatorstatus } = useSWR(`/api/v1/getServicenavigatorStatus?standortId=${markenStandort._id}`, async url => {
        const { data } = await apiClient.get(url);
        return data;
    });

    const serviceNavigatorStatus = useMemo(() => serviceNavigatorstatus?.status ?? [], [serviceNavigatorstatus]);

    return (
        <>
            {markenStandort.isTechno && (
                <>
                    <ServiceStationBanner />
                    <section className="uk-section uk-section-default uk-section-small">
                        <div className="uk-container">
                            <ServiceStationFinder redirectUrl="/reparatur-wartung" />
                        </div>
                    </section>
                </>
            )}
            {!markenStandort.isTechno && <ExternalServiceSelector externalServices={markenStandort.externalServices} />}
                (<section className="uk-section uk-section-muted uk-section-small">
                    <div className="uk-container">
                        <h1>Reparatur & Wartung</h1>
                        <h3>Das sind unsere Service-Leistungen für Sie</h3>
                        <ReduxSuspense response={dienstleistungenResponse}>

                            {dienstleistungen => (
                                <ServiceCategoryCards
                                    dienstleistungen={dienstleistungen}
                                    trackingAction="Klick-Servicekachel-reparaturWartung"
                                />
                            )}
                        </ReduxSuspense>
                    </div>
                </section>
                )
            {markenStandort.url === 'gottfried-schultz' &&
                <section className="uk-section uk-section-small">
                    <ServiceLocationLandingpageList />
                </section>
            }
            {markenStandort && markenStandort.implementsAreaConcept && !markenStandort.isTechno && <SubLocationFinder />}
        </>
    );
}

export default ServiceCategoryList;
