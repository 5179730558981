import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { CategoryLink } from '../form-elements/Link';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { categoryUrlSelector } from '../../../modules/selectors/url';
import { markenStandortSelector } from '../../../modules/selectors/standort/markenStandort';
import { standortUrlSelector } from '../../../modules/selectors/url';
import { apiClient } from '../../../helpers/apiHelperV2';

function ShopMenuList({ className = 'uk-nav uk-nav-default uk-nav-parent-icon', 'data-uk-nav': dataUkNav, categories, closeSidebar }) {
    const [hasOfferCategory, setHasOfferCategory] = useState(false);
    const [offerCategory, setOfferCategory] = useState({});
    const categoryUrl = useSelector(categoryUrlSelector);
    const markenStandort = useSelector(markenStandortSelector);
    const standortUrl = useSelector(standortUrlSelector);

    const isLinkActive = category => {
        return category.url === categoryUrl || category?.subCategories?.find(subCategory => subCategory.url === categoryUrl);
    };
    useEffect(() => {
        async function fetchOffers() {
            const { data } = await apiClient.get(`/api/v1/standorte/${standortUrl}/angebote`)
            return data
        }
        fetchOffers().then((data) => {
            if (data?.length) {
                setHasOfferCategory(true)
            }
        }).catch((err) => console.log("error", err))

        let offerCategory = categories.find((category) => category.url === "angebote")
        if (offerCategory) {
            setOfferCategory(offerCategory);
        }
    }, [standortUrl])

    return (
        <ul className={className} data-uk-nav={dataUkNav}>
            {hasOfferCategory &&
                <li key={offerCategory._id} className={classnames({ 'uk-parent': false, 'uk-active': isLinkActive(offerCategory) })} onClick={closeSidebar}>
                    <CategoryLink category={offerCategory} className="uk-text-bold">% {offerCategory.title}
                    </CategoryLink>
                </li>
            }
            {categories ? categories.filter(category => {
                return !category.subCategories.every(subCategory => {
                    return markenStandort.categoryBlacklist.map(cat => String(cat._id)).includes(String(subCategory._id))
                })
            }).map(category => {
                const hasSubCategories = category.subCategories.length >= 1;
                return (
                    <li
                        key={category._id}
                        className={classnames({
                            'uk-parent': hasSubCategories,
                            'uk-active': isLinkActive(category),
                        })}
                        onClick={hasSubCategories ? undefined : closeSidebar}
                    >
                        <CategoryLink category={category}>{category.title}</CategoryLink>
                        {hasSubCategories && (
                            <ul className="uk-nav-sub">
                                <li
                                    className={classnames({
                                        'uk-parent': category,
                                        'uk-active': category.url === categoryUrl,
                                    })}
                                    onClick={closeSidebar}
                                >
                                    <CategoryLink category={category}>Zur Kategorie</CategoryLink>
                                </li>
                                {_.sortBy(category.subCategories, ['title']).map((subCategory, index) => (
                                    <li
                                        key={index}
                                        onClick={closeSidebar}
                                        className={classnames({
                                            'uk-parent': category,
                                            'uk-active': subCategory.url === categoryUrl,
                                        })}
                                    >
                                        <CategoryLink category={subCategory}>{subCategory.title}</CategoryLink>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </li>
                );
            })
                :
                <></>}
        </ul>
    );
}

export default ShopMenuList;
