import { standortUrlSelector } from './url';
import { createSelector } from 'reselect';

const standortStateByUrlSelector = state => state.standort;

const defaulatStateSelector = state => state;

export const standortStateSelector = createSelector(
    [standortStateByUrlSelector, standortUrlSelector],
    (standortStateByUrl, standortUrl) => standortStateByUrl?.[standortUrl] ?? {}
);

export const defaultStateSelector = createSelector(
    [defaulatStateSelector],
    (defaultState) => defaultState ?? {}
);
