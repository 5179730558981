import React, { useMemo } from 'react';
import _ from 'lodash';
import Image from '../../../../Image/Image';
import EFALink from './EFALink';

function Logo({ header }) {
    const { logo, slogan } = useMemo(() => {
        if (header) return { logo: _.head(header.logo), slogan: header.text };
        else return {};
    }, [header]);

    if (!logo) return null;

    return (
        <EFALink to="/">
            <div className="logo-container">
                {logo.publicUrl && (
                    <Image
                        alt={logo.properties.alternative ?? 'Logo'}
                        title={logo.properties.title}
                        className="logo-container-img"
                        src={logo.publicUrl}
                    />
                )}
                {slogan && (
                    <span className="logo-text">
                        <p dangerouslySetInnerHTML={{ __html: slogan }} />
                    </span>
                )}
            </div>
        </EFALink>
    );
}

export default Logo;
