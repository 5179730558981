import { FETCH_ADD_COUPON_HEIGHT_FAILURE, FETCH_ADD_COUPON_HEIGHT_REQUEST, FETCH_ADD_COUPON_HEIGHT_SUCCESS } from '../actionTypes';
import { FAILURE, FETCHING, SUCCESS } from '../fetchStatus';

const initialState = null;

export default function couponHeight(state = initialState, action) {
    switch (action.type) {
        case FETCH_ADD_COUPON_HEIGHT_REQUEST:
            return {
                status: FETCHING,
            };
        case FETCH_ADD_COUPON_HEIGHT_SUCCESS:
            return {
                status: SUCCESS,
                data: action.payload.couponHeight,
            };
        case FETCH_ADD_COUPON_HEIGHT_FAILURE:
            return {
                status: FAILURE,
                error: action.payload.error,
            };
        default:
            return state;
    }
}
