import { SELECT_VEHICLE, SET_FILTER_FORM, SET_KBA_FORM, SET_HSN_TSN_FORM, SELECT_SEARCH_VEHICLE, SELECT_MANUAL_VEHICLE, SET_VEHICLE_MANUFACTURE } from '../actionTypes';

const initialState = {
    label: '',
    datECodePrefix: '',
    searchFilter: {
        searchDatECodePrefix: '',
        searchLabel: '',
    },
    manualFilter: {
        manualDatECodePrefix: '',
        manualLabel: '',
    },
    filterForm: {
        vehicleType: '',
        manufacturer: '',
        baseModel: '',
        subModel: '',
        engineOption: '',
        carBodyOption: '',
        gearingOption: '',
        equipmentLineOption: '',
    },
    kbaForm: {
        hsn: '',
        tsn: '',
    },
    hsntsnForm: {
        vehicles: [],
    },
    vehicleManufacturer: '',
    vehicleData: '',
    selectedVehicleLabel: ''
};

export default function vehicle(state = initialState, action) {
    switch (action.type) {
        case SELECT_VEHICLE:
            return {
                ...state,
                label: action.payload.label,
                datECodePrefix: action.payload.datECodePrefix,
            };
        case SELECT_SEARCH_VEHICLE:
            return {
                ...state,
                searchFilter: {
                    searchLabel: action.payload.label,
                    searchDatECodePrefix: action.payload.datECodePrefix,
                },
            };
        case SELECT_MANUAL_VEHICLE:
            return {
                ...state,
                manualFilter: {
                    manualLabel: action.payload.label,
                    manualDatECodePrefix: action.payload.datECodePrefix,
                },
            };
        case SET_FILTER_FORM:
            return {
                ...state,
                filterForm: {
                    ...state.filterForm,
                    [action.payload.key]: action.payload.value,
                },
            };
        case SET_KBA_FORM:
            return {
                ...state,
                kbaForm: {
                    ...state.kbaForm,
                    [action.payload.key]: action.payload.value,
                },
            };
        case SET_HSN_TSN_FORM:
            return {
                ...state,
                hsntsnForm: {
                    ...state.hsntsnForm,
                    vehicles: action.payload.vehicles,
                },
            };
        case SET_VEHICLE_MANUFACTURE:
            return {
                ...state,
                vehicleManufacturer: action.payload.vehicleManufacturer,
                vehicleData: (action.payload.vehicleTypeLabel && {
                    vehicleTypeLabel: action.payload.vehicleTypeLabel,
                    manufacturerLabel: action.payload.manufacturerLabel,
                    baseModelLabel: action.payload.baseModelLabel,
                    subModelLabel: action.payload.subModelLabel,
                    datECodePrefix: action.payload.datECodePrefix,
                }),
                selectedVehicleLabel: action.payload.selectedVehicleLabel
            };
        default:
            return state;
    }
}
