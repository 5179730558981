import { LOAD_UIKIT_SUCCESS } from '../actionTypes';
import { SUCCESS } from '../fetchStatus';

const initialState = null;

export default function UIkit(state = initialState, action) {
    switch (action.type) {
        case LOAD_UIKIT_SUCCESS:
            return {
                status: SUCCESS,
            };
        default:
            return state;
    }
}
