import React, { useCallback } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

function Select({ name, value, label, onChange, onBlur, error, className = 'uk-margin', options, disabled }) {
    const innerOnChange = useCallback(({ target: { name, value } }) => onChange({ name, value }), [onChange]);

    if (_.isEmpty(options)) {
        return null;
    }
    return (
        <div className={className}>
            {label && <label className="uk-form-label">{label}</label>}
            <select
                className="uk-select uk-form-controls"
                name={name}
                value={value}
                onChange={innerOnChange}
                onBlur={onBlur}
                disabled={disabled}
            >
                {options.map(option => {
                    const label = option.label ?? option;
                    return (
                        <option key={label} value={option.value}>
                            {label}
                        </option>
                    );
                })}
            </select>
            {error && <div className="uk-text-danger">{error}</div>}
        </div>
    );
}

Select.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.node,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    error: PropTypes.string,
    className: PropTypes.string,
    options: PropTypes.array.isRequired,
    disabled: PropTypes.bool,
};

export default Select;
