import {
    FETCH_TIRE_OPTIONS_FAILURE,
    FETCH_TIRE_OPTIONS_REQUEST,
    FETCH_TIRE_OPTIONS_SUCCESS,
    FETCH_TIRES_FAILURE,
    FETCH_TIRES_REQUEST,
    FETCH_TIRES_SUCCESS,
    UPDATE_TIRES_FILTER_VALUES,
} from '../../actionTypes';
import { FAILURE, FETCHING, SUCCESS } from '../../fetchStatus';

const initialState = {
    tiresResponseBySearchParams: {},
    tireOptionsResponseBySearchParams: {},
    filterValues: {
        fahrzeug: 'PKW',
        breite: '205',
        hoehe: '55',
        durchmesser: '16',
        geschwindigkeitsindex: '',
        tragfaehigkeit: '',
    },
};

export default function tires(state = initialState, action) {
    switch (action.type) {
        case FETCH_TIRES_REQUEST:
            return {
                ...state,
                tiresResponseBySearchParams: {
                    ...state.tiresResponseBySearchParams,
                    [action.payload.searchParams]: {
                        status: FETCHING,
                    },
                },
            };
        case FETCH_TIRES_SUCCESS: {
            const { data } = action.payload;
            return {
                ...state,
                tiresResponseBySearchParams: {
                    ...state.tiresResponseBySearchParams,
                    [action.payload.searchParams]: {
                        status: SUCCESS,
                        data: {
                            tires: data.tires,
                            count: data.count,
                        },
                    },
                },
            };
        }
        case FETCH_TIRES_FAILURE:
            return {
                ...state,
                tiresResponseBySearchParams: {
                    ...state.tiresResponseBySearchParams,
                    [action.payload.searchParams]: {
                        status: FAILURE,
                        error: action.payload.error,
                    },
                },
            };
        case FETCH_TIRE_OPTIONS_REQUEST:
            return {
                ...state,
                tireOptionsResponseBySearchParams: {
                    ...state.tireOptionsResponseBySearchParams,
                    [action.payload.searchParams]: {
                        status: FETCHING,
                    },
                },
            };
        case FETCH_TIRE_OPTIONS_SUCCESS: {
            const { options } = action.payload.data;
            return {
                ...state,
                tireOptionsResponseBySearchParams: {
                    ...state.tireOptionsResponseBySearchParams,
                    [action.payload.searchParams]: {
                        status: SUCCESS,
                        data: options,
                    },
                },
            };
        }
        case FETCH_TIRE_OPTIONS_FAILURE:
            return {
                ...state,
                tireOptionsResponseBySearchParams: {
                    ...state.tireOptionsResponseBySearchParams,
                    [action.payload.searchParams]: {
                        status: FAILURE,
                        error: action.payload.error,
                    },
                },
            };
        case UPDATE_TIRES_FILTER_VALUES:
            const { filterValuesUpdate } = action.payload;
            return {
                ...state,
                filterValues: {
                    ...state.filterValues,
                    ...filterValuesUpdate,
                },
            };
        default:
            return state;
    }
}
