export const API_URL = process.env.RAZZLE_API_URL;
if (!API_URL) throw new Error('RAZZLE_API_URL not defined');

export const IMAGE_API_URL = process.env.RAZZLE_IMAGE_API_URL;
if (!IMAGE_API_URL) throw new Error('RAZZLE_IMAGE_API_URL not defined');

const LEGAL_DEPLOYMENT_ENVS = ['local', 'test', 'abnahme', 'production'];
/**
 * @type {"local"|"test"|"abnahme"|"production"}
 */
export const DEPLOYMENT_ENV = process.env.RAZZLE_DEPLOYMENT_ENV;
if (!DEPLOYMENT_ENV) throw new Error('RAZZLE_DEPLOYMENT_ENV not defined');
if (!LEGAL_DEPLOYMENT_ENVS.includes(DEPLOYMENT_ENV))
    throw new Error(`Unexpected RAZZLE_DEPLOYMENT_ENV: ${DEPLOYMENT_ENV}, expected one of: ${LEGAL_DEPLOYMENT_ENVS}`);

export const IS_DEV = process.env.NODE_ENV === 'development';
// TODO: probably should be an environment variable
export const GOOGLE_MAPS_API_KEY = 'AIzaSyDNaWGs0Y01b9cM7VZzw2wulAi94GZtTh0';
