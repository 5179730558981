import React from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import Image from '../../../../Image/Image';
import EFALink from './EFALink';

function DesktopNav({ navigationMain }) {
    return (
        <div id="nav-wrap-id" className="nav-wrap">
            <div className="before__animation">
                <p>Herzlich Willkommen bei Emil Frey!</p>
                <p className="delayed">Was möchten Sie tun?</p>
            </div>
            <div className="after__animation">
                <p className="nav-text">Ich möchte</p>
                <div className="nav-items">
                    <div className="flex__container">
                        {navigationMain.map((navigation, index) => (
                            <div key={index}>
                                <EFALink
                                    className={classnames('dropdown', { 'trail-icon-chevrondown': !_.isEmpty(navigation.children) })}
                                    to={navigation.link}
                                >
                                    {navigation.title}
                                </EFALink>
                                {!_.isEmpty(navigation.children) && (
                                    <div
                                        className="Autokaufen dropdown-panel"
                                        data-uk-dropdown="pos: bottom-justify; boundary: .header-main-wrap; boundary-align: true"
                                        style={{ margin: 0 }}
                                    >
                                        <div className="uk-flex">
                                            <div className="list-items">
                                                <h4>{navigation.title}</h4>
                                                {navigation.children.map((children, index) => (
                                                    <EFALink key={index} className="icon-arrowright textlink-lg" to={children.link}>
                                                        {children.title}
                                                    </EFALink>
                                                ))}
                                            </div>
                                            {navigation.dropdown.offer && (
                                                <div className="right-side">
                                                    <div className="media-slot-card">
                                                        <div className="m-01-02-01-full-media-slot-card">
                                                            <EFALink to="/konfigurator">
                                                                <div
                                                                    className="media-slot-image"
                                                                    style={{
                                                                        backgroundImage: `url(${navigation.dropdown.offer?.content.imageMedium[0].map(
                                                                            x => x.publicUrl
                                                                        )})`,
                                                                        backgroundRepeat: 'no-repeat',
                                                                        backgroundSize: 'cover',
                                                                    }}
                                                                >
                                                                    {/* <div className="media-cta-box">
                                                                        <app-a-03-07-ctabox _nghost-alf-c39="">
                                                                            <div
                                                                                className="a-03-07-ctabox cta-box"
                                                                                style={{
                                                                                    backgroundColor: 'var(--emf_scy_red)',
                                                                                }}
                                                                            >
                                                                                { <p className="offer-text">
                                                                                    <span className="offer-text-desktop">
                                                                                        {navigation.dropdown.offer?.content.slogan}
                                                                                    </span>
                                                                                    <span className="offer-text-mobile" />
                                                                                    <span className="offer-text-ab"> ab</span>
                                                                                </p> }
                                                                                <h5 className="cta-price">
                                                                                    {navigation.dropdown.offer?.content.ctaboxPrice}€
                                                                                    <span className="offer-time">/Monat</span>
                                                                                </h5>
                                                                            </div>
                                                                        </app-a-03-07-ctabox>
                                                                    </div> */}
                                                                    <div className="headline-box">
                                                                        <p className="availability">
                                                                            {navigation.dropdown.offer?.content.slogan}
                                                                        </p>
                                                                        <h4 className="headline">
                                                                            {navigation.dropdown.offer?.content.title}
                                                                        </h4>
                                                                        <p className="subline">
                                                                            {navigation.dropdown.offer?.content.subline}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </EFALink>
                                                        </div>
                                                    </div>

                                                    <div className="topics">
                                                        <div className="header">
                                                            <h6 className="p-top-24">Aktuelle Themen</h6>
                                                        </div>
                                                        <div className="cards">
                                                            <div className="variant-sm">
                                                                {navigation.dropdown.topics?.cards.map((card, index) => (
                                                                    <div key={index} className="result-card">
                                                                        {card.image?.publicUrl && (
                                                                            <Image
                                                                                className="card-image"
                                                                                src={card.image.publicUrl}
                                                                                alt="card-image"
                                                                            />
                                                                        )}
                                                                        {card.action?.url && (
                                                                            <div className="card-text">
                                                                                <EFALink to={card.action.url}>
                                                                                    <span>{card.text}</span>
                                                                                </EFALink>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DesktopNav;
