import { SET_COOKIE_CONSENT_CATEGORIES } from '../actionTypes';

const initialState = null;

export default function oneTrust(state = initialState, action) {
    switch (action.type) {
        case SET_COOKIE_CONSENT_CATEGORIES:
            return {
                consentCategories: action.payload.consentCategories,
            };
        default:
            return state;
    }
}
