import React from 'react';
import loadable from '@loadable/component';
import { useSelector } from 'react-redux';
import { markenStandortThemeFooterSelector } from '../../../modules/selectors/standort/theme';

const FooterColumns = loadable(() => import('./FooterColumns'));
const NOAFooter = loadable(() => import('../themes/noa/footer/NOAFooter'));
const EFAFooter = loadable(() => import('../themes/efa/footer/EFAFooter'));
const MusterFooter = loadable(() => import('../themes/muster/footer/musterFooter'));

function FooterSelector() {
    const markenStandortThemeFooter = useSelector(markenStandortThemeFooterSelector);

    switch (markenStandortThemeFooter.componentName) {
        case 'EFAFooter':
            return <EFAFooter />;
        case 'NOAFooter':
            return <NOAFooter />;
        case 'MusterFooter':
            return <MusterFooter />;
        case 'DefaultFooter':
        case 'LegacyFooter':
            return <FooterColumns />;
        default:
            throw new Error(`Unexpected markenStandortThemeFooter, got: ${markenStandortThemeFooter.componentName}`);
    }
}

export default FooterSelector;
