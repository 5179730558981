import { FETCH_OFFERS_FAILURE, FETCH_OFFERS_REQUEST, FETCH_OFFERS_SUCCESS } from '../../actionTypes';
import { FAILURE, FETCHING, SUCCESS } from '../../fetchStatus';

const initialState = null;

export default function offers(state = initialState, action) {
    switch (action.type) {
        case FETCH_OFFERS_REQUEST:
            return {
                status: FETCHING,
            };
        case FETCH_OFFERS_SUCCESS:
            return {
                status: SUCCESS,
                data: action.payload.offers,
            };
        case FETCH_OFFERS_FAILURE:
            return {
                status: FAILURE,
                error: action.payload.error,
            };
        default:
            return state;
    }
}
