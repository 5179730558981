import { SET_SEARCH_STATS } from '../actionTypes';

const initialState = {
    stats: null,
};
export default function search(state = initialState, action) {
    switch (action.type) {
        case SET_SEARCH_STATS:
            return { ...state, stats: action.payload };
        default:
            return state;
    }
}
