import { applyMiddleware, compose, createStore as reduxCreateStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { createBrowserHistory, createMemoryHistory } from 'history';
import createRootReducer from './reducers';
import tokenMiddleware from './localStorageMiddleware';
import errorNotificationMiddleware from './errorNotificationMiddleware';
import { isServer } from '../helpers/SSRHelper';
import { setVHostRequestLocation } from './actions/vHostRequestLocation';

export let __clientStore;

export default function createStore({ protocol, hostname, port, url = '/' } = {}) {
    const history = isServer
        ? createMemoryHistory({
              initialEntries: [url],
          })
        : createBrowserHistory();

    const enhancers = [];

    if (!isServer) {
        const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

        if (typeof devToolsExtension === 'function') {
            enhancers.push(devToolsExtension({ trace: true, traceLimit: 25 }));
        }
    }

    const middlewares = [thunk, routerMiddleware(history), tokenMiddleware, errorNotificationMiddleware];
    const composedEnhancers = compose(applyMiddleware(...middlewares), ...enhancers);

    const initialState = !isServer ? window.__PRELOADED_STATE__ : {};

    if (!isServer) {
        delete window.__PRELOADED_STATE__;
    }

    const store = reduxCreateStore(createRootReducer(history), initialState, composedEnhancers);

    // Hack: apiClient needs to peek into the redux store for auth information.
    // This is only required client side, since we don't support auth in SSR.
    // The apiClient can't import clientStore directly, since it is used both on the client and the server.
    // To prevent parallel SSR requests from affecting each other, we simply don't assign the store server-side.
    if (!isServer) {
        __clientStore = store;
    }

    if (isServer) {
        store.dispatch(setVHostRequestLocation({ protocol, hostname, port }));
    }

    return {
        store,
        history,
    };
}
