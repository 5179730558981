import { apiClient } from '../../../helpers/apiHelperV2';
import { FETCH_DIENSTLEISTUNGEN_FAILURE, FETCH_DIENSTLEISTUNGEN_REQUEST, FETCH_DIENSTLEISTUNGEN_SUCCESS } from '../../actionTypes';
import { dienstleistungenResponseSelector } from '../../selectors/standort/dienstleistungen';
import { shouldFetch } from '../../fetchStatus';
import { standortUrlSelector } from '../../selectors/url';

// TODO: this logic should be in a reducer or even serverside.
function patchDienstleistungen(serviceObj, standortUrl) {
    const dienstleistungen =
        (standortUrl === 'de' || serviceObj.noServices ? serviceObj.serviceListe : serviceObj.services || serviceObj.serviceListe) ?? [];
    if (dienstleistungen && dienstleistungen.length < 1) {
        dienstleistungen.forEach(service => {
            if (service.available === false || service.available === '') {
                service.href = `serviceAnfrage/${service.url}`;
            } else {
                service.href = `reparatur-wartung/${service.url}`;
            }
        });
    }
    return dienstleistungen;
}

// TODO: "initiateCheckout" and "intern" edge cases
export const fetchDienstleistungenIfNeeded = () => {
    return async function (dispatch, getState) {
        const state = getState();

        const standortUrl = standortUrlSelector(state);
        const dienstleistungenResponse = dienstleistungenResponseSelector(state);

        if (shouldFetch(dienstleistungenResponse.status)) {
            dispatch({
                type: FETCH_DIENSTLEISTUNGEN_REQUEST,
                payload: { standortUrl },
            });
            try {
                const res = await apiClient.get('/api/v1/Dienstleistungen', {
                    params: {
                        standortUrl,
                    },
                });
                dispatch({
                    type: FETCH_DIENSTLEISTUNGEN_SUCCESS,
                    payload: { standortUrl, dienstleistungen: patchDienstleistungen(res.data, standortUrl) },
                });
            } catch (error) {
                dispatch({
                    type: FETCH_DIENSTLEISTUNGEN_FAILURE,
                    payload: { standortUrl, error },
                });
            }
        }
    };
};
