import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import Image from '../Image/Image';
import { markenStandortSelector } from '../../../modules/selectors/standort/markenStandort';
import classnames from 'classnames';

function Icon({ iconName, alt }) {
    return (
        <div className="uk-flex uk-flex-center">
            <Image
                src={`https://techno-images.s3.eu-central-1.amazonaws.com/payment/${iconName}.png`}
                alt={alt}
                resizeWidth={70}
                resizeHeight={70}
                fit="contain"
                enlarge={true}
                background="transparent"
            />
        </div>
    );
}

function PaymentColumns() {
    const markenStandort = useSelector(markenStandortSelector);

    const arrayWithPaymentIconNames = useMemo(() => {
        const shippingPaymentCategories = _.get(markenStandort, 'stammblatt.zahlungsarten.versand', []);
        const PPE = _.get(markenStandort, 'stammblatt.subZahlungsarten.PPE', false);
        const PDT = _.get(markenStandort, 'stammblatt.subZahlungsarten.PDT', false);
        let arrayWithPaymentIconNames = _.flatMap(shippingPaymentCategories, 'picturePath');
        arrayWithPaymentIconNames = arrayWithPaymentIconNames.filter(path => (PDT || path !== '/paydirekt') && (PPE || path !== '/paypal'));
        arrayWithPaymentIconNames = [...arrayWithPaymentIconNames, ...(markenStandort.paymentProvider === 'paypal' ? ['paypal'] : [])];
        arrayWithPaymentIconNames = arrayWithPaymentIconNames.map(iconName => iconName.replace('/', ''));
        arrayWithPaymentIconNames = _.uniq(arrayWithPaymentIconNames);
        return arrayWithPaymentIconNames;
    }, [markenStandort]);

    const arrayWithShippingIconNames = useMemo(() => {
        let arrayWithShippingIconNames = _.get(markenStandort, 'stammblatt.versandarten', ['dhl']);
        arrayWithShippingIconNames = arrayWithShippingIconNames.map(iconName => iconName.replace('/', ''));
        return arrayWithShippingIconNames;
    }, [markenStandort]);

    return (
        <section className="uk-section uk-section-default">
            <div className="uk-container">
                <div
                    className={classnames(
                        'uk-grid uk-grid-small uk-grid-match uk-child-width-1-1 uk-child-width-1-2@s',
                        markenStandort?.autohauskenner ? 'uk-child-width-1-4@m' : 'uk-child-width-1-3@m'
                    )}
                    data-uk-grid=""
                >
                    {markenStandort?.autohauskenner && (
                        <div>
                            <div className="uk-card uk-card-small uk-card-default uk-card-body uk-text-center">
                                <h3>Partner</h3>
                                <iframe
                                    loading="lazy"
                                    id="autohauskenner"
                                    title="Autohauskenner"
                                    src={markenStandort.autohauskenner}
                                    frameBorder="0"
                                    scrolling="no"
                                    width="100%"
                                    height="250"
                                />
                            </div>
                        </div>
                    )}
                    <div>
                        <div className="uk-card uk-card-small uk-card-default uk-card-body uk-text-center">
                            <h3>Zahlarten</h3>
                            <div
                                className="uk-grid uk-grid-small uk-width-1-1 uk-child-width-1-2 uk-flex uk-flex-center uk-flex-middle"
                                data-uk-grid=""
                            >
                                {arrayWithPaymentIconNames.map((iconName, index) => {
                                    return <Icon key={index} iconName={iconName} alt="payment-icon" />;
                                })}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="uk-card uk-card-small uk-card-default uk-card-body uk-text-center">
                            <h3>Versandart</h3>
                            <div
                                className="uk-grid uk-grid-small uk-width-1-1 uk-child-width-1-2 uk-flex uk-flex-center uk-flex-middle"
                                data-uk-grid=""
                            >
                                {arrayWithShippingIconNames.map((iconName, index) => {
                                    return <Icon key={index} iconName={iconName} alt="shipping-icon" />;
                                })}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="uk-card uk-card-small uk-card-default uk-card-body uk-text-center">
                            <h3>Sicherheit</h3>
                            <div className="uk-grid uk-grid-small uk-child-width-1-2 uk-flex uk-flex-center uk-flex-middle" data-uk-grid="">
                                <Icon iconName="verifiedvisa" alt="payment-visa" />
                                <Icon iconName="mastersecure" alt="payment-mastercard" />
                                <div className="uk-width-1-1">
                                    <Icon iconName="ssl" alt="payment-ssl" />
                                    <p className="uk-text-center uk-margin-small-top">Sichere Zahlung mit SSL-Verschlüsselung</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default PaymentColumns;
