import React from 'react';
import { useSelector } from 'react-redux';
import { userResponseSelector } from '../../../modules/selectors/auth';
import ReduxSuspense from '../ReduxSuspense/ReduxSuspense';
import { Route } from 'react-router-dom';
import StandortRedirect from '../VHost/StandortRedirect';
import _ from 'lodash';

function PrivateRoute({ children, ...rest }) {
    const userResponse = useSelector(userResponseSelector);

    return (
        <Route {...rest}>
            {props => (
                <ReduxSuspense response={userResponse}>
                    {user => {
                        if (user) {
                            if (_.isFunction(children)) {
                                return children(props);
                            } else {
                                return children;
                            }
                        } else {
                            return <StandortRedirect to="/login" />;
                        }
                    }}
                </ReduxSuspense>
            )}
        </Route>
    );
}

export default PrivateRoute;
